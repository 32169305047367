.topArea {
	display: inline-block;
	width: 100%;
	height: auto;
	background-image: url(../images/ondemand/bg_top.jpg);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	h1 {
		img {
			width: 100%;
		}
	}
}

.inner-nav {
	position: relative;
	z-index: 1000;
}

.menu-ondermand {
	display: inline-block;
	width: 100%;
	li {
		float: left;
		width: 25%;
		@media(max-width: 768px) {
			width: 50%;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
}

.sub-ondermand {
	display: none;
	height: 224px;
	width: 25%;
	padding-top: 0;
	margin-top: 0;
	padding-bottom: 0;
	margin-bottom: 0;
	position: absolute;
	@media(max-width: 768px) {
		width: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
	li {
		width: 100%;
		height: 44px;
		background: #323232;
		text-align: center;
		font-size: 16px;
		float: none;
		border-bottom: #969696 solid 1px;
		a {
			width: 100%;
			height: 44px;
			display: block;
			line-height: 44px;
			color: #fff;
			&:hover {
				background: #be0a19;
			}
		}
	}
	.close-sub {
		a {
			color: #fff;
			background: #be0a19;
		}
	}
}

.cntArea {
	padding: 40px 0;
	h3 {
		margin-bottom: 25px;
	}
	.txt {
		font-weight: bold;
		@include font-size(15);
		letter-spacing: -0.5px;
		padding-bottom: 15px;
		line-height: 1.8;
	}
}

.pointWrap {
	display: table;
	width: 100%;
	@media(max-width: 768px) {
		display: block;
	}
	.ct-left {
		display: table-cell;
		width: 65%;
		@media(max-width: 768px) {
			width: 100%;
			display: block;
		}
		dl {
			display: inline-block;
			width: 100%;
			overflow: hidden;
			margin-bottom: 20px;
			dt {
				width: 122px;
				float: left;
				@media(max-width: 768px) {
					width: 50px;
				}
			}
			dd {
				float: left;
				width: calc(100% - 130px);
				font-size: 14px;
				line-height: 1.8;
				margin: 25px 0 0 7px;
				letter-spacing: -0.5px;
				@media(max-width: 768px) {
					width: calc(100% - 50px);
					margin: 0;
					padding-left: 10px;
				}
				span {
					@include font-size(16);
					font-weight: bold;
					color: #be0a19;
				}
			}
		}
	}
	.ct-right {
		display: table-cell;
		width: 35%;
		position: relative;
		@media(max-width: 768px) {
			width: 100%;
			display: block;
		}
		img {
			position: absolute;
			right: 0;
			bottom: 35px;
			@media(max-width: 768px) {
				position: relative;
				display: block;
				margin: 0 auto;
				bottom: 0;
			}
		}
	}
}

.cnt03 {
	margin: 0 -10px;
	h3 {
		margin-bottom: 30px;
		margin-left: 10px;
	}
	.list-wrap {
		display: block;
		width: 100%;
		margin: 0 0 20px;
		li {
			padding: 0 10px;
			margin-bottom: 10px;
			@media(max-width: 768px) {
				margin-bottom: 20px;
			}
			img {
				width: 100%;
				&:hover {
					opacity: .7;
				}
			}
		}
		&.col-2 {
			li {
				float: left;
				width: 50%;
			}
		}
		&.col-3 {
			li {
				float: left;
				width: 33.333%;
				@media(max-width: 768px) {
					width: 50%;
					margin-right: 0;
					img {
						display: block;
						margin: 0 auto;
					}
				}
			}
		}
	}
}

.line {
	clear: both;
	height: 1px;
	background-color: #000;
	margin: 30px auto;
	overflow: hidden;
}

.credit {
	margin: 0 auto;
	line-height: 14px;
	color: #555;
	@include font-size(10);
	padding: 10px 0;
}

.program {
	.heading {
		padding: 30px 0 20px;
		@media(max-width: 768px) {
			padding: 20px 0;
		}
	}
}

.list-new {
	display: inline-block;
	width: 100%;
	padding-bottom: 20px;
	border-bottom: #969696 solid 1px;
	li {
		width: 49.5%;
		float: left;
		display: inline-block;
		margin-right: 1%;
		margin-bottom: 1%;
		position: relative;
		&:nth-child(2n) {
			margin-right: 0;
		}
		a {
			img {
				width: 100%;
			}
		}
		p {
			position: absolute;
			left: 2px;
			bottom: 2px;
			width: calc(100% - 2px);
			height: 30px;
			background: #ffe600;
			opacity: 0;
			zoom: 1;
			line-height: 33px;
			font-size: 14px;
			font-weight: bold;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0 5px;
			font-family: "Hiragino Kaku Gothic ProN", "meiryo", "メイリオ", "ms pgothic", "ms pゴシック", "osaka", sans-serif, "TakaoPGothic", "Sazanami Gothic";
		}
	}
}

/*popup*/
.popupBox {
	position: relative;
	width: 536px;
	height: auto;
	background: #fff;
	border-top: 2px solid #ffe600;
	border-left: 2px solid #ffe600;
	border-right: 2px solid #ffe600;
	padding: 15px 20px 0 20px;
	font-size: 14px;
	text-align: left;
	overflow: hidden;
	h2 {
		@include font-size(24);
		font-weight: bold;
		color: #be0a19;
		border-bottom: #969696 solid 2px;
		padding-bottom: 5px;
	}
	.proInfo {
		font-weight: bold;
		line-height: 24px;
		padding: 10px 0;
		@media(max-width: 768px) {
			@include font-size(12);
		}
	}
	.proTxt {
		line-height: 24px;
		padding-bottom: 15px;
		@media(max-width: 768px) {
			@include font-size(12);
		}
	}
	.price {
		background: #ffe600;
		color: #be0a19;
		font-weight: bold;
		padding: 4px 0 2px 6px;
		margin-left: -20px;
		width: 100%;
		min-height: 24px;
		text-align: center;
		@media(max-width: 768px) {
			@include font-size(12);
		}
		@media(max-width: 374px) {
			@include font-size(10);
		}
	}
}

.fancybox-close-small {
	background: #be0a19;
	border: 0;
	border-radius: 0;
	color: #fff;
	cursor: pointer;
	height: 28px;
	margin: 0;
	padding: 0;
	position: absolute;
	right: 0;
	top: auto;
	bottom: 0;
	width: 28px;
	z-index: 10;
	@media(max-width: 768px) {
		width: 25px;
		height: 25px;
	}
	@media(max-width: 374px) {
		width: 24px;
		height: 24px;
	}
}

.fancybox-hidden {
	display: none;
}

.center-slick {
	.slick-active.slick-center {
		.item {
			img {
				width: 100%;
				max-width: 100%;
				padding-top: 0;
				position: relative;
				z-index: 999;
			}
		}
	}
	.slick-slide img {
		width: 60%;
		padding-top: 40px;
		position: relative;
		z-index: 1;
		margin: 0 auto;
	}
	.slick-active {
		background: red;
	}

	.slick-active + .slick-active {
		background: blue;
	}

	.slick-active + .slick-active + .slick-active {
		background: yellow;
	}
}

.compensate-for-scrollbar {
	margin-right: 0 !important;
}

.carousel-list {
	padding: 44px 0 0;
	width: 980px;
	height: 380px;
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0 auto;
	.next{
		position: absolute;
		top: 189px;
		left: 207px;
		z-index: 3;
		cursor: pointer;
	}
	.prev{
		position: absolute;
		top: 189px;
		left: 728px;
		z-index: 4;
		cursor: pointer;
	}
}
.list-thumnail{
	width: 1300px;
	li{
		position: relative;
		background: #fff;
		img{
			width: 100%;
			height: auto;
			&:hover{
				opacity: 0.5;
			}
		}
	}
	.li00{
		width: 278px;
		float: left;
		margin-top: 76px;
		margin-left: -278px;
		opacity: 0.7;
		zoom: 1;
	}
	.li01{
		width: 278px;
		float: left;
		margin-top: 76px;
		margin-left: 25px;
		opacity: 0.7;
		zoom: 1;
	}
	.li02{
		width: 504px;
		float: left;
		margin-left: -65px;
		position: relative;
		z-index: 2;
		opacity: 1.0;
		img{
			&:hover{
				opacity: .7;
			}
		}
	}
	.li03{
		width: 278px;
		float: left;
		margin: 76px 0 0 -65px;
		position: relative;
		z-index: 1;
		opacity: 0.7;
		zoom: 1;
	}
	.li04{
		width: 278px;
		float: left;
		margin: 76px 0 0 25px;
		position: relative;
		z-index: 1;
		opacity: 0.7;
		zoom: 1;
	}
}
