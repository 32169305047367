#page-forum {
	.page-forum-content {
		border-width: 1px;
		border-color: #ccc;
		border-style: solid;
		margin-bottom: 30px;
		padding: 19px;
		@include font-size(16);
		@media screen and (max-width: 768px) {
			@include font-size(14);
			padding: 10px;
		}
		& + p.txt-center{
			margin-bottom: 50px;
		}
	}
	.pageTop {
		margin-top: 0;
	}
	.forum_text {
	    border-left: 1px solid #cccccc;
	    border-right: 1px solid #cccccc;
	    border-bottom: 1px solid #cccccc;
	    border-bottom-right-radius: 5px;
	    border-bottom-left-radius: 5px;
	    margin: 0;
	    padding: 0;
	    @include font-size(13);
	    p {
		    padding: 20px;
		}
		.forum_left {
			float: left;
			width: calc(55% - 30px);
			padding-right: 0;
			line-height: 1.5;
			@media screen and (max-width: 450px) {
				width: 100%;
				padding-right: 20px;
			}
		}
		.forum_right {
		    float: right;
		    display: inline;
		    margin: 20px 20px 20px 10px;
		    text-align: right;
		    width: 45%;
		    @media screen and (max-width: 768px) {
		    	margin: 20px 10px 20px 10px;
		    }
			@media screen and (max-width: 450px) {
				width: 100%;
				margin: 0;
				padding: 10px 10px 20px 10px;
			}
		    ul {
			    text-align: left;
			    margin: 15px 15px 0 15px;
			    li {
				    list-style: disc;
				    list-style-position: inside;
				    a {
				    	text-decoration: underline;
					    color: #1e8faf!important;
					    @include font-size(13);
					}
				}
			}
			.btnImg {
				.imgWrap {
					width: 100%; 
					display: inline-block; 
					position: relative; 
					margin: 0px 0px 5px; 
					padding: 0px; 
					float: none;
					text-align: center;
					img {
						margin: 0px; 
						padding: 0px; 
						float: left;
						width: 100%;
						@media screen and (max-width: 768px) {
							float: none;
						}
					}
				}
				.unit_thumbnail {
					width: 100%; 
					height: 49px;
				}
			}
		}
		.mail_box {
		    margin: 0;
		    border: 1px solid #CCC;
		    padding-bottom: 15px;
		    .mail_title {
			    background: #1e8faf;
			    color: #FFF;
			    font-weight: bold;
			    height: 30px;
			    text-align: left;
			    padding: 5px 5px 0 15px;
			    margin: 0;
			}
		}
	}
	#present, #mail {
		padding-top: 112px;
		margin-top: -112px;
		@media screen and (max-width: 768px) {
			padding-top: 62px;
			margin-top: -62px;
		}
	}
	#title_present, #title_mail, #title_customer {
		width: calc(100% + 13.5px);
		margin-left: -13.5px;
		@media screen and (max-width: 1200px) {
			width: calc(100% + 10px);
			margin-left: -10px;
		}
		@media screen and (max-width: 980px) {
			width: calc(100% + 8px);
			margin-left: -8px;
		}
		@media screen and (max-width: 768px) {
			width: calc(100% + 6px);
			margin-left: -6px;
		}
	}
	#bannerArea {
		.imgWrap {
			width: 280px; 
			height: 60px; 
			display: inline-block; 
			position: relative; 
			margin: 0px; 
			padding: 0px; 
			float: none;
			img {
				width: 100%;
				height: 60px;
				margin: 0px; 
				padding: 0px; 
				float: left;
			}
		}
	}
}
#lNav {
    margin: 0 0 15px 0;
    
    .imgWrap {
    	img {
    		margin: 0px; 
    		padding: 0px; 
    		float: left;
    		width: 100%;
    		height: 8px;
    	}
    	.unit_thumbnail {
    		width: 100%;
    		height: 8px;
    	}
    }
}
