.link-arrow {
	padding-left: 25px;
	position: relative;
	text-decoration: underline;
	&:before {
		display: block;
		content: '';
		background: url("../images/shicho/houjin/icon-arrow.png") no-repeat center center;
		background-size: 100% 100%;
		width: 10px;
		height: 10px;
		overflow: hidden;
		border-radius: 2px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 10px;
	}
	&:hover {
		color: #0087ba;
	}
}
.mb30 {
	margin-bottom: 30px;
}
ol {
	list-style: none;
}
.page-inquiry {
	@include font-size(15);
	.accordion02 {
		dl {
			dt, dd {
				a {
					line-height: 1.5;
					color: #000;
					text-decoration: underline;
					@include font-size(13);
					&:hover {
						color: #a70000;
					}
				}
			}
		}
	}
	&.index {
		@include font-size(14);
		#mainInner {
			background: #fff;
			border-width: 0 1px 1px 1px;
			border-color: #ccc;
			border-style: solid;
			margin-bottom: 30px;
			padding: 19px;
		}
		.inquiryTit {
			background: url(/images/common/bg_gradation_gray.jpg) 0 0 repeat-x;
			border-top: 1px solid #ccc;
			border-left: 1px solid #ccc;
			border-right: 1px solid #ccc;
			padding: 1em 1em 1em 0.8em;	
			h2 {
				@include font-size(15);
				font-weight: bold;
			}
			span {
				background: url(/images/common/arrow_red.gif) 0 4px no-repeat;
				padding-left: 15px;
			}
		}
		.inquiryList {
			border: 1px solid #ccc;
			margin-bottom: 1em;
			padding: 1.2em 1.2em 0 1.5em;
			li {
				background: url(/images/common/arrow_red.gif) 0 4px no-repeat;
				padding: 0 0 1.2em 15px;
				a {
					text-decoration: underline;
					color: #bc0d17;
					&:hover {
						color: #0087ba;
					}
				}
			}
		}
	}
}