.s-window {
	padding-right: 50px;
	border: 1px solid #999999;
	&::-webkit-input-placeholder {
		color: #999999;
	}
	&:-moz-placeholder {
		color: #999999;
	}
	&::-moz-placeholder {
		color: #999999;
	}
	&:-ms-input-placeholder {
		color: #999999;
	}
	&::-ms-clear {
		display: none;
	}
	&::-webkit-contacts-auto-fill-button {
		visibility: hidden;
		display: none !important;
		pointer-events: none;
		position: absolute;
		right: 0;
	}
}
.news-list {
	li {
		.ttl {
			@media screen and (max-width: 1110px) {
				margin-top: 10px;
			}
		}
	}
}

.slick-slide {
	img {
		@media screen and (min-width: 0 \0
		) {
			width: 100%;
		}
	}
}

.flex-ttl {
	display: flex !important;
	align-items: center;
	.more-btn {

	}
}

.top-ttl {
	&.flex-ttl {
		position: relative;
		padding-left: 63px;
		letter-spacing: 1.8px;
		&:before {
			position: absolute;
			top: 50%;
			left: 0;
			-webkit-transform: translate(0, -50%);
			-ms-transform: translate(0, -50%);
			transform: translate(0, -50%);
		}
		&.flex-ttl-fix {
			padding-left: 67px;
			@media(max-width: 768px) {
				padding-left: 64px;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.main-slider {
		.slick-next, .slick-prev {
			top: calc(50% - 110px);
			-webkit-transform: translate(0, -50%);
			-ms-transform: translate(0, -50%);
			transform: translate(0, -50%);
		}
	}
	.onair-list {
		.owl-nav {
			.owl-prev, .owl-next {
				top: calc(50% - 40px);
			}
		}
	}
	.theme-slide {
		.owl-nav {
			.owl-prev, .owl-next {
				top: calc(50% - 25px);
			}
		}
	}
	.tw-frame {
		border: none;
		text-align: center;
		iframe {
			border: 1px solid #ccc !important;
			border-radius: 3px !important;
			margin: auto;
		}
	}
	.program-box {
		.box {
			.img {
				img {
					@media screen and (min-width: 0 \0
					) {
						position: static;
					}
				}
			}
		}
	}
}

.slick-arrow-clone {
	display: inline-block;
	position: absolute;
	width: 40px;
	height: 40px;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	visibility: hidden;
	@include smooth-transition;
	cursor: pointer;
}
.slick-next-clone {
	right: -10px;
	background: url("../images/common/control_arrow_r.png") no-repeat;
	background-position: center;
}
.slick-prev-clone {
	left: -10px;
	background: url("../images/common/control_arrow_l.png") no-repeat;
	background-position: center;
}
.slick-deactive {
	display: none !important;
}
.main-slider-inner-wrap{
	&:hover{
		.slick-next-clone{
			right: 20px;
		}
		.slick-prev-clone{
			left: 20px;
		}
	}
}
.main-slider-inner-wrap {
	@media screen and (min-width: 769px) {
		position: relative;
		display: inline-block;
		width: 100%;
		&:hover {
			.slick-arrow-clone {
				display: inline-block;
				opacity: 1;
				visibility: visible;
			}
		}

	}
}

.main-plan-list {
	position: relative;
	.slick-arrow-clone {
		top: calc(50% - 12px);
		transform: translateY(-50%);
	}
	.slick-next-clone {
		right: -55px;
	}
	.slick-prev-clone {
		left: -30px;
	}
	&:hover {
		.slick-arrow-clone {
			display: inline-block;
			opacity: 1;
			visibility: visible;
		}
		.slick-next-clone {
			right: -40px;
		}
		.slick-prev-clone {
			left: -14px;
		}
	}
}

.thum-slider .slick-current img, .thum-slider li:hover img {
	opacity: 0.7;
	@include smooth-transition;
}