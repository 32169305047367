#timetable-page {
	padding-top: 136px;
	@media screen and (max-width: 768px) {
		padding-top: 96px;
		.campaign-list, .campaign-list2 {
			li {
				margin-bottom: 4% !important;
				img {
					width: 100%;
				}
			}
		}
		.campaign-list2 {
			margin-bottom: 0;
		}
		.copy-txt {
			padding: 0;
		}
	}
}

.notice {
	color: #333333;
	margin: 10px 0 20px;
	@include font-size(18);
	&.notice-sm {
		margin: 0 0 25px;
		@include font-size(14);
	}
	&.notice-sm {
		@media(max-width: 768px){
			margin-bottom: 30px;
		}
	}
	a {
		color: #a70000;
		&:hover{
			text-decoration: underline;
		}
	}
}

.white-bg {
	background-color: #fff;
}

.table-schedule {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	+ .pagination-item {
		border-top: none;
	}
	table {
		width: 100%;
		@media screen and (max-width: 768px) {
			width: 767px;
		}
		@media print {
			width: 100%;
		}
		@media screen and (-webkit-min-device-pixel-ratio: 0) {
			tr:empty {
				// Correctly rowspan display on Edge
				min-height: 1px;
				max-height: 1px;
			}
		}
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			tr:empty {
				// Correctly rowspan display on IE
				min-height: 1px;
				max-height: 1px;
			}
		}
		tbody {
			th,
			tr:first-child td:first-child,
			tr:last-child td:first-child {
				width: auto;
				vertical-align: top;
				background-color: #333333;
				color: #cccccc;
				text-align: center;
				font-weight: 500;
				padding: 7px;
				border: 1px solid #aaaaaa;
				@include font-size(16);
				a{
					color: #cccccc;
					&:hover{
						text-decoration: underline;
					}
				}
				&.sat {
					color: #7db4ff;
					a{
						color: #7db4ff;
					}
				}
				&.sun {
					color: #ff6e41;
					a{
						color: #ff6e41;
					}
				}
			}
			th, td {
				border: 1px solid #aaaaaa;
				padding: 10px 5px;
				vertical-align: top;
			}
			td {
				width: 165px;
				font-weight: 500;
				line-height: 1.3;
				color: #1b4789;
				@include font-size(16);
				a {
					display: block;
					font-weight: 500;
					line-height: 1.3;
					@include font-size(16);
					color: #333333;
					word-break: break-all;
					&:hover {
						text-decoration: underline;
						color: #a70000;
					}
				}
				&.bgGray {
					padding: 20px;
					background-color: #f1f1f1;
				}
				&.time {
					background-color: #fbf4d8;
					color: #333333;
					@include font-size(14);
					font-weight: 500;
					text-align: center;
					line-height: 1.2;
					width: 40px;
					a {
						display: block;
						color: #333333;
						@include font-size(20);
					}
				}
			}
		}
	}
}

@media print {
	.pagination-item,
	.filtered-item,
	.contact-wrap,
	#header,
	#footer {
		display: none;
	}
}