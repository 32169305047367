.pageTop {
  a {
    display: inline-block;
  }
}
.policy {
  #policyWrap {
    line-height: 1.6;
    margin: 20px 15px;
    padding: 0 30px;
    @media screen and (max-width: 768px){
      padding: 0;
      margin: 0 0 20px 0;
    }
    p {
      margin-bottom: 20px;
      margin-top: 15px;
      @include font-size(14);
    }
    .title {
      clear: both;
      @include font-size(14);
      padding: 1em;
      background: url(../images/policy/bg_gradation_gray.jpg) repeat center top;
      border: none;
      border: 1px solid #ccc;
      font-weight: bold;
    }
    ul {
      li {
        margin-bottom: 10px;
        margin-top: 3px;
        @include font-size(13);
        font-weight: bold;
        list-style-type: none;
        &.top {
          margin-top: 10px;
        }
      }
      ul {
        li {
          font-weight: normal;
        }
      }
    }
    .policy_link {
      color: #333;  
      text-decoration: underline;
      &:hover {
        color: #bc0d17;
      }
    }
  }
}