/* ====================================================
Base style & container
==================================================== */
.wrapper {
	overflow: hidden;
}

body {
	color: $black;
	font-family: $font-gothic;
	line-height: 1.6;
	@include font-size(10);
	-webkit-font-smoothing: antialiased;
}

a {
	color: $red;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

a:focus {
	outline: none;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

.inner {
	display: block;
	margin: 0 auto;
	max-width: 1230px;
	padding: 0 15px;
	@media screen and (max-width: 768px) {
		padding: 0 20px;
	}
}

a.rollover {
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	&:hover {
		opacity: 0.8;
		filter: alpha(opacity=80);
		-ms-filter: "alpha(opacity=80)";
		-moz-opacity: 0.8;
		-khtml-opacity: 0.8;
	}
}

.sp {
	display: none;
	@media screen and (max-width: 768px) {
		display: block;
	}
}

.pc {
	@media screen and (max-width: 768px) {
		display: none;
	}
}

/* ====================================================
Float & clear
==================================================== */
.clearfix {
	*zoom: 1;
	&:before {
		display: table;
		content: '';
	}
	&:after {
		display: table;
		content: '';
		clear: both;
	}
}

/* ====================================================
Header & footer
==================================================== */
#header {
	position: fixed;
	z-index: 9999;
	left: 0;
	width: 100%;
	background-color: #FFF;
	@media screen and (max-width: 768px) {
		padding-bottom: 2px;
	}
	.header-top {
		border-bottom: 2px solid #c1a433;
		margin-bottom: 2px;
		@media screen and (max-width: 768px) {
			height: 62px;
			margin-bottom: 0;
		}
	}
	.logo {
		float: left;
		padding: 3px 0 0 15px;
		@media screen and (max-width: 1110px) {
			width: 260px;
			padding-top: 10px;
		}
		@media screen and (max-width: 990px) {
			width: 245px;
			padding-top: 10px;
		}
		@media screen and (max-width: 768px) {
			width: 35%;
			position: absolute;
			top: 45%;
			left: 0;
			right: 0;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			margin: auto;
			float: none;
			padding: 0;
			max-width: 135px;
		}
		a {
			display: block;
		}
	}
	.info {
		float: right;
		text-align: right;
		width: 100%;
		max-width: 700px;
		font-size: 0;
		@media screen and (max-width: 990px) {
			max-width: 500px;
		}
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
	.tw-ico,
	.search-box,
	.view-btn {
		display: inline-block;
		vertical-align: middle;
		@include font-size(16);
	}
	.search {
		display: inline-block;
		@media screen and (max-width: 768px) {
			display: block;
			margin: 0 20px;
			padding: 20px 0;
		}
	}
	.search-box {
		margin-left: 15px;
		@media screen and (max-width: 990px) {
			margin-left: 10px;
		}
	}
	.s-window {
		width: 290px;
		@media screen and (max-width: 990px) {
			width: 230px;
			@include font-size(14);
		}
		@media screen and (max-width: 768px) {
			width: 100%;
			@include font-size(18);
		}
	}
	.more-search {
		display: inline-block;
		cursor: pointer;
		width: 90px;
		height: 37px;
		text-align: center;
		line-height: 37px;
		@include font-size(14);
		color: $red;
		background: #FFF;
		border: 1px solid #CCC;
		border-radius: 3px;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
		&:hover {
			background-color: #f6f6f6;
		}
	}
	.view-btn {
		margin-left: 20px;
		font-weight: bold;
		@media screen and (max-width: 990px) {
			margin-left: 10px;
		}
		> a {
			display: block;
			height: 56px;
			line-height: 56px;
			background-color: $red;
			color: #FFF;
			width: 240px;
			text-align: center;
			@include font-size(22);
			cursor: pointer;
			@media screen and (max-width: 990px) {
				width: 120px;
				@include font-size(17);
			}
			&::before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				width: 21px;
				height: 30px;
				background-repeat: no-repeat;
				background-size: 100% auto;
				background-image: url(../images/common/viewing_ico.png);
				margin-right: 15px;
				@media screen and (max-width: 990px) {
					margin-right: 8px;
					width: 15px;
					height: 22px;
				}
			}
		}
	}
}

.gnavi-wrap {
	@media screen and (min-width: 769px) {
		display: block !important;
	}
	@media screen and (max-width: 768px) {
		display: none;
		background-color: #333333;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		z-index: 9999;
		min-height: calc(100vh - 64px);
		overflow: auto;

		height: 100%;
	}
}

.sp-btn-wrap {
	width: 26.5%;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	&.left {
		left: 10px;
	}
	&.right {
		right: 10px;
	}
}

.sp-btn {
	li {
		width: 50%;
		float: left;
		text-align: center;
		a {
			display: block;
			color: $black;
			padding-top: 30px;
			background-repeat: no-repeat;
			background-position: center top;
			background-size: 33px auto;
			font-weight: bold;
		}
		&.ico01 {
			a {
				//background-image: url(../images/common/spmenu_ico01.png);
				background: url(../images/common/spmenu_ico01.png) no-repeat center top -2px;
				background-size: 38px 26px;
			}
		}
		&.ico02 {
			a {
				background-image: url(../images/common/spmenu_ico02.png);
			}
		}
		&.ico03 {
			a {
				background-image: url(../images/common/spmenu_ico03.png);
			}
		}
		&.ico04 {
			a {
				background-image: url(../images/common/spmenu_ico04.png);
			}
			&.ico-close {
				a {
					background-image: url(../images/common/ico_close.png);
					background-size: 25px;
				}
			}
		}
		&#sp-menu {
			&.active {
				a {
					background-image: url(../images/common/spmenu_ico01_on.png);
				}
			}
		}
	}
}

.gnavi {
	width: 100%;
	display: table;
	background: #333333;
	position: relative;
	padding-top: 4px;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 4px;
		//background: url("../images/common/border_header.png")repeat-x;
	}
	@media screen and (max-width: 768px) {
		display: block;
		border-top: 1px solid #5e5e5e;
		padding-top: 0;
		&:before {
			display: none;
		}
	}
	li {
		display: table-cell;
		width: 25%;
		text-align: center;
		border-left: 1px solid #494949;
		position: relative;
		@media screen and (max-width: 768px) {
			display: block;
			width: 100%;
			text-align: left;
			border-left: none;
			&.phone-item {
				border: 1px solid #5e5e5e;
				border-top: none;
				background: white;
				padding: 10px 0 20px;
				text-align: center;
				a {
					padding: 0;
					display: block;
					border: none;
					margin: 20px 0;
					&:before, &:after {
						display: none;
					}
				}
			}
			&.menu06 {
				a {
					&::before {
						background-image: url(../images/common/spmenu_ico04.png);
						width: 30px;
						margin-right: 4px;
						margin-left: -3px;
					}
				}
			}
		}
		&:first-of-type {
			border-left: none;
		}
		a {
			position: relative;
			display: block;
			color: #FFF;
			padding: 4px 0;
			@include font-size(18);
			font-weight: bold;
			@media screen and (max-width: 1200px) {
				@include font-size(14);
			}
			@media screen and (max-width: 990px) {
				padding: 9.5px 0;
				@include font-size(13);
			}
			@media screen and (max-width: 768px) {
				padding: 10px 20px;
				border-bottom: 1px solid #5e5e5e;
			}
			&::before {
				content: "";
				display: inline-block;
				position: relative;
				vertical-align: middle;
				width: 35px;
				height: 40px;
				background-repeat: no-repeat;
				background-size: 100% auto;
				margin-right: 15px;
				@media screen and (max-width: 990px) {
					width: 23px;
					height: 26px;
					margin-right: 5px;
				}
				@media screen and (max-width: 768px) {
					margin-right: 10px;
				}
			}
			&::after {
				@media screen and (max-width: 768px) {
					content: "";
					position: absolute;
					top: 50%;
					right: 20px;
					margin-top: -3px;
					vertical-align: middle;
					width: 6px;
					height: 6px;
					border-top: 1px solid #FFF;
					border-right: 1px solid #FFF;
					-ms-transform: rotate(45deg);
					transform: rotate(45deg);
				}
			}
			&.ac-menu {
				&::after {
					@media screen and (max-width: 768px) {
						-ms-transform: rotate(135deg);
						transform: rotate(135deg);
					}
				}
				&.active {
					&::after {
						@media screen and (max-width: 768px) {
							-ms-transform: rotate(-45deg);
							transform: rotate(-45deg);
						}
					}
				}
			}
		}
		&:nth-of-type(1) {
			a {
				&::before {
					background-image: url(../images/common/navi_ico01.png);
				}
			}
		}
		&:nth-of-type(2) {
			a {
				&::before {
					background-image: url(../images/common/navi_ico02.png);
				}
			}
		}
		&:nth-of-type(3) {
			a {
				&::before {
					background-image: url(../images/common/navi_ico03.png);
					width: 30px;
					top: 2px;
					@media screen and (max-width: 768px) {
						width: 25px;
					}
				}
			}
		}
		&:nth-of-type(4) {
			a {
				&::before {
					background-image: url(../images/common/navi_ico04.png);
				}
			}
		}
		&:nth-of-type(5) {
			a {
				&::before {
					background-image: url(../images/common/viewing_ico.png);
					margin-right: 6px;
					background-size: 15px;
					background-position: center;
				}
			}
		}
		.sub-menu {
			position: absolute;
			top: 85%;
			right: 0;
			left: -1px;
			background: #333333;
			padding: 18px 0;
			border-top: 1px solid #494949;
			opacity: 0;
			visibility: hidden;
			@include smooth-transition;
			li {
				display: block;
				width: 100%;
				text-align: left;
				border-left: none;
				a {
					display: block;
					@include font-size(16);
					font-weight: 400;
					padding: 2px 10px 2px 35px;
					@media all and (-ms-high-contrast: none) {
						padding: 4px 10px 0px 35px;
					}
					&:before {
						width: 8px;
						height: 12px;
						margin-right: 0;
						background: url(../images/common/right_white.png) no-repeat;
						position: absolute;
						left: 18px;
						@include v-align;
					}
				}
			}
		}
		&:hover {
			> a {
				background: rgba(255, 255, 255, 0.1);
			}
			.sub-menu {
				opacity: 1;
				visibility: visible;
				top: 100%;
			}
		}
	}
	.sub-navi {
		display: none;
		a {
			background: #333333;
			padding-left: 60px;
			@media screen and (max-width: 768px) {
				background: rgba(255, 255, 255, 0.1);
			}
			&::before {
				display: none;
			}
		}

	}
}

#footer {
	background: #333333;
	color: #cccccc;
	padding: 25px 0 35px;
	@media screen and (max-width: 768px) {
		padding: 0 0 10px;
		.inner {
			padding: 0;
		}
	}
	.footer-ttl {
		@include font-size(19);
		text-align: center;
		margin-bottom: 25px;
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
	.footer-box {
		float: left;
		width: 25%;
		border-right: 1px solid #555555;
		padding: 10px 15px;
		@media screen and (max-width: 768px) {
			display: none;
		}
		&:first-of-type {
			border-left: 1px solid #555555;
		}
		.ttl {
			@include font-size(16);
			font-weight: bold;
			margin-bottom: 10px;
		}
	}
	.footer-list {
		@include font-size(14);
		padding-left: 25px;
		&.mb {
			margin-bottom: 25px;
		}
		li {
			margin: 5px 0;
			a {
				color: #cccccc;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	.footer-navi-wrap {
		clear: both;
		border-top: 1px solid #555555;
		border-bottom: 1px solid #555555;
		margin: 40px 0 20px;
		@media screen and (max-width: 768px) {
			margin: 0 0 10px;
			border: none;
		}
	}
	.footer-navi {
		font-size: 0;
		text-align: center;
		padding: 11px 0;
		@media screen and (max-width: 768px) {
			padding: 0;
		}
		li {
			display: inline-block;
			position: relative;
			@include font-size(14);
			padding: 0 20px;
			&:before {
				content: "";
				display: block;
				height: 13px;
				width: 2px;
				background-color: #555;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
			@media screen and (max-width: 768px) {
				width: 50%;
				padding: 0;
				border: none;
				border-bottom: 1px solid #555555;
				&:nth-of-type(2n) {
					border-left: 1px solid #555555;
				}
				&:before {
					display: none;
				}
			}
			&:first-of-type {
				border-left: none;
			}
			a {
				color: #cccccc;
				@media screen and (max-width: 768px) {
					display: block;
					padding: 10px 0;
				}
				&:hover {
					text-decoration: underline;
					@media screen and (max-width: 768px) {
						text-decoration: none;
						background: rgba(255, 255, 255, 0.1);
					}
				}
			}
		}
	}
	.copyright {
		text-align: center;
	}
}

#topcontrol {
	width: 44px;
	height: 44px;
	background: url(../images/common/pagetop.png) no-repeat;
	z-index: 1;
}

/* ====================================================
common
==================================================== */
.container {
	padding-top: 108px;
	@media screen and (max-width: 768px) {
		padding-top: 64px;
	}

}

input {
	@media screen and (max-width: 768px) {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

select {
	@include font-size(14);
	height: 50px;
	border: 1px solid #999999;
	background: #FFF url(../images/common/arrow_under.png) no-repeat right 20px center;
	background-size: 13px auto;
	border-radius: 3px;
	-moz-appearance: none;
	-webkit-appearance: none;
	padding: 10px 15px;
	&::-ms-expand {
		display: none;
	}
	@media screen and (max-width: 768px) {
		-moz-appearance: none;
	}
}

/* ------ search ------ */
.search {
	position: relative;
}

.s-window {
	height: 37px;
	border: 1px solid #CCC;
	border-radius: 3px;
	padding: 5px 10px;
	@media(max-width: 768px) {
		height: 48px;
	}
}

.s-btn {
	cursor: pointer;
	position: absolute;
	top: 50%;
	right: 10px;
	width: 20px;
	height: 20px;
	background: url(../images/common/search_ico.png) no-repeat 0 0;
	background-size: 100% auto;
	border: none;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	@media(max-width: 768px) {
		width: 24px;
		height: 26px;
	}
}

/* ------ text ------ */
.red {
	color: $red;
}

.link-b {
	&:hover {
		text-decoration: underline;
	}
}

.right-txt {
	text-align: right;
}

.l-arrow {
	&::before {
		content: "";
		display: inline-block;
		margin-right: 10px;
		vertical-align: middle;
		width: 6px;
		height: 6px;
		border-top: 1px solid #a70000;
		border-right: 1px solid #a70000;
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		position: relative;
		top: -1px;
	}
}

/* ------ btn ------ */
.more-btn {
	@include font-size(16);
	@media screen and (max-width: 768px) {
		width: 100%;
		text-align: center;
	}
	a {
		display: inline-block;
		position: relative;
		color: $red;
		border: 1px solid #cccccc;
		border-radius: 3px;
		padding: 5px 19px;
		background-color: #FFF;
		@media screen and (max-width: 768px) {
			display: block;
			position: relative;
			padding: 9px 19px;
		}
		&:hover {
			background-color: #f6f6f6;
		}
		&::before {
			content: "";
			display: inline-block;
			margin-right: 10px;
			vertical-align: middle;
			width: 6px;
			height: 6px;
			border-top: 1px solid $red;
			border-right: 1px solid $red;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			@media screen and (max-width: 768px) {
				position: absolute;
				top: 50%;
				margin-top: -3px;
				left: 10px;
			}
		}
	}
	&-fix {
		a {
			padding: 5px 15px;
		}
	}
}

.yellow-bg {
	background-color: #fbf4d8;
}

.gray-bg {
	background-color: #f1f1f1;
}

