.page-icon_page {
	.section-icon {
		padding: 35px 0 0 0;
	}
	.icon-ttl {
		@include font-size(34);
		@media screen and (max-width: 768px) {
			@include font-size(24);
		}
	}
	.table-container {
		width: 100%;
		margin: 25px 0 30px 0;
		.table-cnr {
			width: 100%;
			@media screen and (max-width: 768px) {
				width: 1200px;
				overflow: auto;
			}
		}
		.table-sty {
			width: 100%;
			@media screen and (max-width: 768px) {
				margin-bottom: 20px;
			}
			.col-01 {
				@media screen and (min-width: 769px) and (max-width: 1000px) {
					width: 110px;
				}
			}
			.col-02 {
				@media screen and (min-width: 769px) and (max-width: 1000px) {
					width: 110px;
				}
			}
			.col-03 {
				@media screen and (min-width: 769px) and (max-width: 1000px) {
					width: 160px;
				}
			}
			th {
				padding: 3px 0;
				background: #333;
				color: #fff;
				@include font-size(14);
				text-align: center;
				&:last-child {
					border-right: 1px solid #333;
				}
			}
			td {
				padding: 11px 15px 11px 20px;
				color: #333;
				@include font-size(16);
				border-bottom: 1px solid #aaa;
				border-left: 1px solid #aaa;
				vertical-align: top;
				&:last-child {
					border-right: 1px solid #aaa;
				}
				img {
					width: auto;
					height: 26px;
					@media screen and (max-width: 768px) {
						height: 23px;
					}
				}
			}
		}
	}
	.contact-wrap {
		.campaign-list2 {
			margin-bottom: 0;
		}
	}
}

.page-icon-new {
	.inner {
		@media(max-width: 768px) {
			padding: 0 15px;
		}
	}
	p {
		line-height: 1.4;
		@include font-size(14);
		margin-bottom: 14px;
		a{
			color: #42413C;
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
	}
	.font_big {
		line-height: 1.4;
		@include font-size(17);
		@media(max-width: 768px) {
			@include font-size(14);
		}
	}
	.font_s {
		line-height: 1.2;
		@include font-size(12);
	}
	.font_b {
		font-weight: bold;
	}
}

.heading-icon-top {
	display: inline-block;
	width: 100%;
	img {
		width: 100%;
	}
	area{
		outline: none;
	}
}

.content-icon {
	padding: 10px 20px;
	@media(max-width: 768px) {
		padding: 10px 0;
	}
}

.ttl-icon {
	background-image: url(../images/icon-page01/h1.gif);
	background-size: 100% 100%;
	height: auto;
	width: 100%;
	text-align: left;
	padding: 8px 20px 0;
	@include font-size(19);
	margin-bottom: 10px;
	a{
		color: #6E6C64;
		&:hover{
			text-decoration: underline;
		}
	}
}

.table_icon {
	width: 100%;
	margin-bottom: 30px;
	@media(max-width: 768px) {
		width: 768px;
		margin-bottom: 20px;
	}
	th {
		text-align: center;
		vertical-align: middle;
		@include font-size(14);
		font-weight: bold;
		border-right: 3px solid #fff;
		border-bottom: 4px solid #fff;
	}
	td {
		@include font-size(14);
		p {
			padding: 0 15px;
			margin-bottom: 14px;
			line-height: 1.4;
		}
	}
	.td_center {
		text-align: center;
		vertical-align: middle;
	}
	.td_1 {
		background-color: #f7f9f9;
		border-bottom-width: medium;
		border-bottom-style: solid;
		border-bottom-color: #999;
		border-right: 3px solid #fff;
		padding-top: 10px;
		padding-bottom: 10px;
		color: #333;
	}
}

.table-scroll {
	@media(max-width: 768px) {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

.th_cate {
	background-image: url(../images/icon-page01/th_category.gif);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	height: 33px;
	width: 15%;
}

.th_icon {
	background-image: url(../images/icon-page01/th_icon.gif);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	height: 33px;
	width: 20%;
}

.th_name {
	background-image: url(../images/icon-page01/th_name.gif);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	height: 33px;
	width: 15%;
}

.th_discript {
	background-image: url(../images/icon-page01/th_discription.gif);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	height: 33px;
	width: 50%;
}

.table_pic {
	border-collapse: separate;
	border-spacing: 2px;
	padding: 10px;
	width: 100%;
	margin-top: 0;
	margin-bottom: 10px;
	text-align: center;
	vertical-align: middle;
	.pic_wrapper {
		padding: 5px 10px;
		border: thin solid #999;
		width: 50%;
		background-color: #f7f9f9;
		img {
			width: 100%;
		}
	}
}