.page-useroubo {
	background: #f1f1f1;
	// color: #000;
	@include font-size(14);
	.useroubo-main {
		padding: 20px 0;
	}
	.useroubo-content {
		background: #fff;
		padding: 20px 40px 0 40px;
		line-height: 1.5;
		@media screen and (max-width: 768px) {
			padding: 10px 20px 0 20px;
		}
		ol {
			margin: 0 2em 2em 4em;
			@media screen and (max-width: 768px) {
				margin: 0 1em 1em 2em;
			}
			position: relative;
			li {
				list-style: none;
				padding-left: 18px;
				&:before {
					position: absolute;
					content: counter(number) ". ";
					counter-increment: number;
					left: 0;
				}
			}
		}
		form {
			text-align: center;
		}
		.mail-link {
			text-decoration: underline;
			color: #000;
			&:hover {
				color: #a70000;
			}
		}
		.mail-icn {
			@include font-size(10);
		}
		.btn-sty {
			width: 100px;
			height: 28px;
			font-weight: bold;
		}
		.mb10 {
			margin-bottom: 10px;
		}
		.mb20 {
			margin-bottom: 20px;
		}
		.mb30 {
			margin-bottom: 30px;
		}
	}
}