.page-sitemap {
  @include font-size(14);
  #sitemapWrap{
    a {
      display: block;
      margin:0.4em 0;
      padding-left: 13px;
      background: url(../images/common/arrow_red.gif) 0 center no-repeat;
      color:#bc0d17;
      text-decoration:none;
      &:link {
        color: #bc0d17;
      }
      &:visited {
        color: #f5616a;
      }
      &:hover {
        color: #0087ba;
      }
      &:active {
        color: #0087ba;
      }
    }
    .top {
      p {
        border-bottom: none;
      }
    }
    .sitemapMargin1 {
      border-top:#CCC 1px solid;
      p {
        border-bottom:#CCC 1px solid;
        margin:0 0 0 6%;
      }
    }
    .sitemapMargin2 {
      border-top:#CCC 1px solid;
      p {
        border-bottom:#CCC 1px solid;
        margin:0 0 0 40%;
      }
    }
    p {
      a {
        &.greySmall {
          background:url(../images/common/arrow_lnav_off.gif) 0 center no-repeat;
          color:#666;
          display: block;
          font-size:85%;
          margin:0.5em 0 0 10%;
          padding-left: 13px;
        }
      }
      &.sitemapNoborder {
        border-bottom:none;
      }
    }
  }
  .unit_thumbnail {
    padding: 0 !important;
    margin: 0 !important;
    display: inline-block;
    content: '';
    z-index: 9998;
    position: absolute;
    top: 0;
    left: 0;
  }
}
