.howtoMenu {
	margin: 10px 0;
	ul {
		li {
			display: inline-block;
			margin-right: 10px;
			@include font-size(12);
			a {
				text-decoration: underline;
				color: #0087ba;
				@include font-size(12);
			}
		}
	}
}

.howtoBox {
	background: url("../images/program/howto/howto_bg_box.gif") left top;
	padding: 7px;
	.howtoBox-in {
		padding: 15px;
		overflow: auto;
		background-color: #fff;
		.title {
			position: relative;
			padding-left: 25px;
			border-bottom: 1px dotted #a6a6a6;
			margin-bottom: 10px;
			&:before {
				display: block;
				content: '';
				background: url("../images/program/howto/icon_nihoneiga_20px.gif") no-repeat center center;
				width: 21px;
				height: 20px;
				background-size: 100% 100%;
				position: absolute;
				left: 0;
				top: 45%;
				transform: translateY(-50%);
			}
			h2 {
				font-weight: bold;
				@include font-size(18);
			}
		}
		.left {
			float: left;
			width: 40%;
			@media screen and (max-width: 768px) {
				width: 100%;
			}
			a {
				color: black;
			}
			span {
				text-decoration: underline;
				color: #a70000;
			}
		}
		.right {
			float: right;
			width: 57%;
			@media screen and (max-width: 768px) {
				width: 100%;
			}
		}
	}
}

.img-caption {
	margin: 0;
	img {
		width: 100%;
	}
	figcaption {
		@include font-size(11);
		a {
			color: #a70000;
			text-decoration: underline;
			@include font-size(11);
		}
	}
}

#howtoIcon {
	border: 1px #CCC solid;
	width: 100%;
	@media screen and (max-width: 768px) {
		min-width: 620px;
	}
	th {
		background: #139ace url("../images/program/howto/bg_line.gif");
		border-left: #FFF 1px solid;
		color: #fff;
		padding: 5px 8px;
		font-weight: bold;
		text-align: center;
		@include font-size(12);
	}
	td {
		border-left: #CCC 1px solid;
		border-bottom: #CCC 1px solid;
		text-align: center;
		padding: 0.5em 1em;
		line-height: 1.5;
		@include font-size(11);
	}
	tr.bgColor {
		background: #f5f5f5;
	}
	.imgWrap {
		display: inline-block;
		margin: 2px;
	}
}

#page-howto {
	.txt-basic.txt-sm {
		line-height: 1.5;
		@include font-size(13);
	}
}