.ttl_caution {
	margin: 0 0 20px 0;
	display: inline-block;
	width: 100%;
	img {
		width: 100%;
	}
}

.m20b {
	margin-bottom: 20px;
}

.m10b {
	margin-bottom: 10px;
}

.main-user {
	padding: 20px 30px;
	border: 1px solid #baa242;
	background: #fff9df;
	border-radius: 5px;
	line-height: 1.5;
	@media(max-width: 768px) {
		padding: 10px;
	}
	.fs15 {
		@include font-size(15);
	}
	p {
		@include font-size(13);
	}
	.txt-user {
		background: #FFF;
		margin: 20px 0 0 0;
		padding: 15px 20px;
		@include font-size(11);
		font-weight: 400;
	}
}

.userList {
	display: inline-block;
	width: 100%;
	li {
		list-style-type: disc;
		list-style-position: outside;
		margin: 0 0 0 20px;
		@include font-size(13);
	}
}

.userCautions_B {
	background: #FFF;
	font-size: 100%;
	font-weight: bold;
	margin: 1em;
	padding: 1em;
	.linkRed {
		color: #f5616a;
		text-decoration: underline;
		&:visited {
			color: #f5616a;
		}
		&:hover {
			color: #0087ba;
		}
	}
}

.list-userBoxwrap {
	display: inline-block;
	width: 100%;
	li {
		border: #baa242 1px solid;
		margin: 20px 0 0 0;
		@include font-size(13);
		.tit {
			background: #fff9df;
			border-bottom: #baa242 1px solid;
			margin: 0;
			padding: 15px;
			@media(max-width: 768px) {
				padding: 10px;
			}
			.handleDay {
				float: right;
				@include font-size(12);
				padding-left: 10px;
				text-align: right;
				width: 25%;
				@media(max-width: 768px) {
					text-align: left;
					width: 100%;
					padding-left: 0;
					padding-bottom: 5px;
				}
			}
			.handleName {
				float: right;
				padding-left: 20px;
				width: 35%;
				@media(max-width: 768px) {
					width: 100%;
					padding-left: 0;
				}
			}
			.handleTitle {
				float: left;
				width: 40%;
				@media(max-width: 768px) {
					width: 100%;
				}
				.small {
					@include font-size(11);
				}
				.big {
					font-weight: bold;
					@include font-size(15);
					@media(max-width: 768px) {
						@include font-size(13);
					}
				}
			}
		}
		.text {
			background: #fff;
			clear: both;
			line-height: 1.5;
			padding: 15px 20px;
			@media(max-width: 768px) {
				padding: 10px;
			}
			.text-blue{
				color: #0000ff;
			}
		}
	}
}

.userNo {
	padding: 20px;
	text-align: center;
	a{
		text-decoration: underline;
		color: #333;
		&:hover{
			color: #a70000;
		}
	}
	@media(max-width: 768px) {
		padding: 10px;
	}
}

.red-bold {
	color: #C00;
}

.linkBlue {
	color: #0087ba;
	text-decoration: underline;
	&:hover {
		color: #C00;
	}
}