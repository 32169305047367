#osusume {
	.plan-wrap {
		padding-top: 31px;
		@media screen and (max-width: 768px) {
			padding-bottom: 20px;
		}
		.ttl-wrap {
			.top-ttl {
				@include font-size(34);
				letter-spacing: 0;
				margin-bottom: 38px;
				@media screen and (max-width: 768px) {
					@include font-size(30);
				}
			}
		}
	}
	.plan-list-slide {
		.plan-list {
			li {
				width: calc(96.6% / 3);
				margin-left: 0 !important;
				margin-right: 1.7%;
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
	}
}
.banner_img {
	img {
		margin: 0 auto;
	}
}