.entryArea {
	padding: 30px 40px 0;
	@media(max-width: 768px) {
		padding: 20px 0 0;
	}
	.heading {
		margin-bottom: 30px;
		@media(max-width: 768px) {
			margin-bottom: 15px;
		}
	}
	.heading-md {
		padding: 22px 0 20px;
		@media(max-width: 768px) {
			padding: 5px 0 15px;
		}
		img {
			width: 100%;
		}
	}
}

.entryLine {
	display: inline-block;
	width: 100%;
	img {
		width: 100%;
	}
}

.infoBox {
	border: #be0a19 solid 3px;
	margin-bottom: 38px;
	padding: 15px;
	text-align: center;
	@media(max-width: 768px) {
		padding: 10px;
		margin-bottom: 20px;
	}
	p {
		font-weight: bold;
		@include font-size(15);
		line-height: 24px;
		color: #be0a19;
		@media(max-width: 768px) {
			@include font-size(13);
		}
		a {
			color: #be0a19;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}

		}
	}
	.cb {
		color: #000;
	}
	.txt {
		@include font-size(20);
		@media(max-width: 768px) {
			@include font-size(13);
		}
		a {
			&:hover {
				text-decoration: none;
			}
		}
	}
	.officialBtn {
		max-width: 491px;
		margin: 0 auto;
		padding: 18px 0;
	}
}

.cntAreaEntry {
	display: block;
	max-width: 620px;
	padding: 0;
	margin: 0 auto;
	text-align: center;
	.heading-sm {
		padding-bottom: 10px;
	}
	.link {
		font-weight: normal;
		padding: 0 0 35px 0;
		@include font-size(14);
		@media(max-width: 768px) {
			padding-bottom: 20px;
		}
		a {
			color: #000;
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
	}
	p {
		font-weight: bold;
		padding: 28px 0;
		@include font-size(16);
		@media(max-width: 768px) {
			padding: 14px 0;
			@include font-size(14);
		}
	}
	.arrow {
		width: 43px;
		margin: 0 auto;
		padding-bottom: 30px;
		@media(max-width: 768px) {
			padding-bottom: 15px;
		}
	}
}