.page-faq {
	padding-bottom: 100px;
	@media screen and (max-width: 768px) {
		padding-bottom: 50px;
	}
	.top-ttl{
		padding-top: 31px;
		margin-bottom: 25px;
	}
	.faq-sec-ttl {
		width: 100%;
		@include font-size(22);
		padding: 2.5px 0;
	    border: #be0a19 solid 3px;
	    text-align: center;
	    font-weight: bold;
	    color: #be0a19;
	    @media screen and (max-width: 768px) {
			@include font-size(18);
		}
	}
	.cntArea {
	    padding: 20px 0;
    	border-bottom: #969696 solid 1px;
    	&.noBor {
    		border: none;
    	}
    	.question {
    		position: relative;
    		padding-bottom: 7px;
    		padding-left: 20px;
		    @include font-size(16);
		    font-weight: bold;
		    color: #be0a19;
		    &:before {
		    	position: absolute;
		    	left: 0;
		    	top: 0;
		    	content: 'Q.';
		    }
    	}
    	.textArea {
    		position: relative;
    		@include font-size(14);
    		padding-left: 20px;
    		&:before {
    			position: absolute;
    			@include font-size(16);
		    	left: 0;
		    	top: 0;
		    	content: 'A.';
		    	padding-right: 5px;
		    }
		    a {
	    	    text-decoration: underline;
	    	    &:hover {
	    	    	text-decoration: none;
	    	    }
		    }
    	}
	}
}
