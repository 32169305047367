.col-2-content-wrapper {
	display: flex;
	justify-content: space-between;
	.main-content {
		width: calc(100% - 270px);
	}
	.sidebar {
		width: 245px;
		margin-bottom: 30px;
	}
	@media screen and (max-width: 768px) {
		display: block;
		.main-content, .sidebar {
			width: 100%;
			margin-bottom: 30px;
		}
	}
}

.breadcrumb {
	margin: 15px 0;
	padding-left: 20px;
	position: relative;
	&:before {
		display: block;
		content: '';
		background: url("../images/common/icon_nihoneiga.gif") no-repeat center center;
		background-size: 100% 100%;
		width: 14px;
		height: 14px;
		position: absolute;
		top: 4px;
		left: 0;
	}
	* {
		@include font-size(14);
	}
	a {
		text-decoration: underline;
		&:link {
			color: #bc0d17;
		}
		&:visited {
			color: #f5616a;
		}
	}
	i {
		margin: 0 2px;
	}
}

.has-breadcrumbs {
	padding-top: 113px;
	@media screen and (max-width: 768px) {
		padding-top: 65px;
	}
}

#lNavTitle {
	background: #404040;
	background: -moz-linear-gradient(top, #404040 0%, #000000 100%);
	background: -webkit-linear-gradient(top, #404040 0%, #000000 100%);
	background: linear-gradient(to bottom, #404040 0%, #000000 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#404040', endColorstr='#000000', GradientType=0);
	color: #fff;
	min-height: 24px;
	padding: 12px 0 11px 0;
	border-bottom: 1px solid #999;
	text-align: center;
	letter-spacing: 0.1em;
	h2 {
		font-weight: bold;
		@include font-size(16);
	}
}

#lNav {
	margin-bottom: 15px;
	border-bottom: 8px solid black;
	ul {
		li {
			font-weight: bold;
			border-left: 1px solid black;
			border-right: 1px solid black;
			border-bottom: 1px solid #b8b8b8;
			a {
				position: relative;
				background: #ffffff;
				background: -moz-linear-gradient(top, #ffffff 0%, #efefef 100%);
				background: -webkit-linear-gradient(top, #ffffff 0%, #efefef 100%);
				background: linear-gradient(to bottom, #ffffff 0%, #efefef 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#efefef', GradientType=0);
				color: #000;
				display: block;
				padding: 13px 5px 12px 30px;
				text-decoration: none;
				@include font-size(12);
				@include smooth-transition;
				&:before {
					display: block;
					content: '';
					background: url("../images/shicho/houjin/icon-arrow.png") no-repeat center center;
					background-size: 100% 100%;
					width: 10px;
					height: 10px;
					overflow: hidden;
					border-radius: 2px;
					position: absolute;
					@include v-align;
					left: 10px;
				}
				&:hover, &.cur {
					background: #bc0d18;
					color: white;
					@include smooth-transition;
					&:before {
						background: url("../images/shicho/houjin/icon-arrow-white.png") no-repeat center center;
						background-size: 100% 100%;
					}
				}
			}
		}
		&.lNavInnerLi {
			li {
				a {
					background: white;
					color: #000;
					padding-left: 40px;
					&:before {
						width: 0;
						height: 0;
						left: 30px;
						border: 3px solid transparent;
						border-left: 3px solid black;
						@include smooth-transition;
					}
					&:hover, &.cur {
						background: #bc0d18;
						color: white;
						&:before {
							border-left-color: white;
							@include smooth-transition;
						}
					}
				}
			}
		}
	}
}

.lNav-red {
	ul{
		border-bottom: 2px solid #bc0d17;
	}
}

.unit_thumbnail {
	padding: 0 !important;
	margin: 0 !important;
	display: inline-block;
	content: '';
	z-index: 9998;
	position: absolute;
	top: 0;
	left: 0;
}

#pr {
	h2 {
		margin: 0 0 10px 0;
	}
	h3 {
		margin: 10px 0 10px 0;
	}
	ul {
		li {
			float: left;
			padding: 0 2px 5px 2px;
		}
	}
}

#mainTitle2 {
	background: url("../images/shicho/houjin/main_tit_bg_1.gif") no-repeat center top;
	background-size: 100% 100%;
	color: #fff;
	letter-spacing: 0.2em;
	overflow: hidden;
	padding: 18px 0;
	text-align: center;
	position: relative;
	h2 {
		font-weight: bold !important;
		line-height: 1.2;
		@include font-size(18);
		span {
			font-size: smaller;
		}
	}
}

#mainInner {
	background: #fff;
	border-width: 0 1px 1px 1px;
	border-color: #ccc;
	border-style: solid;
	margin-bottom: 30px;
	padding: 19px;
}

#target {
	margin-bottom: 20px;
	@include font-size(14);
	dl {
		margin: 20px 20px 5px;
		border-bottom: 1px dotted #999;
		@media screen and (max-width: 768px) {
			margin: 20px 0 10px;
		}
		dt {
			float: left;
			width: 100px;
			padding: 10px 0 10px 10px;
			clear: both;
			font-weight: bold;
			border-top: 1px dotted #999;
			@media screen and (max-width: 768px) {
				float: none;
				width: auto;
				padding-left: 0;
				padding-bottom: 0;
			}
		}
		dd {
			width: calc(100% - 100px);
			margin-left: 100px;
			padding: 10px 10px 10px 0;
			border-top: 1px dotted #999;
			@media screen and (max-width: 768px) {
				border-top: none;
				float: none;
				width: auto;
				margin-left: 0;
			}
		}
	}
}

#scene {
	margin: 0 auto 30px;
	text-align: center;
}

.call_number_area {
	margin: 0 auto;
	max-width: 536px;
	background-color: #fdf9ef;
	border: 3px #a4825f solid;
	font-weight: bold;
	@include font-size(18);
	text-align: center;
	border-radius: 5px;
	.tel_call_number {
		display: block;
		line-height: 1.2;
		@media screen and (min-width: 769px) {
			pointer-events: none;
		}
		span {
			line-height: 1.2 !important;
		}
	}
	.ttl_call_number_houjin {
		font-size: 18px !important;
		span {
			font-size: 16px;
		}
	}
}

.pageTop {
	margin: 15px 0 10px;
	text-align: right;
}

#bannerArea {
	ul {
		display: flex;
		justify-content: space-around;
		align-items: center;
		@media screen and (max-width: 768px) {
			flex-wrap: wrap;
			li {
				margin-bottom: 20px;
			}
		}
	}
}

.center {
	text-align: center;
}

#gojuonList {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 30px;
	li {
		padding: 5px;
		margin-right: 5px;
		border: 1px solid #cccccc;
		border-radius: 5px;
		background: #ffffff;
		background: -moz-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
		background: -webkit-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
		background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f1f1f1', GradientType=0);
		@include smooth-transition;
		a {
			display: block;
			padding: 2px 8px;
			border: 1px solid #cccccc;
			font-weight: bolder;
			background-color: #fff;
			color: #000;
			border-radius: 5px;
			overflow: hidden;
			text-shadow: 0 0 10px rgba(0, 0, 0, .1), 0 0 10px rgba(0, 0, 0, .1), 0 0 10px rgba(0, 0, 0, .1), 0 0 10px rgba(0, 0, 0, .1);
			@include font-size(16);
			@include smooth-transition;
		}
		&:hover {
			position: relative;
			@include smooth-transition;
			&:after {
				display: block;
				content: '';
				width: 0;
				height: 0;
				border: 5px solid transparent;
				border-top: 5px solid #0f88bc;
				position: absolute;
				bottom: -4px;
				@include h-align;
				z-index: 1;
			}
			a {
				color: white;
				background: url("../images/common/blue-pattern.png") no-repeat center center;
				background-size: 100% 100%;
				@include smooth-transition;
				text-shadow: 0 0 10px rgba(255, 255, 255, .1), 0 0 10px rgba(255, 255, 255, .1), 0 0 10px rgba(255, 255, 255, .1), 0 0 10px rgba(255, 255, 255, .1);
			}
		}
	}
}