.table-news-item {
	width: 100%;
	@include font-size(13);
	tr {
		th, td {
			padding: 1em;
			vertical-align: top;
			border: 1px solid #ccc;
			background:#f0f0f0 url(../images/jimaku/bg_gradation_gray.jpg) 0 0 repeat-x;
		}
		th {
			text-align: center;
			font-weight: bold;
			border-right: none;
			@-moz-document url-prefix() {
				width: 180px;
			}
			@media screen and (max-width: 768px) {
				@-moz-document url-prefix() {
					width: auto;
				}
			}
		}
		td {
			border-left: 1px dotted #ccc;
			color: #a70000;
			a {
				&:hover {
					color: #0087ba;
				}
			}
		}
		&.active{
			th{
				background: url(../images/jimaku/news_bgItem.jpg) 0 0 repeat-x;
			}
		}
	}
}

.txt-news {
	margin: 15px 0;
	@include font-size(13);
	border-bottom: 1px dotted #ccc;
	padding-bottom: 15px;
	line-height: 1.5em;
	p {
		margin-bottom: 10px;
	}
}