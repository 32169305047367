.search-page{
	.filtered-item {
		padding: 15px 0 30px;
		@media(max-width: 768px){
			padding: 15px 0;
		}
	}
	.search-module{
		padding: 25px 0 16px;
		.txt-basic{
			margin-bottom: 5px;
		}
		.navi-links{
			margin-bottom: 20px;
		}
	}
	.word-ttl{
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 1.4;
		@media(max-width: 768px){
			position: relative;
			height: 35px;
			color: #fff;
			background-color: #333;
			&:before{
				display: block;
				content: '';
				width: 10px;
				height: 10px;
				transform: rotate(45deg);
				position: absolute;
				right: 20px;
				border-left: 2px solid #fff;
				border-top: 2px solid #fff;
				top: 13px;
			}
			&.active{
				&:before{
					top: 8px;
					border-right: 2px solid #fff;
					border-bottom: 2px solid #fff;
					border-left: none;
					border-top: none;
				}
			}
		}
	}
}

.list-search{
	display: inline-block;
	width: 100%;
	padding-top: 35px;
	.main-list-search{
		float: left;
		width: 71.6%;
		@media(max-width: 768px){
			width: 100%;
		}
	}
	.sidebar-search{
		float: left;
		width: 28.4%;
		padding-left: 50px;
		@media(max-width: 768px){
			width: 100%;
			padding-left: 0;
		}
	}
}

.list-item-search{
	display: inline-block;
	width: 100%;
	li{
		padding: 20px 0 18px;
		border-top: 1px solid #cccccc;
		box-sizing: border-box;
		&:last-child{
			border-bottom: 1px solid #cccccc;
		}
	}
}
.item-search{
	display: inline-block;
	width: 100%;
	.img{
		float: left;
		width: 300px;
		padding-right: 20px;
		img{
			width: 100%;
		}
	}
	.content{
		float: left;
		width: calc(100% - 300px);
		@media(max-width: 768px){
			width: 100%;
		}
	}
}
.ttl-search{
	line-height: 1.6;
	&.ttl-search-style01{
		@include font-size(20);
		color: #333333;
		font-weight: 500;
		margin-bottom: 35px;
		span{
			font-weight: bold;
		}
		@media(max-width: 768px){
			@include font-size(17);
			margin-bottom: 25px;
		}
	}
	&.ttl-search-top{
		color: #a70000;
		@include font-size(16);
		background: #fbf4d8;
		padding: 5px 5px 5px 10px;
		font-weight: 500;
		@media(max-width: 768px){
			@include font-size(12);
			padding: 5px 8px;
			line-height: 1.25;
		}
	}
	&.ttl-search-ct{
		color: #a70000;
		@include font-size(24);
		font-weight: 500;
		line-height: 2;
		margin-bottom: 10px;
		@media(max-width: 768px){
			@include font-size(15);
			line-height: 1.8;
		}
		a{
			&:hover{
				text-decoration: underline;
			}
		}
	}
}
.actor-list{
	margin-bottom: 10px;
	@include font-size(16);
	color: #333;
	@media(max-width: 768px){
		@include font-size(13);
		line-height: 1.8;
	}
	dt{
		display: inline-block;
		width: 42px;
		float: left;
		@media(max-width: 768px){
			width: 50px;
		}
	}
	dd{
		display: inline-block;
		width: calc(100% - 42px);
		line-height: 1.25;
		@media(max-width: 768px){
			width: calc(100% - 50px);
		}
	}
}

.time-list{
	display: flex;
	align-items: center;
	dt{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 64px;
		background: #bbbbbb;
		color: #fff;
		@include font-size(16);
		font-weight: bold;
	}
	dd{
		display: flex;
		align-items: center;
		width: calc(100% - 64px);
		background: #f1f1f1;
		color: #333;
		line-height: 1.25;
		@include font-size(16);
		padding: 10px 5px 10px 15px;
		font-weight: 500;
	}
}

.list-news{
	display: inline-block;
	width: 100%;
	li{
		margin-bottom: 20px;
		@media(max-width: 768px){
			float: left;
			width: 48%;
			margin-right: 4%;
			&:nth-child(2n){
				margin-right: 0;
			}
		}
		.img{
			img{
				display: block;
				margin: 0 auto;
				@media(max-width: 768px){
					width: 100%;
				}
			}
		}
		.txt{
			padding-top: 10px;
			color: #333;
			@include font-size(15);
			font-weight: 500;
			line-height: 1.46;
			@media(max-width: 768px){
				line-height: 1.53;
			}
		}
	}
}
.color-pink{
	background-color: #ffcece;
}

@media(max-width: 768px){
	.merge-content{
		margin-bottom: 15px;
		.img-left{
			float: left;
			width: 35%;
		}
		.title{
			float: left;
			width: 65%;
			padding-left: 10px;
		}
	}
}
.next-link{
	display: inline-block;
	width: 100%;
	text-align: right;
	@include font-size(14);
	font-weight: 600;
	color: #a70000;
	span{
		position: relative;
		&:after{
			content: "";
			display: block;
			width: 8px;
			height: 8px;
			border-right: 1px solid #a70000;
			border-top: 1px solid #a70000;
			transform: rotate(45deg);
			position: absolute;
			left: -16px;
			top: 3px;
		}
	}
}

.pagination {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin: 22px 0 30px;
	@media(max-width: 768px){
		justify-content: space-between;
		margin: 22px auto 50px;
		max-width: 350px;
	}
	a{
		color: #a70000;
		float: left;
		padding: 7px 19px;
		margin: 0 5px;
		text-decoration: none;
		border: 1px solid #cccccc;
		@include font-size(16);
		font-weight: 600;
		border-radius: 3px;
		&:hover:not(.active){
		background-color: #a70000;
			color: #fff;
		}
		@media(max-width: 768px){
			padding: 6px 15px;
			@include font-size(13);
			margin: 0 2px;
		}
		@media(max-width: 374px){
			padding: 2px 10px;
			margin: 0 2px;
		}
		&.first{
			margin-left: 0;
			padding: 7px 20px 7px 11px;
			@media(max-width: 768px){
				padding: 6px 17px 6px 7px;
			}
			@media(max-width: 374px){
				padding: 2px 12px 2px 2px;
			}
		}
		&.last{
			margin-right: 0;
			padding: 7px 11px 7px 20px;
			@media(max-width: 768px){
				padding: 6px 7px 6px 17px;
			}
			@media(max-width: 374px){
				padding: 2px 2px 2px 12px;
			}
		}
	}
	.active{
		a{
			background-color: #a70000;
			border: 1px solid #a70000;
			color: white;
		}
	}
}