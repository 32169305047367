.page-404 {
  .pb25 {
    padding-bottom: 25px;
  }
  .cen {
    text-align: center !important;
  }
  .title_notFound {
    background: #1d1d1d;
    color: #fff;
    padding: 8px;
    @include font-size(16);
    font-weight: bold;
  }
  .main-text {
    background: #fff;
    border: 1px solid #CCCCCC;
    padding: 10px 10px 40px 10px;
    margin: 0;
    p {
      text-align: justify;
      line-height: 1.5;
      @include font-size(14);
      color: #000;
      a {
        text-decoration: underline;
        color: #000;
      }
      a:hover, a:active {
        color: #bc0d17;
      }
    }
  }
}