@charset "UTF-8";
/* ====================================================
reset style
==================================================== */
html {
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

main {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

address, caption, cite, code, dfn, em, th, var {
  font-style: normal;
  font-weight: normal;
}

strong {
  font-weight: bold;
}

ul li {
  list-style: none;
}

ol {
  counter-reset: number;
}

ol li {
  display: list-item;
}

ol li:before {
  content: counter(number) ". ";
  counter-increment: number;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

a {
  text-decoration: none;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000000;
}

del, ins {
  text-decoration: none;
}

td {
  display: table-cell;
}

/* ====================================================
Font
==================================================== */
/* ====================================================
color
==================================================== */
/* ====================================================
Position & transform
==================================================== */
/* ====================================================
Color
==================================================== */
/* ====================================================
Sizing
==================================================== */
/* ====================================================
Misc
==================================================== */
/* ====================================================
Base style & container
==================================================== */
.wrapper {
  overflow: hidden;
}

body {
  color: #333;
  font-family: "游ゴシック", "游ゴシック体", YuGothicMedium, YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ ゴシック", "MS Gothic", "HiraKakuProN-W3", "TakaoExゴシック", TakaoExGothic, "MotoyaLCedar", "Droid Sans Japanese", sans-serif;
  line-height: 1.6;
  font-size: 10px;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #a70000;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a:focus {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.inner {
  display: block;
  margin: 0 auto;
  max-width: 1230px;
  padding: 0 15px;
}

@media screen and (max-width: 768px) {
  .inner {
    padding: 0 20px;
  }
}

a.rollover {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a.rollover:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  -ms-filter: "alpha(opacity=80)";
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
}

.sp {
  display: none;
}

@media screen and (max-width: 768px) {
  .sp {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .pc {
    display: none;
  }
}

/* ====================================================
Float & clear
==================================================== */
.clearfix {
  *zoom: 1;
}

.clearfix:before {
  display: table;
  content: '';
}

.clearfix:after {
  display: table;
  content: '';
  clear: both;
}

/* ====================================================
Header & footer
==================================================== */
#header {
  position: fixed;
  z-index: 9999;
  left: 0;
  width: 100%;
  background-color: #FFF;
}

@media screen and (max-width: 768px) {
  #header {
    padding-bottom: 2px;
  }
}

#header .header-top {
  border-bottom: 2px solid #c1a433;
  margin-bottom: 2px;
}

@media screen and (max-width: 768px) {
  #header .header-top {
    height: 62px;
    margin-bottom: 0;
  }
}

#header .logo {
  float: left;
  padding: 3px 0 0 15px;
}

@media screen and (max-width: 1110px) {
  #header .logo {
    width: 260px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 990px) {
  #header .logo {
    width: 245px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  #header .logo {
    width: 35%;
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: auto;
    float: none;
    padding: 0;
    max-width: 135px;
  }
}

#header .logo a {
  display: block;
}

#header .info {
  float: right;
  text-align: right;
  width: 100%;
  max-width: 700px;
  font-size: 0;
}

@media screen and (max-width: 990px) {
  #header .info {
    max-width: 500px;
  }
}

@media screen and (max-width: 768px) {
  #header .info {
    display: none;
  }
}

#header .tw-ico,
#header .search-box,
#header .view-btn {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-size: 1.6rem;
}

#header .search {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  #header .search {
    display: block;
    margin: 0 20px;
    padding: 20px 0;
  }
}

#header .search-box {
  margin-left: 15px;
}

@media screen and (max-width: 990px) {
  #header .search-box {
    margin-left: 10px;
  }
}

#header .s-window {
  width: 290px;
}

@media screen and (max-width: 990px) {
  #header .s-window {
    width: 230px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  #header .s-window {
    width: 100%;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

#header .more-search {
  display: inline-block;
  cursor: pointer;
  width: 90px;
  height: 37px;
  text-align: center;
  line-height: 37px;
  font-size: 14px;
  font-size: 1.4rem;
  color: #a70000;
  background: #FFF;
  border: 1px solid #CCC;
  border-radius: 3px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

#header .more-search:hover {
  background-color: #f6f6f6;
}

#header .view-btn {
  margin-left: 20px;
  font-weight: bold;
}

@media screen and (max-width: 990px) {
  #header .view-btn {
    margin-left: 10px;
  }
}

#header .view-btn > a {
  display: block;
  height: 56px;
  line-height: 56px;
  background-color: #a70000;
  color: #FFF;
  width: 240px;
  text-align: center;
  font-size: 22px;
  font-size: 2.2rem;
  cursor: pointer;
}

@media screen and (max-width: 990px) {
  #header .view-btn > a {
    width: 120px;
    font-size: 17px;
    font-size: 1.7rem;
  }
}

#header .view-btn > a::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 21px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-image: url(../images/common/viewing_ico.png);
  margin-right: 15px;
}

@media screen and (max-width: 990px) {
  #header .view-btn > a::before {
    margin-right: 8px;
    width: 15px;
    height: 22px;
  }
}

@media screen and (min-width: 769px) {
  .gnavi-wrap {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  .gnavi-wrap {
    display: none;
    background-color: #333333;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 9999;
    min-height: calc(100vh - 64px);
    overflow: auto;
    height: 100%;
  }
}

.sp-btn-wrap {
  width: 26.5%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sp-btn-wrap.left {
  left: 10px;
}

.sp-btn-wrap.right {
  right: 10px;
}

.sp-btn li {
  width: 50%;
  float: left;
  text-align: center;
}

.sp-btn li a {
  display: block;
  color: #333;
  padding-top: 30px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 33px auto;
  font-weight: bold;
}

.sp-btn li.ico01 a {
  background: url(../images/common/spmenu_ico01.png) no-repeat center top -2px;
  background-size: 38px 26px;
}

.sp-btn li.ico02 a {
  background-image: url(../images/common/spmenu_ico02.png);
}

.sp-btn li.ico03 a {
  background-image: url(../images/common/spmenu_ico03.png);
}

.sp-btn li.ico04 a {
  background-image: url(../images/common/spmenu_ico04.png);
}

.sp-btn li.ico04.ico-close a {
  background-image: url(../images/common/ico_close.png);
  background-size: 25px;
}

.sp-btn li#sp-menu.active a {
  background-image: url(../images/common/spmenu_ico01_on.png);
}

.gnavi {
  width: 100%;
  display: table;
  background: #333333;
  position: relative;
  padding-top: 4px;
}

.gnavi:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
}

@media screen and (max-width: 768px) {
  .gnavi {
    display: block;
    border-top: 1px solid #5e5e5e;
    padding-top: 0;
  }
  .gnavi:before {
    display: none;
  }
}

.gnavi li {
  display: table-cell;
  width: 25%;
  text-align: center;
  border-left: 1px solid #494949;
  position: relative;
}

@media screen and (max-width: 768px) {
  .gnavi li {
    display: block;
    width: 100%;
    text-align: left;
    border-left: none;
  }
  .gnavi li.phone-item {
    border: 1px solid #5e5e5e;
    border-top: none;
    background: white;
    padding: 10px 0 20px;
    text-align: center;
  }
  .gnavi li.phone-item a {
    padding: 0;
    display: block;
    border: none;
    margin: 20px 0;
  }
  .gnavi li.phone-item a:before, .gnavi li.phone-item a:after {
    display: none;
  }
  .gnavi li.menu06 a::before {
    background-image: url(../images/common/spmenu_ico04.png);
    width: 30px;
    margin-right: 4px;
    margin-left: -3px;
  }
}

.gnavi li:first-of-type {
  border-left: none;
}

.gnavi li a {
  position: relative;
  display: block;
  color: #FFF;
  padding: 4px 0;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .gnavi li a {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 990px) {
  .gnavi li a {
    padding: 9.5px 0;
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .gnavi li a {
    padding: 10px 20px;
    border-bottom: 1px solid #5e5e5e;
  }
}

.gnavi li a::before {
  content: "";
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 35px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin-right: 15px;
}

@media screen and (max-width: 990px) {
  .gnavi li a::before {
    width: 23px;
    height: 26px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 768px) {
  .gnavi li a::before {
    margin-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  .gnavi li a::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -3px;
    vertical-align: middle;
    width: 6px;
    height: 6px;
    border-top: 1px solid #FFF;
    border-right: 1px solid #FFF;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@media screen and (max-width: 768px) {
  .gnavi li a.ac-menu::after {
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

@media screen and (max-width: 768px) {
  .gnavi li a.ac-menu.active::after {
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

.gnavi li:nth-of-type(1) a::before {
  background-image: url(../images/common/navi_ico01.png);
}

.gnavi li:nth-of-type(2) a::before {
  background-image: url(../images/common/navi_ico02.png);
}

.gnavi li:nth-of-type(3) a::before {
  background-image: url(../images/common/navi_ico03.png);
  width: 30px;
  top: 2px;
}

@media screen and (max-width: 768px) {
  .gnavi li:nth-of-type(3) a::before {
    width: 25px;
  }
}

.gnavi li:nth-of-type(4) a::before {
  background-image: url(../images/common/navi_ico04.png);
}

.gnavi li:nth-of-type(5) a::before {
  background-image: url(../images/common/viewing_ico.png);
  margin-right: 6px;
  background-size: 15px;
  background-position: center;
}

.gnavi li .sub-menu {
  position: absolute;
  top: 85%;
  right: 0;
  left: -1px;
  background: #333333;
  padding: 18px 0;
  border-top: 1px solid #494949;
  opacity: 0;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.gnavi li .sub-menu li {
  display: block;
  width: 100%;
  text-align: left;
  border-left: none;
}

.gnavi li .sub-menu li a {
  display: block;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 400;
  padding: 2px 10px 2px 35px;
}

@media all and (-ms-high-contrast: none) {
  .gnavi li .sub-menu li a {
    padding: 4px 10px 0px 35px;
  }
}

.gnavi li .sub-menu li a:before {
  width: 8px;
  height: 12px;
  margin-right: 0;
  background: url(../images/common/right_white.png) no-repeat;
  position: absolute;
  left: 18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gnavi li:hover > a {
  background: rgba(255, 255, 255, 0.1);
}

.gnavi li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.gnavi .sub-navi {
  display: none;
}

.gnavi .sub-navi a {
  background: #333333;
  padding-left: 60px;
}

@media screen and (max-width: 768px) {
  .gnavi .sub-navi a {
    background: rgba(255, 255, 255, 0.1);
  }
}

.gnavi .sub-navi a::before {
  display: none;
}

#footer {
  background: #333333;
  color: #cccccc;
  padding: 25px 0 35px;
}

@media screen and (max-width: 768px) {
  #footer {
    padding: 0 0 10px;
  }
  #footer .inner {
    padding: 0;
  }
}

#footer .footer-ttl {
  font-size: 19px;
  font-size: 1.9rem;
  text-align: center;
  margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
  #footer .footer-ttl {
    display: none;
  }
}

#footer .footer-box {
  float: left;
  width: 25%;
  border-right: 1px solid #555555;
  padding: 10px 15px;
}

@media screen and (max-width: 768px) {
  #footer .footer-box {
    display: none;
  }
}

#footer .footer-box:first-of-type {
  border-left: 1px solid #555555;
}

#footer .footer-box .ttl {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 10px;
}

#footer .footer-list {
  font-size: 14px;
  font-size: 1.4rem;
  padding-left: 25px;
}

#footer .footer-list.mb {
  margin-bottom: 25px;
}

#footer .footer-list li {
  margin: 5px 0;
}

#footer .footer-list li a {
  color: #cccccc;
}

#footer .footer-list li a:hover {
  text-decoration: underline;
}

#footer .footer-navi-wrap {
  clear: both;
  border-top: 1px solid #555555;
  border-bottom: 1px solid #555555;
  margin: 40px 0 20px;
}

@media screen and (max-width: 768px) {
  #footer .footer-navi-wrap {
    margin: 0 0 10px;
    border: none;
  }
}

#footer .footer-navi {
  font-size: 0;
  text-align: center;
  padding: 11px 0;
}

@media screen and (max-width: 768px) {
  #footer .footer-navi {
    padding: 0;
  }
}

#footer .footer-navi li {
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 1.4rem;
  padding: 0 20px;
}

#footer .footer-navi li:before {
  content: "";
  display: block;
  height: 13px;
  width: 2px;
  background-color: #555;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  #footer .footer-navi li {
    width: 50%;
    padding: 0;
    border: none;
    border-bottom: 1px solid #555555;
  }
  #footer .footer-navi li:nth-of-type(2n) {
    border-left: 1px solid #555555;
  }
  #footer .footer-navi li:before {
    display: none;
  }
}

#footer .footer-navi li:first-of-type {
  border-left: none;
}

#footer .footer-navi li a {
  color: #cccccc;
}

@media screen and (max-width: 768px) {
  #footer .footer-navi li a {
    display: block;
    padding: 10px 0;
  }
}

#footer .footer-navi li a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  #footer .footer-navi li a:hover {
    text-decoration: none;
    background: rgba(255, 255, 255, 0.1);
  }
}

#footer .copyright {
  text-align: center;
}

#topcontrol {
  width: 44px;
  height: 44px;
  background: url(../images/common/pagetop.png) no-repeat;
  z-index: 1;
}

/* ====================================================
common
==================================================== */
.container {
  padding-top: 108px;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 64px;
  }
}

@media screen and (max-width: 768px) {
  input {
    -moz-appearance: none;
    -webkit-appearance: none;
  }
}

select {
  font-size: 14px;
  font-size: 1.4rem;
  height: 50px;
  border: 1px solid #999999;
  background: #FFF url(../images/common/arrow_under.png) no-repeat right 20px center;
  background-size: 13px auto;
  border-radius: 3px;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 10px 15px;
}

select::-ms-expand {
  display: none;
}

@media screen and (max-width: 768px) {
  select {
    -moz-appearance: none;
  }
}

/* ------ search ------ */
.search {
  position: relative;
}

.s-window {
  height: 37px;
  border: 1px solid #CCC;
  border-radius: 3px;
  padding: 5px 10px;
}

@media (max-width: 768px) {
  .s-window {
    height: 48px;
  }
}

.s-btn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 20px;
  height: 20px;
  background: url(../images/common/search_ico.png) no-repeat 0 0;
  background-size: 100% auto;
  border: none;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .s-btn {
    width: 24px;
    height: 26px;
  }
}

/* ------ text ------ */
.red {
  color: #a70000;
}

.link-b:hover {
  text-decoration: underline;
}

.right-txt {
  text-align: right;
}

.l-arrow::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  border-top: 1px solid #a70000;
  border-right: 1px solid #a70000;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: relative;
  top: -1px;
}

/* ------ btn ------ */
.more-btn {
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .more-btn {
    width: 100%;
    text-align: center;
  }
}

.more-btn a {
  display: inline-block;
  position: relative;
  color: #a70000;
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 5px 19px;
  background-color: #FFF;
}

@media screen and (max-width: 768px) {
  .more-btn a {
    display: block;
    position: relative;
    padding: 9px 19px;
  }
}

.more-btn a:hover {
  background-color: #f6f6f6;
}

.more-btn a::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  border-top: 1px solid #a70000;
  border-right: 1px solid #a70000;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (max-width: 768px) {
  .more-btn a::before {
    position: absolute;
    top: 50%;
    margin-top: -3px;
    left: 10px;
  }
}

.more-btn-fix a {
  padding: 5px 15px;
}

.yellow-bg {
  background-color: #fbf4d8;
}

.gray-bg {
  background-color: #f1f1f1;
}

/* ====================================================
index
==================================================== */
.main-slider-wrap {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .main-slider-wrap {
    margin-bottom: 60px;
  }
}

.main-slider li {
  display: table;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .main-slider li {
    display: inline-block;
    width: 8px;
  }
}

.main-slider li:focus {
  outline: none;
}

.main-slider li .img-col {
  display: table-cell;
  vertical-align: middle;
  width: 60%;
}

@media screen and (max-width: 768px) {
  .main-slider li .img-col {
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .main-slider li .img-col:before {
    content: "";
    display: block;
    padding-top: 55%;
  }
}

.main-slider li .img-col img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .main-slider li .img-col img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.main-slider li .img-col a {
  display: inline-block;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 768px) {
  .main-slider li .img-col a {
    position: absolute;
    top: 0;
  }
}

.main-slider li .img-col a:hover {
  opacity: 0.8;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.main-slider li .text-col {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  background-color: #FFF;
  padding: 0 1.5%;
}

@media screen and (max-width: 990px) {
  .main-slider li .text-col {
    padding: 15px 1.5%;
  }
}

@media screen and (max-width: 768px) {
  .main-slider li .text-col {
    display: block;
    padding: 40px 10px 20px;
  }
}

.main-slider li .type {
  color: #bc0c16;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
}

@media screen and (max-width: 1100px) {
  .main-slider li .type {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 768px) {
  .main-slider li .type {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

.main-slider li .ttl {
  font-size: 32px;
  font-size: 3.2rem;
  margin-bottom: 10px;
}

@media screen and (max-width: 1100px) {
  .main-slider li .ttl {
    font-size: 22px;
    font-size: 2.2rem;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 768px) {
  .main-slider li .ttl {
    margin-bottom: 10px;
  }
}

.main-slider li .intro {
  font-size: 15px;
  font-size: 1.5rem;
  margin-bottom: 5px;
}

@media screen and (max-width: 1100px) {
  .main-slider li .intro {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .main-slider li .intro {
    display: none;
  }
}

.main-slider li .hour {
  font-size: 24px;
  font-size: 2.4rem;
  padding-bottom: 30px;
}

@media screen and (max-width: 1100px) {
  .main-slider li .hour {
    font-size: 16px;
    font-size: 1.6rem;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .main-slider li .hour {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

.main-slider li .more-btn {
  position: absolute;
  bottom: 25px;
  right: 20px;
}

@media screen and (max-width: 1100px) {
  .main-slider li .more-btn {
    position: static;
    float: right;
    padding-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .main-slider li .more-btn {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .main-slider .slick-prev, .main-slider .slick-next {
    margin-top: 0;
    top: 20%;
    width: 22px;
    height: 33px;
    border: none;
    background-repeat: no-repeat;
    background-size: 100% auto;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    z-index: 5000;
  }
  .main-slider .slick-prev {
    background-image: url(../images/index/s_prev.png);
    left: -13px;
  }
  .main-slider .slick-next {
    background-image: url(../images/index/s_next.png);
    right: -13px;
  }
}

.thum-slider-wrap {
  position: relative;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 12px 80px 15px;
}

@media screen and (max-width: 768px) {
  .thum-slider-wrap {
    display: none;
  }
}

.thum-slider-wrap .slick-next {
  right: 35px;
}

.thum-slider-wrap .slick-prev {
  left: 35px;
}

.thum-slider li {
  cursor: pointer;
}

.thum-slider li:focus {
  outline: none;
}

.thum-slider li.slick-current img {
  opacity: 0.5;
}

.thum-slider li .bg {
  display: inline-block;
  background: #000;
  vertical-align: middle;
}

.thum-slider li .bg:hover {
  opacity: .8;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.news-list {
  border-bottom: 1px solid #cccccc;
  padding: 13px 0 0;
}

@media screen and (max-width: 768px) {
  .news-list {
    padding-top: 20px;
  }
}

.news-list .news-list-ttl {
  float: left;
  margin-right: 4px;
}

@media screen and (max-width: 768px) {
  .news-list .news-list-ttl {
    float: none;
    margin-bottom: 0;
    margin-right: 0;
  }
}

.news-list .news-list-cont {
  float: left;
  width: calc(100% - 169px);
}

@media screen and (max-width: 768px) {
  .news-list .news-list-cont {
    float: none;
  }
}

.news-list .news-list-cont .ticker-item {
  margin-bottom: 0;
}

.news-list li {
  margin-bottom: 13px;
}

@media screen and (max-width: 768px) {
  .news-list li {
    margin-bottom: 20px;
  }
}

.news-list li .cat, .news-list li .day, .news-list li .ttl {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .news-list li .cat, .news-list li .day, .news-list li .ttl {
    display: inline;
  }
}

.news-list li .day {
  color: #333;
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .news-list li .day {
    margin-right: 10px;
  }
}

.news-list li .cat {
  width: 120px;
  margin-right: 45px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .news-list li .cat {
    display: block;
    max-width: 120px;
    margin-bottom: 10px;
  }
}

.news-list li .cat.info {
  background: #a70000;
  color: #FFF;
  font-weight: bold;
}

.news-list li .cat.new {
  color: #666666;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .news-list li .cat.new {
    font-size: 16px;
    font-size: 1.6rem;
    text-align: left;
  }
}

.news-list li .ttl {
  width: calc(100% - 280px);
  vertical-align: middle;
}

@media (max-width: 768px) {
  .news-list li .ttl {
    width: 100%;
  }
}

.news-list li a {
  color: #333;
}

.news-list li a:hover {
  text-decoration: underline;
}

.news-list.info {
  border-bottom: none;
}

.news-list.info li {
  color: #a70000;
  margin-bottom: 10px;
}

.news-list.info li a, .news-list.info li .day {
  color: #a70000;
}

.top-ttl {
  margin-bottom: 22px;
  font-size: 30px;
  font-size: 3rem;
}

@media screen and (max-width: 768px) {
  .top-ttl {
    position: relative;
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 1.3;
    margin-bottom: 30px;
  }
}

.top-ttl::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .top-ttl::before {
    position: absolute;
    left: 0;
    top: 50%;
    margin: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.top-ttl.top-ttl-lg {
  margin-bottom: 25px;
  font-size: 34px;
  font-size: 3.4rem;
}

@media screen and (max-width: 768px) {
  .top-ttl.program {
    padding-left: 58px;
  }
}

.top-ttl.program::before {
  background-image: url(../images/index/recommend_ico.png);
  width: 48px;
  height: 63px;
}

@media screen and (max-width: 768px) {
  .top-ttl.tw {
    padding-left: 64px;
  }
}

.top-ttl.tw::before {
  background-image: url(../images/index/twitter_ico.png);
  width: 54px;
  height: 46px;
}

@media screen and (max-width: 768px) {
  .top-ttl.onair {
    padding-left: 65px;
  }
}

.top-ttl.onair::before {
  background-image: url(../images/index/onair_ico.png);
  width: 55px;
  height: 55px;
}

@media screen and (max-width: 768px) {
  .top-ttl.p-search {
    padding-left: 67px;
  }
}

.top-ttl.p-search::before {
  background-image: url(../images/index/program_ico.png);
  width: 57px;
  height: 41px;
}

@media screen and (max-width: 768px) {
  .top-ttl.plan {
    padding-left: 67px;
  }
}

.top-ttl.plan::before {
  background-image: url(../images/index/plan_ico.png);
  width: 57px;
  height: 45px;
}

@media screen and (max-width: 768px) {
  .top-ttl.special {
    padding-left: 66px;
  }
}

.top-ttl.special::before {
  background-image: url(../images/index/special_ico.png);
  width: 56px;
  height: 61px;
}

.top-ttl.schedule:before {
  background-image: url(../images/timetable/schedule_ico.png);
  width: 56px;
  height: 44px;
}

.top-ttl.daily-icon:before {
  background-image: url(../images/index/daily_ico.png);
  width: 56px;
  height: 46px;
}

.top-ttl.noicon:before {
  display: none;
}

.program-box .left-col {
  width: 71.44%;
  float: left;
  background: #333;
}

@media screen and (max-width: 768px) {
  .program-box .left-col {
    width: 100%;
    float: none;
    background: none;
    height: inherit !important;
  }
}

.program-box .right-col {
  width: 27.13%;
  margin-left: 1.43%;
  float: right;
  background: #333;
}

@media screen and (max-width: 768px) {
  .program-box .right-col {
    width: 100%;
    float: none;
    margin: 0;
  }
}

.program-box .box {
  margin-bottom: 12px;
  background: #333;
}

@media screen and (max-width: 768px) {
  .program-box .box {
    margin-bottom: 15px;
    display: table;
    width: 100%;
    height: 90px !important;
  }
}

@media screen and (max-width: 768px) {
  .program-box .box .img {
    position: relative;
    display: table-cell;
    width: 135px;
    overflow: hidden;
    vertical-align: top;
  }
}

.program-box .box .img img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .program-box .box .img img {
    position: absolute;
    display: block;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-width: inherit;
    width: 150px;
  }
}

.program-box .box .ttl {
  color: #FFF;
  line-height: 1.3;
  padding: 13px 9px;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .program-box .box .ttl {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.program-box .box .ttl .big {
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 768px) {
  .program-box .box .ttl .big {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .program-box .box .ttl {
    display: table-cell;
  }
}

.program-box .box:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.tw-frame {
  border: 1px solid #CCC;
  border-radius: 3px;
  margin-top: 27px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .tw-frame {
    margin-bottom: 30px;
  }
}

.mallmaga-btn {
  text-align: center;
}

.mallmaga-btn a {
  border-radius: 3px;
  padding: 11px 0;
  display: block;
  background-color: #a70000;
  color: #FFF;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .mallmaga-btn a {
    position: relative;
    font-size: 19px;
    font-size: 1.9rem;
    padding: 17px 0;
  }
}

.mallmaga-btn a::before {
  content: "";
  width: 44px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin-right: 10px;
  background-image: url(../images/index/mailmaga_ico.png);
}

@media screen and (max-width: 768px) {
  .mallmaga-btn a::before {
    position: absolute;
    top: 50%;
    left: 5%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 35px;
    height: 27px;
  }
}

@media (min-width: 769px) {
  .border-scrollbar {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .border-scrollbar:after {
    content: "";
    height: 2px;
    background: #fff;
    bottom: 3px;
    z-index: 0;
    left: 0;
    position: absolute;
    right: 0;
  }
}

.onair-box {
  position: relative;
  overflow: auto;
  padding: 0 0 15px;
  z-index: 1;
  /*バーの太さ*/
  /*バーの背景色*/
  /*バーの色*/
}

.onair-box::-webkit-scrollbar {
  height: 8px;
}

.onair-box::-webkit-scrollbar-track {
  background: transparent;
}

.onair-box::-webkit-scrollbar-thumb {
  background: #a70000;
}

.onair-list {
  width: 2480px;
}

@media screen and (max-width: 768px) {
  .onair-list {
    width: inherit;
    margin-bottom: 20px;
  }
}

.onair-list li {
  float: left;
  margin-right: 18px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.3;
}

@media screen and (max-width: 768px) {
  .onair-list li {
    font-size: 14px;
    font-size: 1.4rem;
    margin-right: 0;
    float: none;
  }
}

.onair-list li .ttl {
  margin-top: 10px;
  color: #333;
}

.onair-list li img {
  width: 100%;
  max-height: 149px;
}

@media screen and (max-width: 768px) {
  .onair-list li img {
    max-height: 100%;
  }
}

.onair-list li:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.plan-list li {
  width: 32.16%;
  float: left;
  margin: 0 1.76% 20px 0;
}

@media screen and (max-width: 768px) {
  .plan-list li {
    width: 100%;
    margin: 0 0 20px;
  }
}

.plan-list li:nth-of-type(3n) {
  margin-right: 0;
}

.plan-list-slide .slick-prev, .plan-list-slide .slick-next {
  display: none !important;
}

.plan-list-slide .slick-dots {
  bottom: -10px;
  top: auto !important;
}

.plan-list-slide .slick-dots li {
  margin: 0 10px;
}

.plan-list-slide .slick-dots li button {
  padding: 4px;
}

.plan-list-slide .plan-list {
  display: inline-block;
  width: 100%;
}

.plan-list-slide .plan-list li {
  width: calc(94% / 3);
  float: left;
  margin-left: 2%;
  margin-bottom: 20px;
  margin-right: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .plan-list-slide .plan-list li {
    margin-left: 1.9%;
  }
}

.plan-list-slide .plan-list li:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.plan-box {
  background-color: #333;
}

.plan-box .img {
  position: relative;
  width: 100%;
  background-color: #cccccc;
  overflow: hidden;
}

.plan-box .img::before {
  content: "";
  display: block;
  padding-top: 66%;
}

.plan-box .img img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 100%;
}

.plan-box .txt {
  position: relative;
  background: #333333;
  color: #FFF;
  padding: 15px 10px 10px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .plan-box .txt {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.plan-box .ttl {
  font-size: 19px;
  font-size: 1.9rem;
}

.plan-box .cat {
  position: absolute;
  top: -25px;
  left: -10px;
  width: 140px;
  padding: 5px 0;
  text-align: center;
  background-color: #B90C0E;
}

.plan-box .cat::before {
  position: absolute;
  top: -10px;
  left: 0;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10px 10px;
  border-color: transparent transparent #007bff transparent;
}

.plan-box .cat.blue {
  background-color: #00a0eb;
}

.plan-box .cat.blue::before {
  border-color: transparent transparent #005075 transparent;
}

.plan-box .cat.green {
  background-color: #2dc100;
}

.plan-box .cat.green::before {
  border-color: transparent transparent #166000 transparent;
}

.plan-box .cat.pink {
  background-color: #ff4b86;
}

.plan-box .cat.pink::before {
  border-color: transparent transparent #7f2543 transparent;
}

.plan-box .cat.orange {
  background-color: #ff7200;
}

.plan-box .cat.orange::before {
  border-color: transparent transparent #7f3900 transparent;
}

.special-list li {
  width: 23.75%;
  float: left;
  margin-left: 1.6666%;
  background-color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 768px) {
  .special-list li {
    width: 48%;
    margin: 0 4% 15px 0 !important;
  }
  .special-list li:nth-of-type(2n) {
    margin-right: 0 !important;
  }
  .special-list li img {
    width: 100%;
  }
}

.special-list li:first-of-type {
  margin-left: 0;
}

.special-list li .txt {
  padding: 10px;
  color: #333;
}

.special-list li .sub-ttl {
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .special-list li .sub-ttl {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.special-list li .ttl {
  font-size: 19px;
  font-size: 1.9rem;
}

@media screen and (max-width: 768px) {
  .special-list li .ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.special-list li:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.search-wrap .search-ttl {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .search-wrap .search-ttl {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
}

.search-wrap .search-ttl .big {
  font-size: 22px;
  font-size: 2.2rem;
}

@media screen and (max-width: 768px) {
  .search-wrap .search-ttl .big {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

.search-wrap .search {
  width: 44.2%;
  float: left;
}

@media screen and (max-width: 768px) {
  .search-wrap .search {
    width: 100%;
    float: none;
    margin: 0 0 30px;
  }
}

.search-wrap .word {
  width: 54.7%;
  float: right;
}

@media screen and (max-width: 768px) {
  .search-wrap .word {
    width: 100%;
    float: none;
  }
}

.search-wrap .word-ttl {
  float: left;
  position: relative;
  background: #bbbbbb;
  height: 68px;
  color: #FFF;
  text-align: center;
  line-height: 68px;
  font-size: 14px;
  font-size: 1.4rem;
  width: 85px;
  margin-right: 20px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .search-wrap .word-ttl {
    width: 100%;
    height: 30px;
    line-height: 30px;
    float: none;
    margin: 0 0 20px;
  }
}

.search-wrap .word-ttl::after {
  position: absolute;
  top: 0;
  right: -10px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 34px 0 34px 10px;
  border-color: transparent transparent transparent #bbbbbb;
}

@media screen and (max-width: 768px) {
  .search-wrap .word-ttl::after {
    display: none;
  }
}

.search-wrap .word-list {
  overflow: hidden;
}

.search-wrap .s-window {
  width: 100%;
  height: 68px;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 990px) {
  .search-wrap .s-window {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 768px) {
  .search-wrap .s-window {
    height: 50px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.search-wrap .s-btn {
  right: 20px;
  width: 30px;
  height: 30px;
}

.search-wrap .keyword-area {
  padding-top: 15px;
  border-top: 1px solid #cccccc;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .search-wrap .keyword-area {
    padding-top: 25px;
  }
}

.search-wrap .genre-area,
.search-wrap .theme-area {
  padding-top: 15px;
  width: 49.4%;
  float: left;
  border-top: 1px solid #cccccc;
}

@media screen and (max-width: 768px) {
  .search-wrap .genre-area,
  .search-wrap .theme-area {
    width: 100%;
    padding-top: 25px;
  }
}

.search-wrap .genre-area {
  margin-right: 1.2%;
}

@media screen and (max-width: 768px) {
  .search-wrap .genre-area {
    margin: 0;
  }
}

.search-wrap .link-list {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  margin-bottom: 15px;
}

.search-wrap .link-list li {
  display: inline-block;
  font-size: 16px;
  font-size: 1.6rem;
  margin-right: 25px;
}

@media screen and (max-width: 768px) {
  .search-wrap .link-list li {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.search-wrap .link-list li a {
  color: #333;
  font-weight: bold;
}

.search-wrap .link-list li a::after {
  content: "";
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  margin-bottom: 4px;
  width: 8px;
  height: 8px;
  border-top: 2px solid #a70000;
  border-right: 2px solid #a70000;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.search-wrap .link-list li .big {
  font-size: 22px;
  font-size: 2.2rem;
}

@media screen and (max-width: 768px) {
  .search-wrap .link-list li .big {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

.search-wrap .theme-slide {
  padding: 0 25px;
}

@media screen and (max-width: 768px) {
  .search-wrap .theme-slide {
    padding: 0;
  }
}

.search-wrap .theme-slide .ttl {
  font-size: 16px;
  font-size: 1.6rem;
  padding-top: 5px;
  color: #333;
}

.search-wrap .theme-slide li a {
  display: inline-block;
  width: 100%;
}

.search-wrap .theme-slide li a img {
  -webkit-transform-style: initial;
}

.search-wrap .theme-slide li a:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.search-wrap .slick-prev,
.search-wrap .slick-next {
  margin-top: -18px;
  width: 20px;
  height: 20px;
  border-color: #a70000;
}

.word-list li {
  display: inline-block;
  font-size: 14px;
  font-size: 1.4rem;
  margin-bottom: 5px;
  margin-right: 3px;
}

@media screen and (max-width: 768px) {
  .word-list li {
    margin: 0 7px 7px 0;
  }
}

.word-list li a {
  display: block;
  border-radius: 5px;
  background-color: #FFF;
  text-align: center;
  padding: 5px 7px;
}

.word-list li a:hover {
  background-color: #f6f6f6;
}

@media (max-width: 768px) {
  .word-list li a {
    padding: 9px 7px;
  }
}

.genre-list {
  font-size: 0;
}

.genre-list li {
  display: inline-block;
  vertical-align: top;
  width: 23.64%;
  margin-right: 1.81%;
  margin-bottom: 10px;
  border-radius: 3px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 990px) {
  .genre-list li {
    width: 32%;
    margin-right: 2% !important;
  }
  .genre-list li:nth-of-type(3n) {
    margin-right: 0 !important;
  }
}

.genre-list li:nth-of-type(4n) {
  margin-right: 0;
}

.genre-list li a {
  padding: 15px 0;
  display: block;
  background-color: #FFF;
  text-align: center;
}

.genre-list li a:hover {
  background-color: #f6f6f6;
}

select.genre-list {
  width: 100%;
  margin-bottom: 40px;
  font-size: 16px;
  font-size: 1.6rem;
}

.read-more-btn {
  cursor: pointer;
  position: relative;
  text-align: center;
  color: #a70000;
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 30px;
}

.read-more-btn::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  border-top: 1px solid #a70000;
  border-right: 1px solid #a70000;
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.read-more-area {
  display: none;
}

#index {
  background: url(../images/index/main_bg.jpg) no-repeat center top;
  background-size: 100% auto;
}

@media screen and (max-width: 1366px) {
  #index {
    background: url(../images/index/main_bg.jpg) no-repeat center top 80px;
  }
}

@media screen and (max-width: 768px) {
  #index {
    background-image: url(../images/index/main_bg_sp.jpg);
  }
}

#index .info-box {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 7px 20px 20px;
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  #index .info-box {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 0px 5% 20px;
  }
}

#index .program-col {
  width: 64.58%;
  float: left;
  padding-top: 32px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  #index .program-col {
    width: 100%;
    float: none;
    padding: 60px 0 0;
    margin-bottom: 55px;
  }
}

#index .tw-col {
  width: 33.333%;
  float: right;
  padding-top: 26px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  #index .tw-col {
    width: 100%;
    float: none;
    padding: 0 0 15px;
  }
}

#index .onair-wrap {
  padding: 40px 0 30px;
}

@media screen and (max-width: 768px) {
  #index .onair-wrap {
    padding: 40px 0 60px;
  }
  #index .onair-wrap .inner {
    padding: 0;
  }
  #index .onair-wrap .ttl-wrap,
  #index .onair-wrap .more-btn {
    padding: 0 15px;
  }
}

#index .search-wrap {
  padding: 45px 0 32px;
}

#index .plan-wrap {
  padding: 40px 0 50px;
}

#index .special-wrap {
  padding: 30px 0 43px;
}

@media screen and (max-width: 768px) {
  #index .special-wrap {
    padding-top: 50px;
  }
  #index .special-wrap .top-ttl {
    margin-bottom: 45px;
  }
}

#index .ttl-wrap .top-ttl,
#index .ttl-wrap .more-btn {
  display: inline-block;
  vertical-align: middle;
}

#index .ttl-wrap .more-btn {
  margin-bottom: 25px;
  margin-left: 30px;
}

@media screen and (max-width: 768px) {
  #index .ttl-wrap .more-btn {
    display: none;
  }
}

.contact-wrap {
  padding: 60px 0 35px;
}

@media screen and (max-width: 768px) {
  .contact-wrap {
    padding: 45px 0 30px;
  }
}

.contact-wrap .contact-inner {
  padding: 0 7.5%;
}

@media screen and (max-width: 768px) {
  .contact-wrap .contact-inner {
    padding: 0;
  }
}

.contact-wrap .freedial-box {
  border-top: #999999 1px solid;
  border-bottom: #999999 1px solid;
  text-align: center;
  padding: 40px 0 0;
  margin-bottom: 35px;
}

@media screen and (max-width: 768px) {
  .contact-wrap .freedial-box {
    padding-top: 25px;
  }
}

.contact-wrap .freedial-box .sp img {
  width: 88%;
  margin: 0 auto;
}

.contact-wrap .zidaigeki {
  margin-top: 40px;
  border-top: #999999 1px solid;
  padding: 8px 0 0;
}

@media screen and (max-width: 768px) {
  .contact-wrap .zidaigeki {
    padding-top: 20px;
    margin-top: 25px;
  }
}

.contact-wrap .zidaigeki .channel-ttl {
  font-weight: bold;
  font-size: 15px;
  font-size: 1.5rem;
  margin-right: 20px;
  display: inline-block;
  color: #000;
}

@media screen and (max-width: 768px) {
  .contact-wrap .zidaigeki .channel-ttl {
    display: block;
    margin: 0 0 -8px;
  }
}

.contact-wrap .zidaigeki a.sp img {
  max-width: 68%;
}

.contact-wrap .zidaigeki img {
  display: inline-block;
  padding-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .contact-wrap .zidaigeki img {
    padding-bottom: 25px;
  }
}

.contact-wrap .campaign-ttl {
  text-align: center;
  font-size: 22px;
  font-size: 2.2rem;
  margin-bottom: 15px;
  letter-spacing: -2px;
}

@media screen and (max-width: 768px) {
  .contact-wrap .campaign-ttl {
    font-size: 19px;
    font-size: 1.9rem;
    margin-bottom: 18px;
  }
}

.contact-wrap .campaign-list li {
  width: 23.53%;
  float: left;
  margin-left: 1.96%;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .contact-wrap .campaign-list li {
    width: 48%;
    margin: 0 4% 15px 0 !important;
    text-align: center;
  }
  .contact-wrap .campaign-list li:nth-of-type(2n) {
    margin-right: 0 !important;
  }
}

.contact-wrap .campaign-list li:first-of-type {
  margin-left: 0;
}

.contact-wrap .campaign-list2 {
  text-align: center;
  font-size: 0;
  margin-bottom: 35px;
}

.contact-wrap .campaign-list2 li {
  display: inline-block;
  margin: 0 0.98%;
}

@media screen and (max-width: 768px) {
  .contact-wrap .campaign-list2 li {
    display: block;
    float: left;
    width: 48%;
    margin: 0 4% 15px 0 !important;
  }
  .contact-wrap .campaign-list2 li:nth-of-type(2n) {
    margin-right: 0 !important;
  }
}

.contact-wrap .copy-txt {
  color: #999999;
  line-height: 1.2;
}

@media screen and (max-width: 768px) {
  .contact-wrap .copy-txt {
    padding-top: 20px;
    padding-bottom: 50px;
    color: #666666;
  }
}

@media screen and (max-width: 768px) {
  .genre-list-sp {
    border-bottom: 1px solid #ccc;
    margin: 30px 0;
  }
}

@media (max-width: 768px) {
  .width100 img {
    width: 100%;
  }
}

.mb0 {
  margin-bottom: 0 !important;
}

.s-window {
  padding-right: 50px;
  border: 1px solid #999999;
}

.s-window::-webkit-input-placeholder {
  color: #999999;
}

.s-window:-moz-placeholder {
  color: #999999;
}

.s-window::-moz-placeholder {
  color: #999999;
}

.s-window:-ms-input-placeholder {
  color: #999999;
}

.s-window::-ms-clear {
  display: none;
}

.s-window::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

@media screen and (max-width: 1110px) {
  .news-list li .ttl {
    margin-top: 10px;
  }
}

@media screen and (min-width: 0 \0 ) {
  .slick-slide img {
    width: 100%;
  }
}

.flex-ttl {
  display: flex !important;
  align-items: center;
}

.top-ttl.flex-ttl {
  position: relative;
  padding-left: 63px;
  letter-spacing: 1.8px;
}

.top-ttl.flex-ttl:before {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.top-ttl.flex-ttl.flex-ttl-fix {
  padding-left: 67px;
}

@media (max-width: 768px) {
  .top-ttl.flex-ttl.flex-ttl-fix {
    padding-left: 64px;
  }
}

@media screen and (max-width: 768px) {
  .main-slider .slick-next, .main-slider .slick-prev {
    top: calc(50% - 110px);
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
  .onair-list .owl-nav .owl-prev, .onair-list .owl-nav .owl-next {
    top: calc(50% - 40px);
  }
  .theme-slide .owl-nav .owl-prev, .theme-slide .owl-nav .owl-next {
    top: calc(50% - 25px);
  }
  .tw-frame {
    border: none;
    text-align: center;
  }
  .tw-frame iframe {
    border: 1px solid #ccc !important;
    border-radius: 3px !important;
    margin: auto;
  }
}

@media screen and (max-width: 768px) and (min-width: 0 \0 ) {
  .program-box .box .img img {
    position: static;
  }
}

.slick-arrow-clone {
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  cursor: pointer;
}

.slick-next-clone {
  right: -10px;
  background: url("../images/common/control_arrow_r.png") no-repeat;
  background-position: center;
}

.slick-prev-clone {
  left: -10px;
  background: url("../images/common/control_arrow_l.png") no-repeat;
  background-position: center;
}

.slick-deactive {
  display: none !important;
}

.main-slider-inner-wrap:hover .slick-next-clone {
  right: 20px;
}

.main-slider-inner-wrap:hover .slick-prev-clone {
  left: 20px;
}

@media screen and (min-width: 769px) {
  .main-slider-inner-wrap {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .main-slider-inner-wrap:hover .slick-arrow-clone {
    display: inline-block;
    opacity: 1;
    visibility: visible;
  }
}

.main-plan-list {
  position: relative;
}

.main-plan-list .slick-arrow-clone {
  top: calc(50% - 12px);
  transform: translateY(-50%);
}

.main-plan-list .slick-next-clone {
  right: -55px;
}

.main-plan-list .slick-prev-clone {
  left: -30px;
}

.main-plan-list:hover .slick-arrow-clone {
  display: inline-block;
  opacity: 1;
  visibility: visible;
}

.main-plan-list:hover .slick-next-clone {
  right: -40px;
}

.main-plan-list:hover .slick-prev-clone {
  left: -14px;
}

.thum-slider .slick-current img, .thum-slider li:hover img {
  opacity: 0.7;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.page-daily {
  padding-top: 31px;
}

.page-daily .top-ttl {
  margin-bottom: 25px;
}

.txt-center {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .show-cal {
    display: none;
  }
  .pagination-item {
    border: none;
  }
}

@media (min-width: 1025px) {
  .calendars-item .pagination-item {
    display: none;
  }
}

.calendars-item .btn-grey {
  display: none;
}

@media screen and (max-width: 1024px) {
  .calendars-item {
    max-width: 375px;
    margin: auto;
  }
  .calendars-item .pagination-item {
    border: none;
  }
  .calendars-item .pagination-item li {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .calendars-item {
    max-width: 100%;
  }
  .calendars-item .btn-grey {
    display: inline-flex;
  }
}

.show-calendars {
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .show-calendars {
    margin-bottom: 20px;
  }
}

.show-calendars .item {
  float: left;
  width: 30.4%;
  margin-right: 4.4%;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.show-calendars .item:nth-child(3n) {
  margin-right: 0;
}

@media screen and (max-width: 1024px) {
  .show-calendars .item {
    display: none;
    width: 100%;
    margin-right: 0;
  }
  .show-calendars .item.current {
    display: block;
  }
}

.table-calendars {
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem;
}

.table-calendars tr th, .table-calendars tr td {
  text-align: center;
  border: 1px solid #aaaaaa;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .table-calendars tr th, .table-calendars tr td {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.table-calendars tr th:nth-child(6), .table-calendars tr td:nth-child(6) {
  color: #7db4ff;
}

.table-calendars tr th:nth-child(7), .table-calendars tr td:nth-child(7) {
  color: #e6410e;
}

.table-calendars tr:nth-child(1) th {
  padding: 7px 12px;
}

.table-calendars tr th {
  background: #333333;
  color: #cccccc;
  padding: 5px 12px;
}

@media screen and (max-width: 350px) {
  .table-calendars tr th {
    padding: 5px;
  }
}

.table-calendars tr th span.num {
  font-size: 26px;
  font-size: 2.6rem;
}

@media screen and (max-width: 768px) {
  .table-calendars tr th span.num {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.table-calendars tr th span.month {
  font-size: 22px;
  font-size: 2.2rem;
}

@media screen and (max-width: 768px) {
  .table-calendars tr th span.month {
    font-size: 20px;
    font-size: 2rem;
  }
}

.table-calendars tr td {
  padding: 8px 12px;
}

.table-calendars tr td.active, .table-calendars tr td:hover {
  background: #a60000;
  color: white;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 350px) {
  .table-calendars tr td {
    padding: 5px;
  }
}

.table-calendars tr td.gray-bg.active, .table-calendars tr td.gray-bg:hover {
  background: #f1f1f1;
  color: #333;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.table-calendars tr td.gray-bg a:hover {
  background: #f1f1f1 !important;
}

.table-calendars.table-calendars-nohover tr th.active, .table-calendars.table-calendars-nohover tr th:hover, .table-calendars.table-calendars-nohover tr td.active, .table-calendars.table-calendars-nohover tr td:hover {
  background: #fff;
  color: #333;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.table-calendars.table-calendars-nohover tr th.active:nth-child(6), .table-calendars.table-calendars-nohover tr th:hover:nth-child(6), .table-calendars.table-calendars-nohover tr td.active:nth-child(6), .table-calendars.table-calendars-nohover tr td:hover:nth-child(6) {
  color: #7db4ff;
}

.table-calendars.table-calendars-nohover tr th.active:nth-child(7), .table-calendars.table-calendars-nohover tr th:hover:nth-child(7), .table-calendars.table-calendars-nohover tr td.active:nth-child(7), .table-calendars.table-calendars-nohover tr td:hover:nth-child(7) {
  color: #e6410e;
}

.pagination-item {
  border-top: 1px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
}

.pagination-item.border-top-none {
  border-top: none;
}

.pagination-item.border-bottom-none {
  border-bottom: none;
}

.pagination-item.border-bottom-none.sp {
  margin-top: 10px;
}

.pagination-item .item {
  width: 33.333%;
  float: left;
  text-align: center;
  font-size: 28px;
  font-size: 2.8rem;
  min-height: 70px;
  line-height: 70px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .pagination-item .item {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 1.4;
  }
  .pagination-item .item:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.pagination-item .item.prev-item {
  text-align: left;
}

.pagination-item .item.prev-item a {
  padding-left: 30px;
}

@media screen and (max-width: 768px) {
  .pagination-item .item.prev-item a {
    padding-left: 23px;
    left: 0;
  }
}

@media screen and (max-width: 360px) {
  .pagination-item .item.prev-item a {
    padding-right: 23px;
  }
}

.pagination-item .item.prev-item a:before {
  left: 0;
  background: url("../images/common/pagination-prev.png") no-repeat;
}

@media screen and (max-width: 768px) {
  .pagination-item .item.prev-item a:before {
    background-position: left;
  }
}

.pagination-item .item.prev-item a span:hover {
  text-decoration: underline;
}

.pagination-item .item.next-item {
  text-align: right;
}

.pagination-item .item.next-item a {
  padding-right: 30px;
}

@media screen and (max-width: 768px) {
  .pagination-item .item.next-item a {
    padding-right: 23px;
    right: 0;
  }
}

@media screen and (max-width: 360px) {
  .pagination-item .item.next-item a {
    padding-left: 23px;
  }
}

.pagination-item .item.next-item a:before {
  right: 0;
  background: url("../images/common/pagination-next.png") no-repeat;
}

@media screen and (max-width: 768px) {
  .pagination-item .item.next-item a:before {
    background-position: right;
  }
}

.pagination-item .item.next-item a span:hover {
  text-decoration: underline;
}

.pagination-item .item a {
  color: #a70000;
  font-size: 16px;
  font-size: 1.6rem;
  display: block;
}

@media screen and (max-width: 768px) {
  .pagination-item .item a {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.4;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.pagination-item .item a:before {
  content: "";
  width: 23px;
  height: 40px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .pagination-item .item a:before {
    width: 17px;
    height: 30px;
  }
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  color: #a70000;
  line-height: 1;
  padding-left: 8px;
  padding-right: 13px;
  height: 36px;
  border-radius: 4px;
  position: relative;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.btn:before, .btn:after {
  display: inline-block;
  content: "";
}

.btn:before {
  margin-right: 10px;
}

.btn.btn-block {
  width: 100%;
}

.btn.btn-grey {
  background: #333333;
  color: white;
  border: none;
  border-radius: 0;
}

@media screen and (max-width: 768px) {
  .btn.btn-grey {
    font-size: 17px;
    font-size: 1.7rem;
    padding: 19px 0;
  }
}

.btn.btn-grey:before {
  position: absolute;
  left: 15px;
  width: 22px;
  height: 22px;
  background: url("../images/common/calendar_icon.png") no-repeat;
}

.btn.btn-grey:after {
  position: absolute;
  right: 20px;
  top: 50%;
  width: 12px;
  height: 8px;
  background: url("../images/common/dow_icon.png") no-repeat;
  transform: translateY(-50%);
}

.btn.btn-grey.active:after {
  background: url("../images/common/top_icon.png") no-repeat;
}

.btn.btn-blue {
  color: #00a9e9;
  border: 1px solid #00a9e9;
  font-weight: bold;
  padding: 9px 45px 9px 20px;
  border-radius: 50px;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 768px) {
  .btn.btn-blue {
    font-size: 16px;
    font-size: 1.6rem;
    width: 100%;
    padding: 9px 30px 9px 0px;
  }
}

.btn.btn-blue:after {
  right: 30px;
  width: 12px;
  height: 11px;
  background: url(../images/common/btn_blue_dow.png) no-repeat;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .btn.btn-blue:after {
    right: 13px;
  }
}

.btn.btn-pdf:before {
  left: 5px;
  width: 32px;
  height: 32px;
  background: url("../images/common/pdf_icon.png") no-repeat;
}

@media screen and (max-width: 768px) {
  .btn.btn-pdf:before {
    left: 10px;
    width: 27px;
    height: 28px;
    background-size: 100%;
  }
}

.btn.btn-guide:before {
  left: 10px;
  width: 21px;
  height: 20px;
  background: url("../images/common/guide_icon.png") no-repeat;
}

.btn.btn-print:before {
  left: 10px;
  width: 24px;
  height: 21px;
  background: url("../images/timetable/print-icon.png") no-repeat;
}

.btn.btn-calendar:before {
  left: 10px;
  width: 22px;
  height: 19px;
  background: url("../images/timetable/calendar-icon.png") no-repeat;
}

.btn:hover {
  opacity: 0.7;
}

.filtered-item-file .item {
  float: left;
  margin-right: 10px;
}

.filtered-item-file .item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .filtered-item-file .item {
    width: 48%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .filtered-item-file .item:last-child {
    margin-bottom: 0;
  }
  .filtered-item-file .item:nth-child(2n) {
    float: right;
  }
  .filtered-item-file .item .btn {
    display: flex;
  }
  .filtered-item-file .item.item-lg-sp {
    width: 100%;
  }
}

.filtered-item-links {
  font-size: 16px;
  font-size: 1.6rem;
}

.filtered-item-links .item {
  float: left;
  margin-right: 15px;
}

.filtered-item-links .item a {
  display: block;
  color: #a70000;
  position: relative;
  padding-left: 20px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 768px) {
  .filtered-item-links .item a {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.filtered-item-links .item a:before {
  content: "";
  width: 20px;
  height: 20px;
  background: url("../images/common/links_icon.png") no-repeat;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.filtered-item-links .item a:hover {
  opacity: 0.7;
  text-decoration: underline;
}

.filtered-item-links .item:last-child {
  margin-right: 0;
}

.filtered-item {
  padding: 16px 0;
}

.filtered-item .filtered-item-file {
  float: left;
}

@media screen and (max-width: 768px) {
  .filtered-item .filtered-item-file {
    width: 100%;
  }
}

.filtered-item .filtered-item-links {
  float: right;
  padding-top: 7px;
}

@media screen and (max-width: 768px) {
  .filtered-item .filtered-item-links {
    width: 100%;
    padding-top: 16px;
  }
  .filtered-item .filtered-item-links.sp {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.filtered-item.filtered-item-fix {
  padding: 20px 0;
}

.table-category {
  width: 100%;
}

@media screen and (max-width: 375px) and (min-width: 0 \0 ) {
  .table-category {
    width: 35px;
  }
}

.table-category tr th, .table-category tr td {
  border: 1px solid #aaaaaa;
  vertical-align: top;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .table-category tr th, .table-category tr td {
    padding: 15px 12px;
  }
}

.table-category tr th {
  background: #fbf4d8;
  font-size: 20px;
  font-size: 2rem;
  width: 90px;
}

@media screen and (max-width: 768px) {
  .table-category tr th {
    width: 45px;
    padding: 20px 0px;
    font-size: 15px;
    font-size: 1.5rem;
    text-align: center;
  }
}

.table-category + p {
  padding: 12px 0;
}

@media screen and (max-width: 768px) {
  .table-category + p {
    padding: 15px 0;
  }
}

.category-item {
  overflow: hidden;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .category-item {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.category-item .img {
  float: left;
  width: 26.5%;
}

@media screen and (max-width: 768px) {
  .category-item .img {
    width: 46%;
  }
}

.category-item .txt {
  float: left;
  width: 73.5%;
  padding-left: 20px;
}

@media screen and (max-width: 768px) {
  .category-item .txt {
    width: 54%;
    padding-left: 10px;
  }
}

.category-item .txt .ttl {
  display: block;
  color: #a70000;
  background: #fbf4d8;
  padding: 5px 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .category-item .txt .ttl {
    font-size: 12px;
    font-size: 1.2rem;
    padding: 5px 8px;
  }
}

.category-item .txt .ttl a:hover {
  text-decoration: underline;
}

.category-item .txt .heading {
  font-size: 24px;
  font-size: 2.4rem;
  color: #a70000;
  margin-bottom: 12px;
}

@media screen and (max-width: 768px) {
  .category-item .txt .heading {
    font-size: 15px;
    font-size: 1.5rem;
    margin-bottom: 0;
  }
}

.category-item .txt .cast {
  display: inline-block;
  width: 100%;
}

.category-item .txt .cast dt {
  display: inline-block;
  vertical-align: top;
  margin-right: 2px;
}

.category-item .txt .cast dd {
  display: inline-block;
}

.category-item .txt .cast dd a {
  color: #333;
}

.category-item .txt .cast dd a:hover {
  text-decoration: underline;
}

.daily-note {
  margin: 10px 0;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 768px) {
  .daily-note {
    margin-top: 30px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.daily-note.note-small {
  margin: 0 0 30px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .daily-note.note-small {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.daily-note.note-small span {
  color: #a70000;
}

.daily-note a:hover {
  text-decoration: underline;
}

.mt-sp {
  margin-top: 40px;
}

.page-daily .table-calendars tr th {
  background: #333333;
  color: #cccccc;
  padding: 5px 12px;
}

@media screen and (max-width: 350px) {
  .page-daily .table-calendars tr th {
    padding: 5px;
  }
}

.page-daily .table-calendars tr td {
  padding: 0;
}

.page-daily .table-calendars tr td a {
  color: #333;
  display: block;
  padding: 8px 12px;
}

.page-daily .table-calendars tr td a:hover {
  background: #a60000;
  color: white;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.page-daily .table-calendars tr td.active a {
  color: white;
}

@media screen and (max-width: 350px) {
  .page-daily .table-calendars tr td a {
    padding: 5px;
  }
}

.page-daily .heading a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .page-daily .heading a:hover {
    text-decoration: none;
  }
}

#timetable-page {
  padding-top: 136px;
}

@media screen and (max-width: 768px) {
  #timetable-page {
    padding-top: 96px;
  }
  #timetable-page .campaign-list li, #timetable-page .campaign-list2 li {
    margin-bottom: 4% !important;
  }
  #timetable-page .campaign-list li img, #timetable-page .campaign-list2 li img {
    width: 100%;
  }
  #timetable-page .campaign-list2 {
    margin-bottom: 0;
  }
  #timetable-page .copy-txt {
    padding: 0;
  }
}

.notice {
  color: #333333;
  margin: 10px 0 20px;
  font-size: 18px;
  font-size: 1.8rem;
}

.notice.notice-sm {
  margin: 0 0 25px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media (max-width: 768px) {
  .notice.notice-sm {
    margin-bottom: 30px;
  }
}

.notice a {
  color: #a70000;
}

.notice a:hover {
  text-decoration: underline;
}

.white-bg {
  background-color: #fff;
}

.table-schedule {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-schedule + .pagination-item {
  border-top: none;
}

.table-schedule table {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .table-schedule table {
    width: 767px;
  }
}

@media print {
  .table-schedule table {
    width: 100%;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .table-schedule table tr:empty {
    min-height: 1px;
    max-height: 1px;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .table-schedule table tr:empty {
    min-height: 1px;
    max-height: 1px;
  }
}

.table-schedule table tbody th,
.table-schedule table tbody tr:first-child td:first-child,
.table-schedule table tbody tr:last-child td:first-child {
  width: auto;
  vertical-align: top;
  background-color: #333333;
  color: #cccccc;
  text-align: center;
  font-weight: 500;
  padding: 7px;
  border: 1px solid #aaaaaa;
  font-size: 16px;
  font-size: 1.6rem;
}

.table-schedule table tbody th a,
.table-schedule table tbody tr:first-child td:first-child a,
.table-schedule table tbody tr:last-child td:first-child a {
  color: #cccccc;
}

.table-schedule table tbody th a:hover,
.table-schedule table tbody tr:first-child td:first-child a:hover,
.table-schedule table tbody tr:last-child td:first-child a:hover {
  text-decoration: underline;
}

.table-schedule table tbody th.sat,
.table-schedule table tbody tr:first-child td:first-child.sat,
.table-schedule table tbody tr:last-child td:first-child.sat {
  color: #7db4ff;
}

.table-schedule table tbody th.sat a,
.table-schedule table tbody tr:first-child td:first-child.sat a,
.table-schedule table tbody tr:last-child td:first-child.sat a {
  color: #7db4ff;
}

.table-schedule table tbody th.sun,
.table-schedule table tbody tr:first-child td:first-child.sun,
.table-schedule table tbody tr:last-child td:first-child.sun {
  color: #ff6e41;
}

.table-schedule table tbody th.sun a,
.table-schedule table tbody tr:first-child td:first-child.sun a,
.table-schedule table tbody tr:last-child td:first-child.sun a {
  color: #ff6e41;
}

.table-schedule table tbody th, .table-schedule table tbody td {
  border: 1px solid #aaaaaa;
  padding: 10px 5px;
  vertical-align: top;
}

.table-schedule table tbody td {
  width: 165px;
  font-weight: 500;
  line-height: 1.3;
  color: #1b4789;
  font-size: 16px;
  font-size: 1.6rem;
}

.table-schedule table tbody td a {
  display: block;
  font-weight: 500;
  line-height: 1.3;
  font-size: 16px;
  font-size: 1.6rem;
  color: #333333;
  word-break: break-all;
}

.table-schedule table tbody td a:hover {
  text-decoration: underline;
  color: #a70000;
}

.table-schedule table tbody td.bgGray {
  padding: 20px;
  background-color: #f1f1f1;
}

.table-schedule table tbody td.time {
  background-color: #fbf4d8;
  color: #333333;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.2;
  width: 40px;
}

.table-schedule table tbody td.time a {
  display: block;
  color: #333333;
  font-size: 20px;
  font-size: 2rem;
}

@media print {
  .pagination-item,
  .filtered-item,
  .contact-wrap,
  #header,
  #footer {
    display: none;
  }
}

.header-top {
  position: relative;
}

.show-view {
  position: absolute;
  top: 100%;
  right: 0;
  max-width: 480px;
  background: white;
  z-index: 1;
  border: 2px solid #a70000;
  font-size: 15px;
  font-size: 1.5rem;
  text-align: left;
  display: none;
}

@media screen and (max-width: 768px) {
  .show-view {
    border: none;
    width: 100%;
    max-width: 100%;
    top: calc(100% + 2px);
  }
}

.show-view .item {
  border-bottom: 1px solid #ccc;
  position: relative;
  width: 100%;
  padding: 20px 30px 20px 20px;
}

.show-view .item:after {
  content: "";
  width: 8px;
  height: 14px;
  position: absolute;
  right: 11px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url("../images/common/right.png") no-repeat;
}

.show-view .item.logo-item {
  padding: 26px 30px 26px 20px;
}

.show-view .item.logo-item img {
  float: left;
  width: 260px;
}

.show-view .item.logo-item span {
  float: left;
  color: #333333;
  padding: 13px 0 0 10px;
  line-height: 1;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .show-view .item.logo-item span {
    display: block;
    width: 100%;
    padding: 13px 0 0 0;
  }
}

@media screen and (max-width: 768px) {
  .show-view .item.logo-item {
    padding: 15px 30px 15px 20px;
  }
}

.show-view .item.logo-list {
  padding: 0;
}

.show-view .item.logo-list:after {
  display: none;
}

.show-view .item.logo-list ul li {
  float: left;
  width: 33.333%;
  border: 1px solid #ccc;
  border-left: none;
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .show-view .item.logo-list ul li {
    width: 100%;
  }
  .show-view .item.logo-list ul li a {
    position: relative;
    display: block;
  }
  .show-view .item.logo-list ul li a:after {
    content: "";
    width: 8px;
    height: 14px;
    position: absolute;
    right: 11px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url("../images/common/right.png") no-repeat;
  }
}

.show-view .item.logo-list ul li:nth-child(1), .show-view .item.logo-list ul li:nth-child(2) {
  width: 50%;
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .show-view .item.logo-list ul li:nth-child(1) img, .show-view .item.logo-list ul li:nth-child(2) img {
    max-width: 100%;
  }
  .show-view .item.logo-list ul li:nth-child(1) a:after, .show-view .item.logo-list ul li:nth-child(2) a:after {
    background: url("../images/common/right_white.png") no-repeat;
  }
}

.show-view .item.logo-list ul li:nth-child(2), .show-view .item.logo-list ul li:last-child {
  border-right: none;
}

.show-view .item.logo-list ul li img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .show-view .item.logo-list ul li img {
    max-width: 190px;
  }
}

.show-view .item.tel-view {
  background: #f1f1f1;
  padding: 18px 20px 13px 20px;
}

@media screen and (max-width: 768px) {
  .show-view .item.tel-view {
    padding: 16px 20px 19px 20px;
  }
}

.show-view .item.tel-view:after {
  display: none;
}

@media screen and (max-width: 768px) {
  .show-view .item.tel-view img {
    max-width: 150px;
  }
}

.show-view .item.tel-view span {
  font-size: 14px;
  font-size: 1.4rem;
  padding-left: 10px;
}

@media screen and (max-width: 768px) {
  .show-view .item.tel-view span {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.show-view .item.tel-view a {
  color: #333333;
}

.show-view .item.tel-view li {
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .show-view .item.tel-view li {
    margin-bottom: 12px;
  }
}

.show-view .item.tel-view li:last-child {
  margin-bottom: 0;
}

.show-view .item.tel-view li:last-child a {
  position: relative;
  padding-right: 20px;
}

.show-view .item.tel-view li:last-child a:after {
  content: "";
  width: 8px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url("../images/common/right.png") no-repeat;
}

.show-view .item a.rollover {
  letter-spacing: -1.5px;
}

.heading-page {
  padding: 50px 0 60px;
  background: url("../images/shicho/bg_heading_page.png") repeat-x;
}

@media screen and (max-width: 768px) {
  .heading-page {
    background: url("../images/shicho/bg_heading_page_sp.png") repeat-x;
    background-size: 100% 100%;
  }
}

.list-img-shicho {
  margin: 55px 0 15px;
}

.list-img-shicho .item {
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.list-img-shicho .item:hover {
  opacity: 0.7;
}

.list-img-shicho .item img {
  width: 100%;
}

.list-img-shicho.col-4 .item {
  width: 25%;
}

@media screen and (max-width: 768px) {
  .list-img-shicho.col-4 .item {
    width: 50%;
  }
}

.fint-item .item {
  float: left;
  border: 2px solid #c1a433;
  border-right: none;
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.fint-item .item:nth-child(4n) {
  border-right: 2px solid #c1a433;
}

.fint-item .item:hover {
  opacity: 0.7;
}

.fint-item.col-4 .item {
  width: 25%;
}

@media screen and (max-width: 768px) {
  .fint-item.col-4 .item {
    width: 50%;
    border-bottom: none;
    background: white;
  }
  .fint-item.col-4 .item:nth-child(2n) {
    border-right: 2px solid #c1a433;
  }
}

@media screen and (max-width: 768px) {
  .fint-item {
    border-bottom: 2px solid #c1a433;
  }
}

.channels {
  background: #fbf4d8;
  position: relative;
}

.channels img {
  margin-top: -15px;
}

@media screen and (max-width: 768px) {
  .channels img.sp {
    display: inline-block !important;
    margin: -18px 0 20px;
  }
}

.channels:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 100%;
  right: 0;
  left: 0;
  margin: auto;
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
  border-top: solid 30px #fbf4d8;
}

.scroll-page {
  padding: 60px 0 0px;
}

.ttl-parent {
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 768px) {
  .ttl-parent {
    text-align: left;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.link-parent a {
  font-size: 16px;
  font-size: 1.6rem;
  position: relative;
  display: inline-block;
  padding-left: 17px;
}

@media screen and (max-width: 768px) {
  .link-parent a {
    font-size: 14px;
    font-size: 1.4rem;
    text-align: left;
    padding-left: 20px;
  }
}

.link-parent a:before {
  content: "";
  width: 9px;
  height: 13px;
  background: url("../images/common/right.png") no-repeat;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.scroll-link-page {
  margin: 25px -10px;
}

@media screen and (max-width: 768px) {
  .scroll-link-page {
    margin: 25px -8px;
  }
}

.scroll-link-page .item {
  float: left;
  padding: 0 10px;
}

.scroll-link-page .item a {
  display: block;
}

.scroll-link-page .item a img {
  width: 100%;
}

.scroll-link-page.col-5 .item {
  width: 20%;
}

@media screen and (max-width: 768px) {
  .scroll-link-page.col-5 .item {
    width: 50%;
    margin-bottom: 15px;
    padding: 0 8px;
  }
  .scroll-link-page.col-5 .item:nth-child(1) {
    width: 100%;
  }
}

.show-item {
  max-width: 980px;
  margin: auto;
}

.show-item .txt {
  max-width: 820px;
  padding: 50px 0 75px;
  margin: auto;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .show-item .txt {
    padding: 40px 20px 50px;
  }
}

.show-item .txt img {
  margin-bottom: 15px;
}

.show-item .btn-blue {
  margin: 40px 0 20px;
}

.show-item strong {
  display: block;
  font-size: 18px;
  font-size: 1.8rem;
  padding: 27px 0 12px;
}

@media screen and (max-width: 768px) {
  .show-item strong {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.heading-item {
  margin-top: 95px;
}

@media screen and (max-width: 768px) {
  .heading-item {
    margin-top: 75px;
  }
  .heading-item img {
    width: 100%;
    max-width: 320px;
    margin: auto;
  }
}

.heading-item#ttl02, .heading-item#ttl05 {
  margin: 115px 0 45px;
}

@media screen and (max-width: 768px) {
  .heading-item#ttl02, .heading-item#ttl05 {
    margin: 80px 0 35px;
  }
}

.heading-item#ttl04 {
  margin: 120px 0 45px;
}

@media screen and (max-width: 768px) {
  .heading-item#ttl04 {
    margin: 80px 0 35px;
  }
}

.heading-item#ttl03 {
  margin: 120px 0 45px;
}

@media screen and (max-width: 768px) {
  .heading-item#ttl03 {
    margin: 80px 0 35px;
  }
}

@media screen and (max-width: 768px) {
  .heading-item#ttl02 img {
    max-width: 227px;
  }
}

.heading-item#ttl05 {
  padding-left: 50px;
}

@media screen and (max-width: 768px) {
  .heading-item#ttl05 {
    padding-left: 0px;
    margin-top: 65px;
  }
}

.heading-txt {
  background: #00a9e9;
  color: white;
  font-size: 24px;
  font-size: 2.4rem;
  position: relative;
  font-weight: bold;
  padding: 8px 30px;
}

.heading-txt span {
  font-style: oblique;
}

@media screen and (max-width: 768px) {
  .heading-txt {
    font-size: 21px;
    font-size: 2.1rem;
  }
}

.heading-txt:before {
  content: "";
  width: 6px;
  background: white;
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .heading-txt:before {
    width: 5px;
  }
}

.show-links {
  margin: 50px -10px 80px;
  display: flex;
}

@media screen and (max-width: 768px) {
  .show-links {
    display: block;
    margin: 40px -5px 50px;
  }
}

.show-links .item {
  float: left;
  width: 27%;
  padding: 0 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .show-links .item {
    padding: 0 5px;
    width: 50%;
    display: block;
    margin-bottom: 10px;
  }
  .show-links .item img {
    width: 100%;
  }
}

.show-links .item:nth-child(4n) {
  width: 19%;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .show-links .item:nth-child(4n) {
    width: 50%;
  }
}

.regiter-new {
  border: 3px solid #00a9e9;
  padding: 30px;
  margin-bottom: 80px;
}

@media screen and (max-width: 768px) {
  .regiter-new {
    padding: 15px;
    margin-bottom: 60px;
  }
}

.regiter-new li {
  float: left;
  width: 45%;
  position: relative;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 768px) {
  .regiter-new li {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-size: 1.6rem;
  }
  .regiter-new li img {
    margin: auto;
  }
}

.regiter-new li:nth-child(2n) {
  float: right;
}

.regiter-new li:nth-child(2n):before {
  content: "";
  width: 22%;
  height: 107px;
  background: url(../images/common/right_blue.png) no-repeat center;
  position: absolute;
  right: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.regiter-new li .btn {
  font-size: 18px;
  font-size: 1.8rem;
  background: #00a9e9;
  color: white;
  border-radius: 0;
  border: none;
  padding: 8px 13px;
  margin-top: 15px;
  max-width: 400px;
  font-weight: bold;
  float: left;
}

@media screen and (max-width: 768px) {
  .regiter-new li .btn {
    max-width: 200px;
    font-size: 16px;
    font-size: 1.6rem;
    padding: 6px 13px;
    margin-bottom: 20px;
    float: none;
  }
  .regiter-new li .btn + a img {
    margin-bottom: 15px;
  }
}

.regiter-new li img {
  display: block;
}

@media screen and (max-width: 768px) {
  .regiter-new li span {
    display: block;
  }
}

.regiter-new li.pc img {
  margin-bottom: 15px;
}

.regiter-new li.pc span {
  padding-left: 12px;
}

.tab-menu {
  border-bottom: 3px solid #00a9e9;
  margin-top: 50px;
}

@media screen and (max-width: 768px) {
  .tab-menu {
    border-bottom: none;
    margin-bottom: 25px;
  }
}

.tab-menu .item {
  float: left;
  background: #87d2f4;
  color: white;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: bold;
  cursor: pointer;
  padding: 7px;
}

.tab-menu .item.active {
  background: #00a9e9;
}

.tab-menu .item:nth-child(1) {
  font-style: oblique;
}

.tab-menu.col-3 .item {
  width: 32.4%;
  margin-right: 1.4%;
}

.tab-menu.col-3 .item:nth-child(3n) {
  margin-right: 0;
  float: right;
}

@media screen and (max-width: 768px) {
  .tab-menu.col-3 .item {
    width: 100%;
    margin-right: 0;
    font-size: 19px;
    font-size: 1.9rem;
    position: relative;
  }
  .tab-menu.col-3 .item.active:after {
    content: "";
    width: 13px;
    height: 8px;
    background: url("../images/common/dow_icon.png") no-repeat;
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.tab-content {
  display: none;
  font-size: 18px;
  font-size: 1.8rem;
  border: 1px solid #00a9e9;
  border-top: none;
  padding: 25px 40px 40px;
}

@media screen and (max-width: 768px) {
  .tab-content {
    border: none;
    padding: 0;
  }
}

.tab-content .mt50 {
  margin-top: 50px;
}

.tab-content .tv .list-item .item {
  width: 50%;
}

.tab-content .tv .list-item .item.tel-item {
  width: 47.55%;
}

.tab-content .tv .list-item .item.tel-item .show {
  padding: 24px 20px;
}

.tab-content .tv .list-item .item.tel-item .show a img {
  float: left;
}

.tab-content.active {
  display: block;
}

.list-item {
  margin-top: 45px;
}

.list-item .item {
  float: left;
  width: 46%;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .list-item .item {
    width: 100%;
    margin-bottom: 15px;
  }
}

.list-item .item:nth-child(2n) {
  float: right;
}

.list-item .item.tel-item {
  width: 51.55%;
}

@media screen and (max-width: 768px) {
  .list-item .item.tel-item {
    width: 100%;
  }
}

.list-item .item.tel-item .show {
  padding: 20px;
  line-height: 1;
}

.list-item .item.tel-item .show .ttl {
  font-weight: bold;
  display: block;
  font-size: 21px;
  font-size: 2.1rem;
}

@media screen and (max-width: 768px) {
  .list-item .item.tel-item .show .ttl {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.tv .list-item {
  margin-top: 45px;
}

.tv .list-item .item.tel-item .show {
  border: 3px solid #00a2e6;
}

.tv .list-item .item.tel-item .show .ttl {
  color: #00a2e6;
  margin-bottom: 15px;
}

.tv .list-item .item.tel-item .show img {
  margin-bottom: 15px;
}

.tv .list-item .item.tel-item .show .txt-item {
  float: left;
}

@media screen and (max-width: 990px) {
  .tv .list-item .item.tel-item .show .txt-item {
    width: 100%;
  }
}

.tv .list-item .item.tel-item .show .oder-list {
  float: right;
  background: #00a2e6;
  color: white;
  width: 70px;
  height: 70px;
  padding: 8px 2px 0;
  margin-top: -2px;
  font-size: 13px;
  line-height: 1.4;
  font-weight: bold;
}

@media screen and (max-width: 990px) {
  .tv .list-item .item.tel-item .show .oder-list {
    width: 100%;
    margin-bottom: 10px;
    height: auto;
    font-size: 16px;
    font-size: 1.6rem;
    padding: 3px 10px;
  }
}

@media screen and (max-width: 768px) {
  .tv .list-item .item.tel-item .show .oder-list {
    font-size: 16px;
    font-size: 1.6rem;
    background: none;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  .tv .list-item .item.tel-item .show .oder-list span {
    background: #00a2e6;
    padding: 3px 15px;
  }
  .tv .list-item .item.tel-item .show .oder-list img {
    margin-bottom: 20px;
  }
}

.tv .list-item .item.tel-item .show .oder-list + p {
  float: left;
  width: 100%;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
}

.tv .list-item .item.tel-item .show .tv-reception {
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 768px) {
  .tv .list-item .item.tel-item .show .tv-reception {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.j-com {
  margin-top: 45px;
}

.j-com .list-item {
  margin-top: 30px;
}

.j-com .list-item .item.tel-item .show {
  border: 3px solid #e83316;
}

.j-com .list-item .item.tel-item .show .ttl {
  color: #e83316;
  margin-bottom: 20px;
}

.j-com .list-item .item.tel-item .show img {
  margin-bottom: 23px;
}

.j-com .list-item .item.tel-item .show p {
  margin-bottom: 13px;
}

.j-com .list-item .item.tel-item .show p:last-child {
  margin-bottom: 0;
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 768px) {
  .j-com .list-item .item.tel-item .show p:last-child {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.au {
  margin-bottom: 55px;
}

@media screen and (max-width: 768px) {
  .au {
    margin-bottom: 25px;
  }
}

.au .list-item {
  margin: 45px 0 10px;
}

.au .list-item .item.tel-item .show {
  border: 3px solid #ed6e01;
}

.au .list-item .item.tel-item .show .ttl {
  color: #ed6e01;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .au .list-item .item.tel-item .show .ttl {
    line-height: 1.4;
    margin-bottom: 15px;
  }
}

.au .list-item .item.tel-item .show img {
  margin-bottom: 18px;
}

.au .list-item .item.tel-item .show p {
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 768px) {
  .au .list-item .item.tel-item .show p {
    font-size: 16px;
    font-size: 1.6rem;
  }
  .au .list-item .item.tel-item .show p span {
    display: block;
    margin-bottom: 15px;
  }
}

.au .samll {
  margin-top: 10px;
  font-size: 14px;
  font-size: 1.4rem;
}

.accordion {
  margin-top: 40px;
}

.accordion dl {
  margin-bottom: 20px;
}

.accordion dl:last-child {
  margin-bottom: 0;
}

.accordion dl dt {
  font-weight: bold;
  text-align: center;
  padding: 7px;
  color: white;
  background: #87d2f4;
  position: relative;
  font-size: 19px;
  font-size: 1.9rem;
}

.accordion dl dt.active {
  background: #00a9e9;
}

.accordion dl dt:after {
  content: "";
  width: 13px;
  height: 8px;
  background: url(../images/common/top_icon.png) no-repeat;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.accordion dl dt.active:after {
  background: url(../images/common/dow_icon.png) no-repeat;
}

.accordion dl dd {
  display: none;
  padding: 25px 0 30px;
}

@media screen and (max-width: 768px) {
  .accordion dl dd .list-item .item .mt40 {
    margin-top: 5px;
  }
  .accordion dl dd .list-item .item p {
    font-weight: 500;
  }
}

.accordion dl:nth-child(1) dt {
  font-style: oblique;
}

.japanmap {
  margin-top: 45px;
}

.japanmap .img {
  float: left;
  width: 60%;
}

.japanmap .area-map {
  float: right;
  width: 40%;
}

@media screen and (max-width: 768px) {
  .japanmap .area-map {
    width: 100%;
  }
}

.japanmap .area-map li {
  margin-bottom: 2px;
}

.japanmap .area-map li:last-child {
  margin-bottom: 0;
}

.japanmap .area-map li a {
  display: block;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.japanmap .area-map li:hover a.hv-01 {
  background: rgba(55, 134, 197, 0.29);
}

.japanmap .area-map li:hover a.hv-02 {
  background: rgba(135, 203, 249, 0.34);
}

.japanmap .area-map li:hover a.hv-03 {
  background: rgba(47, 162, 47, 0.38);
}

.japanmap .area-map li:hover a.hv-04 {
  background: rgba(184, 199, 179, 0.32);
}

.japanmap .area-map li:hover a.hv-05 {
  background: rgba(187, 119, 223, 0.29);
}

.japanmap .area-map li:hover a.hv-06 {
  background: rgba(22, 149, 147, 0.34);
}

.japanmap .area-map li:hover a.hv-07 {
  background: rgba(181, 226, 158, 0.41);
}

.japanmap .area-map li:hover a.hv-08 {
  background: rgba(249, 202, 139, 0.48);
}

.japanmap .area-map li:hover a.hv-09 {
  background: rgba(242, 148, 38, 0.43);
}

.japanmap .area-map li:hover a.hv-10 {
  background: rgba(232, 64, 37, 0.31);
}

.txt-right {
  text-align: right;
}

.mt40 {
  margin-top: 40px;
}

.page-shicho .regiter-new .btn:hover {
  opacity: 1;
}

.page-shicho a.tel-event {
  pointer-events: none;
  display: block;
}

@media screen and (max-width: 768px) {
  .page-shicho .tel-item a.tel-event {
    pointer-events: inherit;
  }
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.category-view {
  background-color: #fbf4d8;
  padding: 10px 20px;
  margin-bottom: 20px;
  font-size: 18px;
  font-size: 1.8rem;
}

.category-view a {
  color: #a70000;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .category-view {
    font-size: 14px;
    font-size: 1.4rem;
  }
  .category-view a {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.article-heading {
  border-bottom: 1px solid #cccccc;
  display: block;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.article-heading .article-heading-ttl .txt-info {
  color: #333333;
  line-height: 1.2;
  font-weight: 500;
}

.article-heading .article-heading-ttl .txt-info.txt-info-sm {
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .article-heading .article-heading-ttl .txt-info.txt-info-sm {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.article-heading .article-heading-ttl .txt-info.txt-info-lg {
  font-size: 42px;
  font-size: 4.2rem;
}

@media screen and (max-width: 768px) {
  .article-heading .article-heading-ttl .txt-info.txt-info-lg {
    font-size: 31px;
    font-size: 3.1rem;
  }
}

.article-heading .article-heading-ttl .txt-info.txt-info-md {
  font-size: 26px;
  font-size: 2.6rem;
}

@media screen and (max-width: 768px) {
  .article-heading .article-heading-ttl .txt-info.txt-info-md {
    font-size: 19px;
    font-size: 1.9rem;
  }
}

.article-heading .social-row {
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .article-heading .social-row {
    position: relative;
    top: auto;
    right: auto;
    margin-bottom: 30px;
  }
}

.article-heading .movie-genre {
  position: absolute;
  right: 0;
  bottom: 15px;
}

@media screen and (max-width: 768px) {
  .article-heading .movie-genre {
    position: relative;
    right: auto;
    bottom: auto;
    margin-top: 10px;
  }
}

.article-heading .movie-genre.movie-genre-underline a:hover {
  text-decoration: underline;
}

.social-row {
  display: flex;
  margin: 0 -5px;
}

.social-row .social-row-items {
  margin: 0 5px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.social-row .social-row-items:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.movie-genre {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  font-size: 1.6rem;
}

.article-quick-info {
  margin-bottom: 40px;
}

.article-quick-info .article-images {
  border-right: 1px solid #cccccc;
  padding-right: 14px;
  float: left;
  width: 65.4%;
}

@media screen and (max-width: 768px) {
  .article-quick-info .article-images {
    width: 100%;
    padding-right: 0;
    border-right: none;
    margin-bottom: 35px;
  }
}

.article-quick-info .article-desc {
  float: left;
  width: 34.6%;
  padding-left: 24px;
}

@media screen and (max-width: 768px) {
  .article-quick-info .article-desc {
    width: 100%;
    padding-left: 0;
  }
}

.article-quick-info .article-desc .tags .tag-item {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}

.article-quick-info .article-desc .tags .tag-desc {
  display: block;
  margin-bottom: 20px;
}

.article-quick-info .article-desc .tags .tag-desc a {
  display: inline-block;
  padding-left: 15px;
  position: relative;
  font-size: 14px;
  font-size: 1.4rem;
}

.article-quick-info .article-desc .tags .tag-desc a:before {
  display: block;
  content: '\f138';
  font-family: FontAwesome, sans-serif;
  position: absolute;
  top: -1px;
  left: 0;
  font-size: 15px;
  font-size: 1.5rem;
}

.article-quick-info .article-desc .tags .tag-desc.tag-desc-underline a:hover {
  text-decoration: underline;
}

.article-images p {
  color: #333333;
  font-weight: 500;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .article-images p {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

.article-sub-ttl {
  background-color: #f1f1f1;
  border-left: 4px solid #a60000;
  color: #333333;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  padding: 7px 14px;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .article-sub-ttl {
    padding: 5px 10px;
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
}

.program-info {
  margin-bottom: 20px;
}

.program-info tr th {
  width: 110px;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .program-info tr th {
    width: 98px;
  }
}

.program-info tr th span {
  display: block;
  background-color: #f1f1f1;
  text-align: center;
  padding: 2px 10px;
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .program-info tr th span {
    padding: 5px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.program-info tr td {
  vertical-align: top;
  padding: 2px 10px;
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .program-info tr td {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.program-info tr td a:hover {
  text-decoration: underline;
}

.program-info tr.spacer {
  height: 5px;
}

.desc-notice {
  color: #333333;
  font-weight: 500;
  line-height: 1.3;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .desc-notice {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.slideshow-main .slick-slide img, .slideshow-thumbs .slick-slide img {
  width: 100%;
  height: 100%;
}

.slideshow-thumbs li {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.9);
}

.slideshow-thumbs li .bg {
  display: inline-block;
  background: #000;
  vertical-align: middle;
}

.slideshow-thumbs li .bg:hover {
  opacity: .8;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.slideshow-main {
  margin-bottom: 5px;
}

.slideshow-main .slick-slide {
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .slideshow-main {
    margin-bottom: 0;
  }
  .slideshow-main .slick-dots {
    position: relative;
    top: auto;
    bottom: auto;
    padding: 15px 0;
  }
  .slideshow-main .slick-dots li {
    top: -3px;
  }
  .slideshow-main .slick-dots li button {
    padding: 4px;
  }
  .slideshow-main .slick-slide {
    margin-right: 0;
  }
  .slideshow-main .slick-arrow {
    border-color: #a70000;
    z-index: 1;
    padding: 0;
    width: 20px;
    height: 20px;
  }
  .slideshow-main .slick-arrow.slick-prev {
    top: 45%;
    transform: rotate(-135deg) translateX(40%);
  }
  .slideshow-main .slick-arrow.slick-next {
    top: 40%;
    transform: rotate(45deg) translateX(40%);
  }
}

.slideshow-thumbs {
  margin-bottom: 45px;
}

@media screen and (max-width: 768px) {
  .slideshow-thumbs {
    display: none !important;
  }
}

.slideshow-thumbs .slick-slide {
  margin-right: 10px;
}

.slideshow-thumbs .slick-slide.slick-current > div > li {
  display: block;
  position: relative;
}

.slideshow-thumbs .slick-slide.slick-current > div > li img {
  opacity: 0.7;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.slick-slide {
  outline: none !important;
}

.txt-basic {
  color: #333333;
  margin-bottom: 15px;
  font-weight: 500;
  line-height: 1.75;
  font-size: 16px;
  font-size: 1.6rem;
}

.txt-basic.txt-sm {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .txt-basic {
    font-size: 14px;
    font-size: 1.4rem;
  }
  .txt-basic.txt-sm {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) {
  .txt-basic.txt-basic-fix {
    margin-bottom: 0;
  }
}

.txt-orange {
  color: #d63200;
}

.txt-red {
  color: red;
}

.txt-red02 {
  color: #c02;
}

.txt-left {
  text-align: left;
}

@media screen and (max-width: 768px) {
  .table-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.movie-schedule-table {
  margin-bottom: 15px;
  width: 100%;
}

@media screen and (max-width: 640px) {
  .movie-schedule-table {
    margin-bottom: 40px;
  }
}

.movie-schedule-table thead th {
  text-align: center;
  background-color: #333333;
  color: white;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 500;
  border: 1px solid #333333;
}

@media screen and (max-width: 768px) {
  .movie-schedule-table thead th {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.movie-schedule-table tbody td {
  vertical-align: top;
  border: 1px solid #aaaaaa;
  padding: 7px 20px;
  font-size: 16px;
  font-size: 1.6rem;
}

.movie-schedule-table tbody td .datetime {
  color: #a60000;
}

.movie-schedule-table tbody td a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .movie-schedule-table tbody td {
    padding: 8px 10px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.article-copyright {
  color: #999999;
  padding-top: 10px;
  padding-bottom: 90px;
  font-size: 10px;
  font-size: 1rem;
  border-top: 1px solid #cccccc;
}

@media screen and (max-width: 768px) {
  .article-copyright {
    padding-bottom: 70px;
  }
}

.see-it-together {
  padding-top: 37px;
}

.movie-list-box .movie-list-box-item {
  display: block;
  float: left;
  width: 23.75%;
  margin-right: 1.6%;
  margin-bottom: 1.6%;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.movie-list-box .movie-list-box-item:nth-of-type(4n) {
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .movie-list-box .movie-list-box-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0 55px;
  }
}

.movie-list-box .movie-list-box-item .img {
  background-color: #cccccc;
}

.movie-list-box .movie-list-box-item .img img {
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .movie-list-box .movie-list-box-item .img img {
    width: 100%;
  }
}

.movie-list-box .movie-list-box-item .ttl {
  background-color: #f8f8f8;
  padding: 10px 12px;
  color: #333333;
  font-size: 19px;
  font-size: 1.9rem;
  text-transform: uppercase;
  min-height: 68px;
  line-height: 1.2;
}

.movie-list-box .movie-list-box-item:hover {
  opacity: .7;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 768px) {
  .movie-list-box {
    margin-bottom: 60px;
  }
  .movie-list-box .movie-list-box-item .ttl {
    background-color: #f8f8f8;
    font-size: 14px;
    font-size: 1.4rem;
  }
  .movie-list-box .slick-arrow {
    border-color: #a70000;
    z-index: 1;
  }
  .movie-list-box .slick-current + .slick-slide {
    margin-left: -95px;
  }
}

@media screen and (max-width: 768px) {
  .see-it-together .movie-list-box {
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
  }
  .see-it-together .movie-list-box .slick-arrow.slick-prev {
    left: 20px;
  }
  .see-it-together .movie-list-box .slick-arrow.slick-next {
    right: 20px;
  }
}

.broadcast-schedule {
  font-weight: 500;
}

.broadcast-schedule ul li {
  margin-bottom: 10px;
}

.broadcast-schedule-calendar {
  float: left;
  width: 390px;
}

.broadcast-schedule-calendar .txt-nummonth {
  font-size: 26px;
  font-size: 2.6rem;
}

.broadcast-schedule-calendar .txt-month {
  font-size: 22px;
  font-size: 2.2rem;
}

.broadcast-schedule-calendar .table-calendars .broadcast-day {
  background: #900;
}

.broadcast-schedule-calendar .table-calendars .broadcast-day:hover {
  background: #900;
}

.broadcast-schedule-calendar .table-calendars .broadcast-day:hover a {
  text-decoration: underline;
}

.broadcast-schedule-calendar .table-calendars .broadcast-day a {
  color: white;
}

.broadcast-schedule-calendar .table-calendars .broadcast-day.color-blue a {
  color: #7db4ff;
}

.broadcast-schedule-table {
  float: right;
  width: calc(100% - 420px);
}

@media screen and (max-width: 768px) {
  .broadcast-schedule-table {
    width: 100%;
  }
  .broadcast-schedule-table .movie-schedule-table {
    min-width: initial;
    margin-bottom: 20px;
  }
  .broadcast-schedule-table .movie-schedule-table thead th {
    border: 1px solid #aaaaaa;
  }
}

.broadcast-schedule-table-ttl {
  padding-bottom: 15px;
  line-height: 1;
  font-size: 18px;
  font-size: 1.8rem;
}

.calender-note {
  margin: 5px 0 15px;
}

.calender-note-box {
  width: 26px;
  height: 26px;
  margin-right: 8px;
  display: inline-block;
  border: 1px solid #aaaaaa;
  background: #900;
  vertical-align: middle;
}

.calender-note-txt {
  line-height: 26px;
  vertical-align: middle;
  display: inline-block;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .page-detail .filtered-item {
    padding: 40px 0 35px 0;
  }
  .page-detail .filtered-item .filtered-item-file {
    float: none;
  }
  .page-detail .notice {
    margin: 0;
  }
  .page-detail .notice.notice-sm {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .page-detail .article-quick-info .article-desc .tags .tag-item a img {
    width: auto;
    height: 23px;
  }
}

.page-detail .category-view a {
  letter-spacing: -0.5px;
}

.page-detail .category-view a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 769px) {
  .page-detail .article-heading {
    padding-right: 250px;
  }
}

.page-detail .article-heading .article-heading-ttl .txt-info-sm {
  letter-spacing: -1.3px;
}

.page-detail .article-heading .article-heading-ttl .txt-info-lg {
  letter-spacing: -3.2px;
}

.page-detail .article-heading .article-heading-ttl .txt-info-md {
  letter-spacing: -2.3px;
}

.page-detail .top-ttl {
  margin-bottom: 19px;
}

@media screen and (max-width: 768px) {
  .page-detail .top-ttl {
    margin-bottom: 24px;
  }
}

.cable {
  padding-top: 47px;
  margin-bottom: 28px;
}

@media screen and (max-width: 768px) {
  .cable {
    padding-top: 15px;
    margin-bottom: 15px;
  }
}

.cable img {
  width: 100%;
}

.detailtext {
  font-size: 19px;
  line-height: 1.3 !important;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .detailtext {
    font-size: 15px;
    text-align: left;
    line-height: 1.4 !important;
    margin-bottom: 15px;
  }
}

.japanmapwrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 55px;
}

.japanmapwrapper .japanmap {
  width: 547px;
  display: block;
}

@media screen and (max-width: 768px) {
  .japanmapwrapper .japanmap {
    display: none;
  }
}

.japanmapwrapper .area {
  width: 300px;
  margin-left: 30px;
  padding-top: 120px;
}

.japanmapwrapper .area li {
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .japanmapwrapper .area {
    margin-left: 0;
    width: 100%;
    padding-top: 0;
  }
  .japanmapwrapper .area.sp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .japanmapwrapper .area.sp li {
    width: 45%;
    margin-bottom: 4px;
  }
}

.right {
  text-align: right;
}

#shichowrap {
  padding-bottom: 50px;
  font-size: 14px;
  font-size: 1.4rem;
}

.areawrap {
  margin-bottom: 45px;
}

.areawrap p.ttl {
  width: 70px;
  background: #3485c7;
  color: #fff;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: bold;
  border-right: none;
  padding: 5px 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .areawrap p.ttl {
    padding: 4px 0;
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.areawrap p.ttl.ttl2 {
  background: #83cafc;
}

.areawrap p.ttl.ttl3 {
  background: #26a326;
}

.areawrap p.ttl.ttl4 {
  background: #b6c7b0;
}

.areawrap p.ttl.ttl5 {
  background: #be74e5;
}

.areawrap p.ttl.ttl6 {
  background: #009696;
}

.areawrap p.ttl.ttl7 {
  background: #b3e49c;
}

.areawrap p.ttl.ttl8 {
  background: #f9cb86;
}

.areawrap p.ttl.ttl9 {
  background: #f39500;
}

.areawrap p.ttl.ttl10 {
  background: #ea3e16;
}

.areawrap ul {
  width: 100%;
  border-bottom: 2px solid #3485c7;
  margin-bottom: 11px;
}

@media screen and (max-width: 768px) {
  .areawrap ul {
    padding-left: 155px;
    position: relative;
  }
}

.areawrap ul.bb2 {
  border-bottom-color: #83cafc;
}

.areawrap ul.bb3 {
  border-bottom-color: #26a326;
}

.areawrap ul.bb4 {
  border-bottom-color: #b6c7b0;
}

.areawrap ul.bb5 {
  border-bottom-color: #be74e5;
}

.areawrap ul.bb6 {
  border-bottom-color: #009696;
}

.areawrap ul.bb7 {
  border-bottom-color: #b3e49c;
}

.areawrap ul.bb8 {
  border-bottom-color: #f9cb86;
}

.areawrap ul.bb9 {
  border-bottom-color: #f39500;
}

.areawrap ul.bb10 {
  border-bottom-color: #ea3e16;
}

.areawrap ul li {
  width: 100px;
  float: left;
  font-size: 18px;
  padding: 12px 0 10px 10px;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .areawrap ul li {
    width: 80px;
    padding: 6px 0 7px 20px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.areawrap ul li.prefecture {
  width: 153px;
  color: #fff;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  background: #3485c7;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .areawrap ul li.prefecture {
    padding: 6px 0 5px;
    font-size: 14px;
    font-size: 1.4rem;
    position: absolute;
    top: 2px;
    left: 0;
  }
}

.areawrap ul li.prefecture.prefecture2 {
  background: #83cafc;
}

.areawrap ul li.prefecture.prefecture3 {
  background: #26a326;
}

.areawrap ul li.prefecture.prefecture4 {
  background: #b6c7b0;
}

.areawrap ul li.prefecture.prefecture5 {
  background: #be74e5;
}

.areawrap ul li.prefecture.prefecture6 {
  background: #009696;
}

.areawrap ul li.prefecture.prefecture7 {
  background: #b3e49c;
}

.areawrap ul li.prefecture.prefecture8 {
  background: #f9cb86;
}

.areawrap ul li.prefecture.prefecture9 {
  background: #f39500;
}

.areawrap ul li.prefecture.prefecture10 {
  background: #ea3e16;
}

.areawrap ul li a {
  background: url("../images/shicho/cable/cableicon.png") no-repeat left center;
  padding-left: 16px;
  color: #000;
}

@media screen and (max-width: 768px) {
  .areawrap ul li a {
    padding-left: 7px;
    background-size: 4px 7px;
    font-size: 10px;
    font-size: 1rem;
  }
}

.areawrap ul li a:hover {
  color: #a70000;
}

.areawrap ul.item {
  border-bottom: none;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .areawrap ul.item {
    display: none;
  }
}

.areawrap ul.item li {
  border-right: 1px solid #3f3f3f;
  box-sizing: border-box;
  background: url("../images/shicho/cable/areattlbg.jpg") repeat left top;
  font-size: 14px;
  font-size: 1.4rem;
  padding: 6px 0;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.areawrap ul.item li.watcharea {
  width: 31%;
}

.areawrap ul.item li.dept {
  width: 27.73%;
}

.areawrap ul.item li.ch {
  width: 23.27%;
}

.areawrap ul.item li.tel {
  width: 18%;
}

.areawrap table {
  border-top: 1px solid #3f3f3f;
  border-right: 1px solid #3f3f3f;
  margin-bottom: 10px;
  width: 100%;
}

.areawrap table .watcharea {
  width: 31%;
}

@media screen and (max-width: 768px) {
  .areawrap table .watcharea:before {
    content: "視聴可能エリア";
  }
}

.areawrap table .dept {
  width: 27.73%;
}

@media screen and (max-width: 768px) {
  .areawrap table .dept:before {
    content: "局名";
  }
}

.areawrap table .ch {
  width: 23.27%;
}

@media screen and (max-width: 768px) {
  .areawrap table .ch:before {
    content: "ch番号";
  }
}

.areawrap table .tel {
  width: 18%;
}

@media screen and (max-width: 768px) {
  .areawrap table .tel:before {
    width: 28.2%;
    background: #e7e6e6;
    content: "お問い合わせ";
    font-size: 11px;
    padding: 2px 0;
    position: absolute;
    top: 4%;
    left: 3.5%;
    text-align: center;
  }
  .areawrap table .tel p {
    width: 66%;
    float: right;
    text-align: left;
    line-height: 1.3 !important;
  }
}

.areawrap table tr td {
  border-left: 1px solid #3f3f3f;
  border-bottom: 1px solid #3f3f3f;
  box-sizing: border-box;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  line-height: 1.2 !important;
}

.areawrap table tr td.tl {
  text-align: left;
}

.areawrap table tr td.tel {
  border-bottom: 1px solid #3f3f3f;
}

@media screen and (max-width: 768px) {
  .areawrap table tr td.tel {
    width: 100%;
    padding: 4px 5px 8px 10px;
    margin-bottom: 6px;
    position: relative;
  }
  .areawrap table tr td.tel.last {
    margin-bottom: 0 !important;
  }
}

.areawrap table tr td a {
  color: #bc0d17;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .areawrap table {
    border-left: 1px solid #3f3f3f;
    border-collapse: separate;
    padding: 6px 0 0;
  }
  .areawrap table .watcharea, .areawrap table .dept, .areawrap table .ch {
    width: 67%;
    position: relative;
  }
  .areawrap table .watcharea:before, .areawrap table .dept:before, .areawrap table .ch:before {
    width: 42%;
    background: #e7e6e6;
    font-size: 11px;
    padding: 2px 0;
    position: absolute;
    top: 4%;
    left: -44%;
    text-align: center;
  }
  .areawrap table tr td {
    width: 67%;
    border: none;
    box-sizing: border-box;
    font-size: 11px;
    float: right;
    text-align: left;
    padding: 4px 10px 4px 10px;
  }
}

.col-2-content-wrapper {
  display: flex;
  justify-content: space-between;
}

.col-2-content-wrapper .main-content {
  width: calc(100% - 270px);
}

.col-2-content-wrapper .sidebar {
  width: 245px;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .col-2-content-wrapper {
    display: block;
  }
  .col-2-content-wrapper .main-content, .col-2-content-wrapper .sidebar {
    width: 100%;
    margin-bottom: 30px;
  }
}

.breadcrumb {
  margin: 15px 0;
  padding-left: 20px;
  position: relative;
}

.breadcrumb:before {
  display: block;
  content: '';
  background: url("../images/common/icon_nihoneiga.gif") no-repeat center center;
  background-size: 100% 100%;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 4px;
  left: 0;
}

.breadcrumb * {
  font-size: 14px;
  font-size: 1.4rem;
}

.breadcrumb a {
  text-decoration: underline;
}

.breadcrumb a:link {
  color: #bc0d17;
}

.breadcrumb a:visited {
  color: #f5616a;
}

.breadcrumb i {
  margin: 0 2px;
}

.has-breadcrumbs {
  padding-top: 113px;
}

@media screen and (max-width: 768px) {
  .has-breadcrumbs {
    padding-top: 65px;
  }
}

#lNavTitle {
  background: #404040;
  background: -moz-linear-gradient(top, #404040 0%, #000000 100%);
  background: -webkit-linear-gradient(top, #404040 0%, #000000 100%);
  background: linear-gradient(to bottom, #404040 0%, #000000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#404040', endColorstr='#000000', GradientType=0);
  color: #fff;
  min-height: 24px;
  padding: 12px 0 11px 0;
  border-bottom: 1px solid #999;
  text-align: center;
  letter-spacing: 0.1em;
}

#lNavTitle h2 {
  font-weight: bold;
  font-size: 16px;
  font-size: 1.6rem;
}

#lNav {
  margin-bottom: 15px;
  border-bottom: 8px solid black;
}

#lNav ul li {
  font-weight: bold;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid #b8b8b8;
}

#lNav ul li a {
  position: relative;
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #efefef 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #efefef 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #efefef 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#efefef', GradientType=0);
  color: #000;
  display: block;
  padding: 13px 5px 12px 30px;
  text-decoration: none;
  font-size: 12px;
  font-size: 1.2rem;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#lNav ul li a:before {
  display: block;
  content: '';
  background: url("../images/shicho/houjin/icon-arrow.png") no-repeat center center;
  background-size: 100% 100%;
  width: 10px;
  height: 10px;
  overflow: hidden;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 10px;
}

#lNav ul li a:hover, #lNav ul li a.cur {
  background: #bc0d18;
  color: white;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#lNav ul li a:hover:before, #lNav ul li a.cur:before {
  background: url("../images/shicho/houjin/icon-arrow-white.png") no-repeat center center;
  background-size: 100% 100%;
}

#lNav ul.lNavInnerLi li a {
  background: white;
  color: #000;
  padding-left: 40px;
}

#lNav ul.lNavInnerLi li a:before {
  width: 0;
  height: 0;
  left: 30px;
  border: 3px solid transparent;
  border-left: 3px solid black;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#lNav ul.lNavInnerLi li a:hover, #lNav ul.lNavInnerLi li a.cur {
  background: #bc0d18;
  color: white;
}

#lNav ul.lNavInnerLi li a:hover:before, #lNav ul.lNavInnerLi li a.cur:before {
  border-left-color: white;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.lNav-red ul {
  border-bottom: 2px solid #bc0d17;
}

.unit_thumbnail {
  padding: 0 !important;
  margin: 0 !important;
  display: inline-block;
  content: '';
  z-index: 9998;
  position: absolute;
  top: 0;
  left: 0;
}

#pr h2 {
  margin: 0 0 10px 0;
}

#pr h3 {
  margin: 10px 0 10px 0;
}

#pr ul li {
  float: left;
  padding: 0 2px 5px 2px;
}

#mainTitle2 {
  background: url("../images/shicho/houjin/main_tit_bg_1.gif") no-repeat center top;
  background-size: 100% 100%;
  color: #fff;
  letter-spacing: 0.2em;
  overflow: hidden;
  padding: 18px 0;
  text-align: center;
  position: relative;
}

#mainTitle2 h2 {
  font-weight: bold !important;
  line-height: 1.2;
  font-size: 18px;
  font-size: 1.8rem;
}

#mainTitle2 h2 span {
  font-size: smaller;
}

#mainInner {
  background: #fff;
  border-width: 0 1px 1px 1px;
  border-color: #ccc;
  border-style: solid;
  margin-bottom: 30px;
  padding: 19px;
}

#target {
  margin-bottom: 20px;
  font-size: 14px;
  font-size: 1.4rem;
}

#target dl {
  margin: 20px 20px 5px;
  border-bottom: 1px dotted #999;
}

@media screen and (max-width: 768px) {
  #target dl {
    margin: 20px 0 10px;
  }
}

#target dl dt {
  float: left;
  width: 100px;
  padding: 10px 0 10px 10px;
  clear: both;
  font-weight: bold;
  border-top: 1px dotted #999;
}

@media screen and (max-width: 768px) {
  #target dl dt {
    float: none;
    width: auto;
    padding-left: 0;
    padding-bottom: 0;
  }
}

#target dl dd {
  width: calc(100% - 100px);
  margin-left: 100px;
  padding: 10px 10px 10px 0;
  border-top: 1px dotted #999;
}

@media screen and (max-width: 768px) {
  #target dl dd {
    border-top: none;
    float: none;
    width: auto;
    margin-left: 0;
  }
}

#scene {
  margin: 0 auto 30px;
  text-align: center;
}

.call_number_area {
  margin: 0 auto;
  max-width: 536px;
  background-color: #fdf9ef;
  border: 3px #a4825f solid;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: center;
  border-radius: 5px;
}

.call_number_area .tel_call_number {
  display: block;
  line-height: 1.2;
}

@media screen and (min-width: 769px) {
  .call_number_area .tel_call_number {
    pointer-events: none;
  }
}

.call_number_area .tel_call_number span {
  line-height: 1.2 !important;
}

.call_number_area .ttl_call_number_houjin {
  font-size: 18px !important;
}

.call_number_area .ttl_call_number_houjin span {
  font-size: 16px;
}

.pageTop {
  margin: 15px 0 10px;
  text-align: right;
}

#bannerArea ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 768px) {
  #bannerArea ul {
    flex-wrap: wrap;
  }
  #bannerArea ul li {
    margin-bottom: 20px;
  }
}

.center {
  text-align: center;
}

#gojuonList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

#gojuonList li {
  padding: 5px;
  margin-right: 5px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f1f1f1', GradientType=0);
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#gojuonList li a {
  display: block;
  padding: 2px 8px;
  border: 1px solid #cccccc;
  font-weight: bolder;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  overflow: hidden;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-size: 1.6rem;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#gojuonList li:hover {
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#gojuonList li:hover:after {
  display: block;
  content: '';
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top: 5px solid #0f88bc;
  position: absolute;
  bottom: -4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
}

#gojuonList li:hover a {
  color: white;
  background: url("../images/common/blue-pattern.png") no-repeat center center;
  background-size: 100% 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.1), 0 0 10px rgba(255, 255, 255, 0.1), 0 0 10px rgba(255, 255, 255, 0.1), 0 0 10px rgba(255, 255, 255, 0.1);
}

.jimakuTit {
  background: url(../images/jimaku/bg_gradation_gray.jpg) 0 0 repeat-x;
  border: 1px solid #ccc;
  padding: 0.5em;
  padding-left: 0.8em;
  color: #333;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
}

.mainTitle {
  background: url(../images/jimaku/main_tit_bg.gif) 0 0 repeat-x;
  color: #fff;
  font-size: 19px;
  font-size: 1.9rem;
  font-weight: bold !important;
  letter-spacing: 0.2em;
  overflow: hidden;
  padding: 9px 0;
  text-align: center;
}

.page-jimaku {
  border-width: 0 1px 1px 1px;
  border-color: #ccc;
  border-style: solid;
  margin-bottom: 30px;
  padding: 19px;
  font-size: 16px;
  font-size: 1.6rem;
  background: white;
}

@media screen and (max-width: 768px) {
  .page-jimaku {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 10px;
  }
}

.page-jimaku + p.txt-center {
  margin-bottom: 50px;
}

.title-item {
  background: #efefef;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  margin: 1.2em 0 1em;
  padding: 0.3em;
  padding-left: 0.5em;
  border-left: 5px solid #cccccc;
}

.scrol-item {
  padding: 10px 0;
}

.scrol-item a {
  display: inline-block;
  color: #0087ba;
  position: relative;
}

.scrol-item a:hover {
  color: #bc0d17;
  text-decoration: underline;
}

.jimakuCap {
  font-size: 14px;
  font-size: 1.4rem;
}

.jimakuCap .right-img {
  float: right;
  margin-left: 10px;
  min-width: 45%;
}

@media screen and (max-width: 768px) {
  .jimakuCap .right-img {
    float: none;
    margin: 0 auto 15px;
    min-width: auto;
    display: block;
  }
}

.jimakuCap p {
  margin-bottom: 10px;
}

.jimakuCap p:last-child {
  margin-bottom: 0;
}

.jimakuCap-img p img {
  margin-bottom: 15px;
}

.jimakuCap-img .pageTop {
  margin: 5px 0 30px;
}

.accordion02 + .pageTop {
  margin: 5px 0 10px;
}

.call_number_area {
  margin: 20px auto 0;
  max-width: 536px;
  background-color: #fdf9ef;
  border: 3px #a4825f solid;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .call_number_area {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.call_number_area .ttl_call_number {
  margin-bottom: 10px;
  padding: 5px 0;
  background-color: #84603a;
  font-size: 15px;
  font-size: 1.5rem;
  color: #fff;
  border-radius: 2px 2px 0 0;
}

@media screen and (max-width: 768px) {
  .call_number_area .ttl_call_number {
    padding: 5px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.call_number_area .tel_call_number {
  padding: 0 0 15px;
  font-size: 30px;
  font-size: 3rem;
  color: red;
}

.call_number_area .tel_call_number span {
  line-height: 5.5px;
  font-size: 14px;
  font-size: 1.4rem;
  color: #333;
  display: block;
}

@media screen and (max-width: 768px) {
  .call_number_area .tel_call_number span {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.accordion02 dl {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-size: 1.4rem;
}

.accordion02 dl:last-child {
  margin-bottom: 0;
}

.accordion02 dl dt, .accordion02 dl dd {
  padding: 1em;
  padding-left: 50px;
}

.accordion02 dl dt:before, .accordion02 dl dd:before {
  content: "";
  width: 28px;
  height: 28px;
  border-radius: 4px;
  position: absolute;
  left: 13px;
}

.accordion02 dl dt {
  position: relative;
  font-weight: bold;
  border-bottom: 1px dotted #ccc;
}

.accordion02 dl dt:before {
  background: url(../images/jimaku/icon_faq_q.gif) no-repeat;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.accordion02 dl dd {
  position: relative;
}

.accordion02 dl dd:before {
  background: url(../images/jimaku/icon_faq_a.gif) no-repeat;
}

.sidebar-list img {
  width: 100%;
}

.sidebar-list a:hover {
  opacity: 0.7;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.sidebar-list .mt20 {
  margin-top: 20px;
}

.sidebar-list ul li a img {
  width: auto;
}

@media screen and (max-width: 768px) {
  .sidebar-list a {
    display: block;
    width: 48%;
  }
  .sidebar-list a.left-img {
    float: left;
  }
  .sidebar-list a.right-img {
    float: right;
  }
  .sidebar-list a img {
    margin-bottom: 15px;
  }
  .sidebar-list ul li {
    float: left;
    width: 48%;
  }
  .sidebar-list ul li:nth-child(2n) {
    float: right;
  }
  .sidebar-list ul li a {
    width: 100%;
  }
  .sidebar-list ul li a img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .col-2-content-wrapper .mb20 {
    margin-bottom: 10px;
  }
  .col-2-content-wrapper .mb20 a {
    width: 48%;
  }
  .col-2-content-wrapper .mb20 a.left-img {
    float: left;
  }
  .col-2-content-wrapper .mb20 a.right-img {
    float: right;
  }
  .col-2-content-wrapper .mb20 a img {
    margin-bottom: 0;
  }
  .col-2-content-wrapper .pr-sp ul li {
    float: left;
    width: 48%;
  }
  .col-2-content-wrapper .pr-sp ul li:nth-child(2n) {
    float: right;
  }
  .col-2-content-wrapper .pr-sp ul li img {
    width: 100%;
  }
}

.w100 {
  width: 100%;
}

@media screen and (max-width: 1200px) and (min-width: 0 \0 ) {
  #bannerArea .imgWrap img {
    width: 100%;
  }
}

.page-faq {
  padding-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .page-faq {
    padding-bottom: 50px;
  }
}

.page-faq .top-ttl {
  padding-top: 31px;
  margin-bottom: 25px;
}

.page-faq .faq-sec-ttl {
  width: 100%;
  font-size: 22px;
  font-size: 2.2rem;
  padding: 2.5px 0;
  border: #be0a19 solid 3px;
  text-align: center;
  font-weight: bold;
  color: #be0a19;
}

@media screen and (max-width: 768px) {
  .page-faq .faq-sec-ttl {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.page-faq .cntArea {
  padding: 20px 0;
  border-bottom: #969696 solid 1px;
}

.page-faq .cntArea.noBor {
  border: none;
}

.page-faq .cntArea .question {
  position: relative;
  padding-bottom: 7px;
  padding-left: 20px;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  color: #be0a19;
}

.page-faq .cntArea .question:before {
  position: absolute;
  left: 0;
  top: 0;
  content: 'Q.';
}

.page-faq .cntArea .textArea {
  position: relative;
  font-size: 14px;
  font-size: 1.4rem;
  padding-left: 20px;
}

.page-faq .cntArea .textArea:before {
  position: absolute;
  font-size: 16px;
  font-size: 1.6rem;
  left: 0;
  top: 0;
  content: 'A.';
  padding-right: 5px;
}

.page-faq .cntArea .textArea a {
  text-decoration: underline;
}

.page-faq .cntArea .textArea a:hover {
  text-decoration: none;
}

.topArea {
  display: inline-block;
  width: 100%;
  height: auto;
  background-image: url(../images/ondemand/bg_top.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.topArea h1 img {
  width: 100%;
}

.inner-nav {
  position: relative;
  z-index: 1000;
}

.menu-ondermand {
  display: inline-block;
  width: 100%;
}

.menu-ondermand li {
  float: left;
  width: 25%;
}

@media (max-width: 768px) {
  .menu-ondermand li {
    width: 50%;
  }
}

.menu-ondermand li img {
  width: 100%;
  height: auto;
}

.sub-ondermand {
  display: none;
  height: 224px;
  width: 25%;
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  position: absolute;
}

@media (max-width: 768px) {
  .sub-ondermand {
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.sub-ondermand li {
  width: 100%;
  height: 44px;
  background: #323232;
  text-align: center;
  font-size: 16px;
  float: none;
  border-bottom: #969696 solid 1px;
}

.sub-ondermand li a {
  width: 100%;
  height: 44px;
  display: block;
  line-height: 44px;
  color: #fff;
}

.sub-ondermand li a:hover {
  background: #be0a19;
}

.sub-ondermand .close-sub a {
  color: #fff;
  background: #be0a19;
}

.cntArea {
  padding: 40px 0;
}

.cntArea h3 {
  margin-bottom: 25px;
}

.cntArea .txt {
  font-weight: bold;
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: -0.5px;
  padding-bottom: 15px;
  line-height: 1.8;
}

.pointWrap {
  display: table;
  width: 100%;
}

@media (max-width: 768px) {
  .pointWrap {
    display: block;
  }
}

.pointWrap .ct-left {
  display: table-cell;
  width: 65%;
}

@media (max-width: 768px) {
  .pointWrap .ct-left {
    width: 100%;
    display: block;
  }
}

.pointWrap .ct-left dl {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.pointWrap .ct-left dl dt {
  width: 122px;
  float: left;
}

@media (max-width: 768px) {
  .pointWrap .ct-left dl dt {
    width: 50px;
  }
}

.pointWrap .ct-left dl dd {
  float: left;
  width: calc(100% - 130px);
  font-size: 14px;
  line-height: 1.8;
  margin: 25px 0 0 7px;
  letter-spacing: -0.5px;
}

@media (max-width: 768px) {
  .pointWrap .ct-left dl dd {
    width: calc(100% - 50px);
    margin: 0;
    padding-left: 10px;
  }
}

.pointWrap .ct-left dl dd span {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  color: #be0a19;
}

.pointWrap .ct-right {
  display: table-cell;
  width: 35%;
  position: relative;
}

@media (max-width: 768px) {
  .pointWrap .ct-right {
    width: 100%;
    display: block;
  }
}

.pointWrap .ct-right img {
  position: absolute;
  right: 0;
  bottom: 35px;
}

@media (max-width: 768px) {
  .pointWrap .ct-right img {
    position: relative;
    display: block;
    margin: 0 auto;
    bottom: 0;
  }
}

.cnt03 {
  margin: 0 -10px;
}

.cnt03 h3 {
  margin-bottom: 30px;
  margin-left: 10px;
}

.cnt03 .list-wrap {
  display: block;
  width: 100%;
  margin: 0 0 20px;
}

.cnt03 .list-wrap li {
  padding: 0 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .cnt03 .list-wrap li {
    margin-bottom: 20px;
  }
}

.cnt03 .list-wrap li img {
  width: 100%;
}

.cnt03 .list-wrap li img:hover {
  opacity: .7;
}

.cnt03 .list-wrap.col-2 li {
  float: left;
  width: 50%;
}

.cnt03 .list-wrap.col-3 li {
  float: left;
  width: 33.333%;
}

@media (max-width: 768px) {
  .cnt03 .list-wrap.col-3 li {
    width: 50%;
    margin-right: 0;
  }
  .cnt03 .list-wrap.col-3 li img {
    display: block;
    margin: 0 auto;
  }
}

.line {
  clear: both;
  height: 1px;
  background-color: #000;
  margin: 30px auto;
  overflow: hidden;
}

.credit {
  margin: 0 auto;
  line-height: 14px;
  color: #555;
  font-size: 10px;
  font-size: 1rem;
  padding: 10px 0;
}

.program .heading {
  padding: 30px 0 20px;
}

@media (max-width: 768px) {
  .program .heading {
    padding: 20px 0;
  }
}

.list-new {
  display: inline-block;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: #969696 solid 1px;
}

.list-new li {
  width: 49.5%;
  float: left;
  display: inline-block;
  margin-right: 1%;
  margin-bottom: 1%;
  position: relative;
}

.list-new li:nth-child(2n) {
  margin-right: 0;
}

.list-new li a img {
  width: 100%;
}

.list-new li p {
  position: absolute;
  left: 2px;
  bottom: 2px;
  width: calc(100% - 2px);
  height: 30px;
  background: #ffe600;
  opacity: 0;
  zoom: 1;
  line-height: 33px;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
  font-family: "Hiragino Kaku Gothic ProN", "meiryo", "メイリオ", "ms pgothic", "ms pゴシック", "osaka", sans-serif, "TakaoPGothic", "Sazanami Gothic";
}

/*popup*/
.popupBox {
  position: relative;
  width: 536px;
  height: auto;
  background: #fff;
  border-top: 2px solid #ffe600;
  border-left: 2px solid #ffe600;
  border-right: 2px solid #ffe600;
  padding: 15px 20px 0 20px;
  font-size: 14px;
  text-align: left;
  overflow: hidden;
}

.popupBox h2 {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #be0a19;
  border-bottom: #969696 solid 2px;
  padding-bottom: 5px;
}

.popupBox .proInfo {
  font-weight: bold;
  line-height: 24px;
  padding: 10px 0;
}

@media (max-width: 768px) {
  .popupBox .proInfo {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.popupBox .proTxt {
  line-height: 24px;
  padding-bottom: 15px;
}

@media (max-width: 768px) {
  .popupBox .proTxt {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.popupBox .price {
  background: #ffe600;
  color: #be0a19;
  font-weight: bold;
  padding: 4px 0 2px 6px;
  margin-left: -20px;
  width: 100%;
  min-height: 24px;
  text-align: center;
}

@media (max-width: 768px) {
  .popupBox .price {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media (max-width: 374px) {
  .popupBox .price {
    font-size: 10px;
    font-size: 1rem;
  }
}

.fancybox-close-small {
  background: #be0a19;
  border: 0;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  height: 28px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: auto;
  bottom: 0;
  width: 28px;
  z-index: 10;
}

@media (max-width: 768px) {
  .fancybox-close-small {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 374px) {
  .fancybox-close-small {
    width: 24px;
    height: 24px;
  }
}

.fancybox-hidden {
  display: none;
}

.center-slick .slick-active.slick-center .item img {
  width: 100%;
  max-width: 100%;
  padding-top: 0;
  position: relative;
  z-index: 999;
}

.center-slick .slick-slide img {
  width: 60%;
  padding-top: 40px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
}

.center-slick .slick-active {
  background: red;
}

.center-slick .slick-active + .slick-active {
  background: blue;
}

.center-slick .slick-active + .slick-active + .slick-active {
  background: yellow;
}

.compensate-for-scrollbar {
  margin-right: 0 !important;
}

.carousel-list {
  padding: 44px 0 0;
  width: 980px;
  height: 380px;
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0 auto;
}

.carousel-list .next {
  position: absolute;
  top: 189px;
  left: 207px;
  z-index: 3;
  cursor: pointer;
}

.carousel-list .prev {
  position: absolute;
  top: 189px;
  left: 728px;
  z-index: 4;
  cursor: pointer;
}

.list-thumnail {
  width: 1300px;
}

.list-thumnail li {
  position: relative;
  background: #fff;
}

.list-thumnail li img {
  width: 100%;
  height: auto;
}

.list-thumnail li img:hover {
  opacity: 0.5;
}

.list-thumnail .li00 {
  width: 278px;
  float: left;
  margin-top: 76px;
  margin-left: -278px;
  opacity: 0.7;
  zoom: 1;
}

.list-thumnail .li01 {
  width: 278px;
  float: left;
  margin-top: 76px;
  margin-left: 25px;
  opacity: 0.7;
  zoom: 1;
}

.list-thumnail .li02 {
  width: 504px;
  float: left;
  margin-left: -65px;
  position: relative;
  z-index: 2;
  opacity: 1.0;
}

.list-thumnail .li02 img:hover {
  opacity: .7;
}

.list-thumnail .li03 {
  width: 278px;
  float: left;
  margin: 76px 0 0 -65px;
  position: relative;
  z-index: 1;
  opacity: 0.7;
  zoom: 1;
}

.list-thumnail .li04 {
  width: 278px;
  float: left;
  margin: 76px 0 0 25px;
  position: relative;
  z-index: 1;
  opacity: 0.7;
  zoom: 1;
}

.entryArea {
  padding: 30px 40px 0;
}

@media (max-width: 768px) {
  .entryArea {
    padding: 20px 0 0;
  }
}

.entryArea .heading {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .entryArea .heading {
    margin-bottom: 15px;
  }
}

.entryArea .heading-md {
  padding: 22px 0 20px;
}

@media (max-width: 768px) {
  .entryArea .heading-md {
    padding: 5px 0 15px;
  }
}

.entryArea .heading-md img {
  width: 100%;
}

.entryLine {
  display: inline-block;
  width: 100%;
}

.entryLine img {
  width: 100%;
}

.infoBox {
  border: #be0a19 solid 3px;
  margin-bottom: 38px;
  padding: 15px;
  text-align: center;
}

@media (max-width: 768px) {
  .infoBox {
    padding: 10px;
    margin-bottom: 20px;
  }
}

.infoBox p {
  font-weight: bold;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 24px;
  color: #be0a19;
}

@media (max-width: 768px) {
  .infoBox p {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.infoBox p a {
  color: #be0a19;
  text-decoration: underline;
}

.infoBox p a:hover {
  text-decoration: none;
}

.infoBox .cb {
  color: #000;
}

.infoBox .txt {
  font-size: 20px;
  font-size: 2rem;
}

@media (max-width: 768px) {
  .infoBox .txt {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.infoBox .txt a:hover {
  text-decoration: none;
}

.infoBox .officialBtn {
  max-width: 491px;
  margin: 0 auto;
  padding: 18px 0;
}

.cntAreaEntry {
  display: block;
  max-width: 620px;
  padding: 0;
  margin: 0 auto;
  text-align: center;
}

.cntAreaEntry .heading-sm {
  padding-bottom: 10px;
}

.cntAreaEntry .link {
  font-weight: normal;
  padding: 0 0 35px 0;
  font-size: 14px;
  font-size: 1.4rem;
}

@media (max-width: 768px) {
  .cntAreaEntry .link {
    padding-bottom: 20px;
  }
}

.cntAreaEntry .link a {
  color: #000;
  text-decoration: underline;
}

.cntAreaEntry .link a:hover {
  text-decoration: none;
}

.cntAreaEntry p {
  font-weight: bold;
  padding: 28px 0;
  font-size: 16px;
  font-size: 1.6rem;
}

@media (max-width: 768px) {
  .cntAreaEntry p {
    padding: 14px 0;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.cntAreaEntry .arrow {
  width: 43px;
  margin: 0 auto;
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .cntAreaEntry .arrow {
    padding-bottom: 15px;
  }
}

.iconInfoAWrap {
  background: #fff;
  border-width: 0 1px 0 1px;
  border-color: #ccc;
  border-style: solid;
  padding: 10px;
}

.iconInfoAWrap.no-border {
  border: none;
}

.iconInfoAWrap .guideFoot {
  border-top: 1px dotted #ccc;
  padding: 1.2em 0 0 0;
  margin-bottom: 1.25em;
}

.iconInfoAWrap .guideFoot ul {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .iconInfoAWrap .guideFoot ul {
    flex-wrap: wrap;
  }
}

.iconInfoAWrap .guideFoot ul li {
  color: #000;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .iconInfoAWrap .guideFoot ul li {
    width: 100%;
  }
}

.iconInfoAWrap .guideFoot ul li:before {
  display: inline-block;
  content: '';
  background: url(../images/shicho/houjin/icon-arrow.png) no-repeat center center;
  background-size: 100% 100%;
  width: 10px;
  height: 10px;
  border-radius: 2px;
}

.iconInfoAWrap .guideFoot ul li a {
  color: #000;
  font-size: 14px;
  font-size: 1.4rem;
}

.iconInfoAWrap .guideFoot ul li a:hover {
  text-decoration: underline;
}

.iconInfoAWrap .guideFoot p {
  font-size: 13px;
  font-size: 1.3rem;
}

@media screen and (max-width: 768px) {
  .iconInfoAWrap .guideFoot p {
    text-align: left !important;
  }
}

#progTab {
  display: flex;
  align-items: center;
  border: 1px solid #d2d2d2;
}

@media screen and (max-width: 768px) {
  #progTab {
    flex-wrap: wrap;
  }
}

#progTab .tab-label {
  display: block;
  font-weight: bold;
  font-size: 14px;
  font-size: 1.4rem;
  padding: 10px 20px;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  #progTab .tab-label {
    width: 100%;
  }
}

#progTab .tabContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #progTab .tabContent {
    padding: 0 20px;
    flex-wrap: wrap;
  }
}

#progTab .tabContent .tabSort {
  display: flex;
  position: relative;
  bottom: -1px;
}

#progTab .tabContent .tabSort li {
  border: 1px solid #d2d2d2;
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f1f1f1', GradientType=0);
  border-top-width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-size: 1.4rem;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#progTab .tabContent .tabSort li:before {
  display: block;
  content: '';
  width: 0;
  height: 0;
  background-color: transparent;
}

#progTab .tabContent .tabSort li a {
  padding: 10px 20px;
  font-weight: bold;
  color: #000;
}

#progTab .tabContent .tabSort li.active, #progTab .tabContent .tabSort li:hover {
  border-top-color: #c1222c;
  border-bottom-color: white;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  background: #f7f7f7;
  background: -moz-linear-gradient(top, #f7f7f7 0%, #ffffff 100%);
  background: -webkit-linear-gradient(top, #f7f7f7 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #f7f7f7 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f7f7', endColorstr='#ffffff', GradientType=0);
}

#progTab .tabContent .tabSort li.active a, #progTab .tabContent .tabSort li:hover a {
  color: #c1222c;
}

#progTab .tabContent .tabMonth {
  display: flex;
}

#progTab .tabContent .tabMonth li {
  border: 1px solid #CCCCCC;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f1f1f1', GradientType=0);
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#progTab .tabContent .tabMonth li:before {
  display: block;
  content: '';
  width: 0;
  height: 0;
  background-color: transparent;
}

#progTab .tabContent .tabMonth li a {
  display: block;
  padding: 5px 10px;
  color: #000;
  text-decoration: underline;
  font-weight: bold;
  font-size: 14px;
  font-size: 1.4rem;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#progTab .tabContent .tabMonth li.active, #progTab .tabContent .tabMonth li:hover {
  background: #430d0a;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#progTab .tabContent .tabMonth li.active a, #progTab .tabContent .tabMonth li:hover a {
  text-decoration: none;
  color: white;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.programTitle {
  background: #eaeaea;
  background: -moz-linear-gradient(top, #eaeaea 0%, #ffffff 28%);
  background: -webkit-linear-gradient(top, #eaeaea 0%, #ffffff 28%);
  background: linear-gradient(to bottom, #eaeaea 0%, #ffffff 28%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eaeaea', endColorstr='#ffffff', GradientType=0);
  clear: both;
  border: #d6d6d6 1px solid;
  border-top: none;
  padding: 5px 10px 3px 3px;
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.programTitle div {
  padding-left: 10px;
  border-left: #bb0d16 3px solid;
}

.heightLineParent {
  overflow: hidden;
}

.heightLineParent .progBox {
  border: 1px solid #ccc;
  float: left;
  width: 49.5%;
  margin-right: 1%;
  margin-bottom: 1%;
}

.heightLineParent .progBox:nth-of-type(2n) {
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .heightLineParent .progBox {
    width: 100%;
    margin-right: 0;
  }
}

.heightLineParent .progBox .progBoxIcon {
  background: #f1f1f1;
  margin: 1px 1px 8px 1px;
  text-align: right;
}

.heightLineParent .progBox .progBoxInfo2 {
  margin: 0 7px 7px 7px;
}

.heightLineParent .progBox .progBoxInfo2 .progBoxTit {
  text-align: justify;
}

.heightLineParent .progBox .progBoxInfo2 .progBoxTit a {
  font-weight: bold;
  color: #69C;
  text-decoration: underline;
  font-size: 14px;
  font-size: 1.4rem;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.heightLineParent .progBox .progBoxInfo2 .progBoxTit a:hover {
  color: #bb0d16;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.heightLineParent .progBox .progBoxInfo2 .progBoxDate {
  font-size: 12px;
  font-size: 1.2rem;
}

.heightLineParent .progBox .progBoxInfo2 .progBoxStaff {
  background: #f1f1f1;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 140%;
  margin: 5px 0 0 0;
  padding: 5px;
}

#yearList {
  clear: both;
  border-top: 1px dotted #ccc;
  border-bottom: 1px dotted #ccc;
  letter-spacing: .03em;
  margin-bottom: 30px;
  padding: .6em 0 .6em;
  font-size: 14px;
  font-size: 1.4rem;
}

#yearList a {
  display: inline-block;
  color: #000;
  margin: 0 2px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .sp {
    display: none !important;
  }
}

#page-genre, #page-year-range, #page-search {
  padding-top: 140px;
}

@media screen and (max-width: 768px) {
  #page-genre, #page-year-range, #page-search {
    padding-top: 100px;
  }
}

@media screen and (min-width: 769px) {
  #page-genre .top-ttl, #page-year-range .top-ttl, #page-search .top-ttl {
    margin-bottom: 10px;
  }
}

.search-module {
  padding: 25px 0;
  position: relative;
}

.search-module:after {
  display: block;
  content: '';
  width: 0;
  height: 0;
  border: 22px solid transparent;
  border-top: 23px solid #f1f1f1;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.search-module .navi-links {
  display: flex;
  border-bottom: 1px solid #666666;
  margin-bottom: 22px;
}

@media screen and (max-width: 768px) {
  .search-module .navi-links {
    margin-bottom: 20px;
    justify-content: space-between;
  }
}

.search-module .navi-links li {
  display: flex;
  width: 22%;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-right: 10px;
  background-color: #fff;
  position: relative;
  top: 1px;
  border: 1px solid transparent;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 1px solid #666666;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 768px) {
  .search-module .navi-links li {
    margin-right: 0;
    width: 24%;
  }
}

.search-module .navi-links li:before {
  display: block;
  content: '';
  width: 0;
  height: 0;
  background-color: transparent;
}

.search-module .navi-links li a {
  display: block;
  width: 100%;
  text-align: center;
  padding: 12px;
  color: #a60000;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .search-module .navi-links li a {
    line-height: 1.2;
    font-size: 14px;
    font-size: 1.4rem;
    padding: 12px 3px;
  }
}

.search-module .navi-links li a small {
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .search-module .navi-links li a small {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.search-module .navi-links li.active, .search-module .navi-links li:hover {
  background-color: #f1f1f1;
  border: 1px solid #666666;
  border-bottom-color: #f1f1f1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.search-module .navi-links li.active:after, .search-module .navi-links li:hover:after {
  display: block;
  content: '';
  width: 100%;
  height: 4px;
  background-color: #a60000;
  position: absolute;
  top: 0;
  left: 0;
}

.search-module .navi-links li.active a, .search-module .navi-links li:hover a {
  color: #333333;
}

.search-module .navi-links li.active a:before {
  display: inline-block;
  content: '';
  width: 8px;
  height: 8px;
  border-right: 2px solid #333333;
  border-bottom: 2px solid #333333;
  transform: rotate(45deg);
  position: relative;
  bottom: 4px;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .search-module .navi-links li.active a:before {
    width: 5px;
    height: 5px;
    position: absolute;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
    bottom: 0;
  }
}

.search-module .navi-months {
  display: flex;
  margin-bottom: 16px;
}

@media screen and (max-width: 768px) {
  .search-module .navi-months {
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.search-module .navi-months li {
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .search-module .navi-months li {
    width: 32.5%;
    margin: 0;
  }
}

.search-module .navi-months li a {
  display: block;
  border-radius: 5px;
  background-color: #fff;
  color: #a60000;
  padding: 8px 68px;
  font-size: 16px;
  font-size: 1.6rem;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 768px) {
  .search-module .navi-months li a {
    padding: 10px;
    text-align: center;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.search-module .navi-months li.active a, .search-module .navi-months li:hover a {
  background-color: #a60000;
  color: white;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.navi-genres {
  height: auto;
  padding: unset;
}

.select {
  display: block;
  height: 45px;
  cursor: pointer;
  position: relative;
  font: normal 11px/22px Arial, Sans-Serif;
  color: black;
}

@media screen and (min-width: 769px) {
  .select {
    height: auto;
  }
  .select select {
    display: none;
  }
}

.styledSelect {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #333333;
  padding: 13px 10px;
  text-align: center;
  font-weight: bold;
  color: white;
  font-size: 14px;
  font-size: 1.4rem;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (min-width: 769px) {
  .styledSelect {
    display: none;
  }
}

.styledSelect:after {
  content: "";
  width: 8px;
  height: 8px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(45deg) translateY(-50%);
  position: absolute;
  right: 20px;
  top: 42%;
}

.styledSelect:active, .styledSelect.active, .styledSelect:hover {
  background-color: #444444;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.styledSelect:active:after, .styledSelect.active:after {
  transform: rotate(225deg) translateY(-50%);
  right: 13px;
  top: 37%;
}

.options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0 0;
  padding: 0 0;
  list-style: none;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.options li {
  padding: 8px;
  margin: 0;
  font-size: 14px;
  font-size: 1.4rem;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.options li:hover {
  background-color: #f1f1f1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  color: #a60000;
}

@media (max-width: 768px) {
  .options li {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .options li a {
    display: inline-block;
    width: 100%;
    padding: 8px;
  }
}

@media screen and (min-width: 769px) {
  .options {
    position: relative;
    display: block !important;
    border: none;
    box-shadow: none;
    background-color: transparent;
    margin-bottom: -10px;
  }
  .options li {
    display: inline-block;
    width: 140px;
    height: 40px;
    margin-right: 10px !important;
    margin-bottom: 10px;
    padding: 0;
    line-height: 40px;
    border-radius: 5px;
    background-color: #fff;
    color: #a60000;
    text-align: center;
    font-size: 15px;
    font-size: 1.5rem;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
  .options li.selected, .options li:hover {
    background-color: #a60000;
    color: white;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
  }
  .options li.selected a, .options li:hover a {
    color: white;
  }
  .options li a {
    display: inline-block;
    width: 100%;
  }
}

.mt35 {
  margin-top: 35px;
}

.list-movie-genres {
  padding: 45px 0 0;
}

@media screen and (max-width: 768px) {
  .list-movie-genres {
    padding: 55px 0 34px;
  }
}

div[id^="genre-"] {
  display: none;
}

div[id^="genre-"].display {
  display: block;
}

.page-icon_page .section-icon {
  padding: 35px 0 0 0;
}

.page-icon_page .icon-ttl {
  font-size: 34px;
  font-size: 3.4rem;
}

@media screen and (max-width: 768px) {
  .page-icon_page .icon-ttl {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.page-icon_page .table-container {
  width: 100%;
  margin: 25px 0 30px 0;
}

.page-icon_page .table-container .table-cnr {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .page-icon_page .table-container .table-cnr {
    width: 1200px;
    overflow: auto;
  }
}

.page-icon_page .table-container .table-sty {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .page-icon_page .table-container .table-sty {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
  .page-icon_page .table-container .table-sty .col-01 {
    width: 110px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
  .page-icon_page .table-container .table-sty .col-02 {
    width: 110px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
  .page-icon_page .table-container .table-sty .col-03 {
    width: 160px;
  }
}

.page-icon_page .table-container .table-sty th {
  padding: 3px 0;
  background: #333;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center;
}

.page-icon_page .table-container .table-sty th:last-child {
  border-right: 1px solid #333;
}

.page-icon_page .table-container .table-sty td {
  padding: 11px 15px 11px 20px;
  color: #333;
  font-size: 16px;
  font-size: 1.6rem;
  border-bottom: 1px solid #aaa;
  border-left: 1px solid #aaa;
  vertical-align: top;
}

.page-icon_page .table-container .table-sty td:last-child {
  border-right: 1px solid #aaa;
}

.page-icon_page .table-container .table-sty td img {
  width: auto;
  height: 26px;
}

@media screen and (max-width: 768px) {
  .page-icon_page .table-container .table-sty td img {
    height: 23px;
  }
}

.page-icon_page .contact-wrap .campaign-list2 {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .page-icon-new .inner {
    padding: 0 15px;
  }
}

.page-icon-new p {
  line-height: 1.4;
  font-size: 14px;
  font-size: 1.4rem;
  margin-bottom: 14px;
}

.page-icon-new p a {
  color: #42413C;
  text-decoration: underline;
}

.page-icon-new p a:hover {
  text-decoration: none;
}

.page-icon-new .font_big {
  line-height: 1.4;
  font-size: 17px;
  font-size: 1.7rem;
}

@media (max-width: 768px) {
  .page-icon-new .font_big {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.page-icon-new .font_s {
  line-height: 1.2;
  font-size: 12px;
  font-size: 1.2rem;
}

.page-icon-new .font_b {
  font-weight: bold;
}

.heading-icon-top {
  display: inline-block;
  width: 100%;
}

.heading-icon-top img {
  width: 100%;
}

.heading-icon-top area {
  outline: none;
}

.content-icon {
  padding: 10px 20px;
}

@media (max-width: 768px) {
  .content-icon {
    padding: 10px 0;
  }
}

.ttl-icon {
  background-image: url(../images/icon-page01/h1.gif);
  background-size: 100% 100%;
  height: auto;
  width: 100%;
  text-align: left;
  padding: 8px 20px 0;
  font-size: 19px;
  font-size: 1.9rem;
  margin-bottom: 10px;
}

.ttl-icon a {
  color: #6E6C64;
}

.ttl-icon a:hover {
  text-decoration: underline;
}

.table_icon {
  width: 100%;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .table_icon {
    width: 768px;
    margin-bottom: 20px;
  }
}

.table_icon th {
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
  border-right: 3px solid #fff;
  border-bottom: 4px solid #fff;
}

.table_icon td {
  font-size: 14px;
  font-size: 1.4rem;
}

.table_icon td p {
  padding: 0 15px;
  margin-bottom: 14px;
  line-height: 1.4;
}

.table_icon .td_center {
  text-align: center;
  vertical-align: middle;
}

.table_icon .td_1 {
  background-color: #f7f9f9;
  border-bottom-width: medium;
  border-bottom-style: solid;
  border-bottom-color: #999;
  border-right: 3px solid #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
}

@media (max-width: 768px) {
  .table-scroll {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.th_cate {
  background-image: url(../images/icon-page01/th_category.gif);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 33px;
  width: 15%;
}

.th_icon {
  background-image: url(../images/icon-page01/th_icon.gif);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 33px;
  width: 20%;
}

.th_name {
  background-image: url(../images/icon-page01/th_name.gif);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 33px;
  width: 15%;
}

.th_discript {
  background-image: url(../images/icon-page01/th_discription.gif);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 33px;
  width: 50%;
}

.table_pic {
  border-collapse: separate;
  border-spacing: 2px;
  padding: 10px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
  vertical-align: middle;
}

.table_pic .pic_wrapper {
  padding: 5px 10px;
  border: thin solid #999;
  width: 50%;
  background-color: #f7f9f9;
}

.table_pic .pic_wrapper img {
  width: 100%;
}

.parent-list {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .parent-list {
    margin-bottom: 20px;
  }
}

.parent-list .item {
  float: left;
  background: #f1f1f1;
}

.parent-list .item .txt {
  font-size: 16px;
  font-size: 1.6rem;
  padding: 10px 15px 15px;
  color: #333333;
  font-weight: 500;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

@media screen and (max-width: 768px) {
  .parent-list .item .txt {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.parent-list .item .txt small {
  font-weight: normal;
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.8;
}

.parent-list .item:hover .img {
  opacity: 0.7;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.parent-list .item:hover .txt {
  color: #a60000;
  text-decoration: underline;
}

.parent-list.col-4 .item {
  display: block;
  float: left;
  width: 23.75%;
  margin-right: 1.6%;
  margin-bottom: 1.6%;
}

.parent-list.col-4 .item:nth-child(4n) {
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .parent-list.col-4 .item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
  }
  .parent-list.col-4 .item:last-child {
    margin-bottom: 0;
  }
  .parent-list.col-4 .item .img {
    float: left;
    width: 40%;
  }
  .parent-list.col-4 .item .txt {
    float: right;
    width: 60%;
  }
}

@media screen and (max-width: 360px) {
  .parent-list.col-4 .item .txt {
    padding: 10px 5px 10px;
  }
  .parent-list.col-4 .item .txt small {
    font-size: 10px;
    font-size: 1rem;
  }
}

.accordion-sp {
  margin-top: 40px;
}

@media (min-width: 769px) {
  .accordion-sp .parent-item .parent-list {
    display: block;
  }
}

.options-02 {
  display: flex;
}

@media screen and (max-width: 768px) {
  .options-02 {
    flex-wrap: wrap;
    margin-bottom: -10px;
    justify-content: space-between;
  }
}

.options-02 li {
  display: flex;
  flex-grow: 1;
  float: left;
  width: 10%;
  margin-right: 10px;
}

.options-02 li:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .options-02 li {
    width: 32.5%;
    margin-bottom: 10px;
    flex-grow: 0;
    justify-content: space-between;
    margin-right: 0;
  }
}

.options-02 li a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 8px 5px;
  border-radius: 5px;
  background-color: #fff;
  color: #a60000;
  font-size: 16px;
  font-size: 1.6rem;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.options-02 li a:hover {
  background-color: #a60000;
  color: white;
}

.options-02 li a:before {
  display: block;
  content: '';
  width: 0;
  height: 0;
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .accordion-sp .parent-item .article-sub-ttl {
    position: relative;
  }
  .accordion-sp .parent-item .article-sub-ttl:after {
    content: "";
    width: 18px;
    height: 11px;
    background: url(../images/program/dow.png) no-repeat;
    background-size: 100%;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .accordion-sp .parent-item .article-sub-ttl.active:after {
    background: url(../images/program/top.png) no-repeat;
    background-size: 100%;
  }
  .accordion-sp .parent-item .parent-list {
    display: none;
  }
  .accordion-sp .parent-item.active .parent-list {
    display: block;
  }
}

#page-year-range .accordion-sp {
  margin-top: 53px;
}

@media screen and (max-width: 768px) {
  #page-year-range .accordion-sp {
    margin-top: 40px;
  }
}

#page-year-range .accordion-sp .article-sub-ttl {
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  #page-year-range .accordion-sp .article-sub-ttl {
    pointer-events: inherit;
  }
}

#page-year-range .parent-list .item .txt small:first-child {
  font-weight: bold !important;
}

#page-year-range .parent-list .item .txt small.fw-bold {
  font-weight: bold;
}

#osusume .plan-wrap {
  padding-top: 31px;
}

@media screen and (max-width: 768px) {
  #osusume .plan-wrap {
    padding-bottom: 20px;
  }
}

#osusume .plan-wrap .ttl-wrap .top-ttl {
  font-size: 34px;
  font-size: 3.4rem;
  letter-spacing: 0;
  margin-bottom: 38px;
}

@media screen and (max-width: 768px) {
  #osusume .plan-wrap .ttl-wrap .top-ttl {
    font-size: 30px;
    font-size: 3rem;
  }
}

#osusume .plan-list-slide .plan-list li {
  width: calc(96.6% / 3);
  margin-left: 0 !important;
  margin-right: 1.7%;
}

#osusume .plan-list-slide .plan-list li:nth-child(3n) {
  margin-right: 0;
}

.banner_img img {
  margin: 0 auto;
}

.search-page .filtered-item {
  padding: 15px 0 30px;
}

@media (max-width: 768px) {
  .search-page .filtered-item {
    padding: 15px 0;
  }
}

.search-page .search-module {
  padding: 25px 0 16px;
}

.search-page .search-module .txt-basic {
  margin-bottom: 5px;
}

.search-page .search-module .navi-links {
  margin-bottom: 20px;
}

.search-page .word-ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .search-page .word-ttl {
    position: relative;
    height: 35px;
    color: #fff;
    background-color: #333;
  }
  .search-page .word-ttl:before {
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    position: absolute;
    right: 20px;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    top: 13px;
  }
  .search-page .word-ttl.active:before {
    top: 8px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-left: none;
    border-top: none;
  }
}

.list-search {
  display: inline-block;
  width: 100%;
  padding-top: 35px;
}

.list-search .main-list-search {
  float: left;
  width: 71.6%;
}

@media (max-width: 768px) {
  .list-search .main-list-search {
    width: 100%;
  }
}

.list-search .sidebar-search {
  float: left;
  width: 28.4%;
  padding-left: 50px;
}

@media (max-width: 768px) {
  .list-search .sidebar-search {
    width: 100%;
    padding-left: 0;
  }
}

.list-item-search {
  display: inline-block;
  width: 100%;
}

.list-item-search li {
  padding: 20px 0 18px;
  border-top: 1px solid #cccccc;
  box-sizing: border-box;
}

.list-item-search li:last-child {
  border-bottom: 1px solid #cccccc;
}

.item-search {
  display: inline-block;
  width: 100%;
}

.item-search .img {
  float: left;
  width: 300px;
  padding-right: 20px;
}

.item-search .img img {
  width: 100%;
}

.item-search .content {
  float: left;
  width: calc(100% - 300px);
}

@media (max-width: 768px) {
  .item-search .content {
    width: 100%;
  }
}

.ttl-search {
  line-height: 1.6;
}

.ttl-search.ttl-search-style01 {
  font-size: 20px;
  font-size: 2rem;
  color: #333333;
  font-weight: 500;
  margin-bottom: 35px;
}

.ttl-search.ttl-search-style01 span {
  font-weight: bold;
}

@media (max-width: 768px) {
  .ttl-search.ttl-search-style01 {
    font-size: 17px;
    font-size: 1.7rem;
    margin-bottom: 25px;
  }
}

.ttl-search.ttl-search-top {
  color: #a70000;
  font-size: 16px;
  font-size: 1.6rem;
  background: #fbf4d8;
  padding: 5px 5px 5px 10px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .ttl-search.ttl-search-top {
    font-size: 12px;
    font-size: 1.2rem;
    padding: 5px 8px;
    line-height: 1.25;
  }
}

.ttl-search.ttl-search-ct {
  color: #a70000;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .ttl-search.ttl-search-ct {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.8;
  }
}

.ttl-search.ttl-search-ct a:hover {
  text-decoration: underline;
}

.actor-list {
  margin-bottom: 10px;
  font-size: 16px;
  font-size: 1.6rem;
  color: #333;
}

@media (max-width: 768px) {
  .actor-list {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.8;
  }
}

.actor-list dt {
  display: inline-block;
  width: 42px;
  float: left;
}

@media (max-width: 768px) {
  .actor-list dt {
    width: 50px;
  }
}

.actor-list dd {
  display: inline-block;
  width: calc(100% - 42px);
  line-height: 1.25;
}

@media (max-width: 768px) {
  .actor-list dd {
    width: calc(100% - 50px);
  }
}

.time-list {
  display: flex;
  align-items: center;
}

.time-list dt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  background: #bbbbbb;
  color: #fff;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

.time-list dd {
  display: flex;
  align-items: center;
  width: calc(100% - 64px);
  background: #f1f1f1;
  color: #333;
  line-height: 1.25;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 10px 5px 10px 15px;
  font-weight: 500;
}

.list-news {
  display: inline-block;
  width: 100%;
}

.list-news li {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .list-news li {
    float: left;
    width: 48%;
    margin-right: 4%;
  }
  .list-news li:nth-child(2n) {
    margin-right: 0;
  }
}

.list-news li .img img {
  display: block;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .list-news li .img img {
    width: 100%;
  }
}

.list-news li .txt {
  padding-top: 10px;
  color: #333;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.46;
}

@media (max-width: 768px) {
  .list-news li .txt {
    line-height: 1.53;
  }
}

.color-pink {
  background-color: #ffcece;
}

@media (max-width: 768px) {
  .merge-content {
    margin-bottom: 15px;
  }
  .merge-content .img-left {
    float: left;
    width: 35%;
  }
  .merge-content .title {
    float: left;
    width: 65%;
    padding-left: 10px;
  }
}

.next-link {
  display: inline-block;
  width: 100%;
  text-align: right;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #a70000;
}

.next-link span {
  position: relative;
}

.next-link span:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-right: 1px solid #a70000;
  border-top: 1px solid #a70000;
  transform: rotate(45deg);
  position: absolute;
  left: -16px;
  top: 3px;
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 22px 0 30px;
}

@media (max-width: 768px) {
  .pagination {
    justify-content: space-between;
    margin: 22px auto 50px;
    max-width: 350px;
  }
}

.pagination a {
  color: #a70000;
  float: left;
  padding: 7px 19px;
  margin: 0 5px;
  text-decoration: none;
  border: 1px solid #cccccc;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: 3px;
}

.pagination a:hover:not(.active) {
  background-color: #a70000;
  color: #fff;
}

@media (max-width: 768px) {
  .pagination a {
    padding: 6px 15px;
    font-size: 13px;
    font-size: 1.3rem;
    margin: 0 2px;
  }
}

@media (max-width: 374px) {
  .pagination a {
    padding: 2px 10px;
    margin: 0 2px;
  }
}

.pagination a.first {
  margin-left: 0;
  padding: 7px 20px 7px 11px;
}

@media (max-width: 768px) {
  .pagination a.first {
    padding: 6px 17px 6px 7px;
  }
}

@media (max-width: 374px) {
  .pagination a.first {
    padding: 2px 12px 2px 2px;
  }
}

.pagination a.last {
  margin-right: 0;
  padding: 7px 11px 7px 20px;
}

@media (max-width: 768px) {
  .pagination a.last {
    padding: 6px 7px 6px 17px;
  }
}

@media (max-width: 374px) {
  .pagination a.last {
    padding: 2px 2px 2px 12px;
  }
}

.pagination .active a {
  background-color: #a70000;
  border: 1px solid #a70000;
  color: white;
}

.page-useroubo {
  background: #f1f1f1;
  font-size: 14px;
  font-size: 1.4rem;
}

.page-useroubo .useroubo-main {
  padding: 20px 0;
}

.page-useroubo .useroubo-content {
  background: #fff;
  padding: 20px 40px 0 40px;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .page-useroubo .useroubo-content {
    padding: 10px 20px 0 20px;
  }
}

.page-useroubo .useroubo-content ol {
  margin: 0 2em 2em 4em;
  position: relative;
}

@media screen and (max-width: 768px) {
  .page-useroubo .useroubo-content ol {
    margin: 0 1em 1em 2em;
  }
}

.page-useroubo .useroubo-content ol li {
  list-style: none;
  padding-left: 18px;
}

.page-useroubo .useroubo-content ol li:before {
  position: absolute;
  content: counter(number) ". ";
  counter-increment: number;
  left: 0;
}

.page-useroubo .useroubo-content form {
  text-align: center;
}

.page-useroubo .useroubo-content .mail-link {
  text-decoration: underline;
  color: #000;
}

.page-useroubo .useroubo-content .mail-link:hover {
  color: #a70000;
}

.page-useroubo .useroubo-content .mail-icn {
  font-size: 10px;
  font-size: 1rem;
}

.page-useroubo .useroubo-content .btn-sty {
  width: 100px;
  height: 28px;
  font-weight: bold;
}

.page-useroubo .useroubo-content .mb10 {
  margin-bottom: 10px;
}

.page-useroubo .useroubo-content .mb20 {
  margin-bottom: 20px;
}

.page-useroubo .useroubo-content .mb30 {
  margin-bottom: 30px;
}

#page-forum .page-forum-content {
  border-width: 1px;
  border-color: #ccc;
  border-style: solid;
  margin-bottom: 30px;
  padding: 19px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  #page-forum .page-forum-content {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 10px;
  }
}

#page-forum .page-forum-content + p.txt-center {
  margin-bottom: 50px;
}

#page-forum .pageTop {
  margin-top: 0;
}

#page-forum .forum_text {
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-size: 1.3rem;
}

#page-forum .forum_text p {
  padding: 20px;
}

#page-forum .forum_text .forum_left {
  float: left;
  width: calc(55% - 30px);
  padding-right: 0;
  line-height: 1.5;
}

@media screen and (max-width: 450px) {
  #page-forum .forum_text .forum_left {
    width: 100%;
    padding-right: 20px;
  }
}

#page-forum .forum_text .forum_right {
  float: right;
  display: inline;
  margin: 20px 20px 20px 10px;
  text-align: right;
  width: 45%;
}

@media screen and (max-width: 768px) {
  #page-forum .forum_text .forum_right {
    margin: 20px 10px 20px 10px;
  }
}

@media screen and (max-width: 450px) {
  #page-forum .forum_text .forum_right {
    width: 100%;
    margin: 0;
    padding: 10px 10px 20px 10px;
  }
}

#page-forum .forum_text .forum_right ul {
  text-align: left;
  margin: 15px 15px 0 15px;
}

#page-forum .forum_text .forum_right ul li {
  list-style: disc;
  list-style-position: inside;
}

#page-forum .forum_text .forum_right ul li a {
  text-decoration: underline;
  color: #1e8faf !important;
  font-size: 13px;
  font-size: 1.3rem;
}

#page-forum .forum_text .forum_right .btnImg .imgWrap {
  width: 100%;
  display: inline-block;
  position: relative;
  margin: 0px 0px 5px;
  padding: 0px;
  float: none;
  text-align: center;
}

#page-forum .forum_text .forum_right .btnImg .imgWrap img {
  margin: 0px;
  padding: 0px;
  float: left;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #page-forum .forum_text .forum_right .btnImg .imgWrap img {
    float: none;
  }
}

#page-forum .forum_text .forum_right .btnImg .unit_thumbnail {
  width: 100%;
  height: 49px;
}

#page-forum .forum_text .mail_box {
  margin: 0;
  border: 1px solid #CCC;
  padding-bottom: 15px;
}

#page-forum .forum_text .mail_box .mail_title {
  background: #1e8faf;
  color: #FFF;
  font-weight: bold;
  height: 30px;
  text-align: left;
  padding: 5px 5px 0 15px;
  margin: 0;
}

#page-forum #present, #page-forum #mail {
  padding-top: 112px;
  margin-top: -112px;
}

@media screen and (max-width: 768px) {
  #page-forum #present, #page-forum #mail {
    padding-top: 62px;
    margin-top: -62px;
  }
}

#page-forum #title_present, #page-forum #title_mail, #page-forum #title_customer {
  width: calc(100% + 13.5px);
  margin-left: -13.5px;
}

@media screen and (max-width: 1200px) {
  #page-forum #title_present, #page-forum #title_mail, #page-forum #title_customer {
    width: calc(100% + 10px);
    margin-left: -10px;
  }
}

@media screen and (max-width: 980px) {
  #page-forum #title_present, #page-forum #title_mail, #page-forum #title_customer {
    width: calc(100% + 8px);
    margin-left: -8px;
  }
}

@media screen and (max-width: 768px) {
  #page-forum #title_present, #page-forum #title_mail, #page-forum #title_customer {
    width: calc(100% + 6px);
    margin-left: -6px;
  }
}

#page-forum #bannerArea .imgWrap {
  width: 280px;
  height: 60px;
  display: inline-block;
  position: relative;
  margin: 0px;
  padding: 0px;
  float: none;
}

#page-forum #bannerArea .imgWrap img {
  width: 100%;
  height: 60px;
  margin: 0px;
  padding: 0px;
  float: left;
}

#lNav {
  margin: 0 0 15px 0;
}

#lNav .imgWrap img {
  margin: 0px;
  padding: 0px;
  float: left;
  width: 100%;
  height: 8px;
}

#lNav .imgWrap .unit_thumbnail {
  width: 100%;
  height: 8px;
}

.howtoMenu {
  margin: 10px 0;
}

.howtoMenu ul li {
  display: inline-block;
  margin-right: 10px;
  font-size: 12px;
  font-size: 1.2rem;
}

.howtoMenu ul li a {
  text-decoration: underline;
  color: #0087ba;
  font-size: 12px;
  font-size: 1.2rem;
}

.howtoBox {
  background: url("../images/program/howto/howto_bg_box.gif") left top;
  padding: 7px;
}

.howtoBox .howtoBox-in {
  padding: 15px;
  overflow: auto;
  background-color: #fff;
}

.howtoBox .howtoBox-in .title {
  position: relative;
  padding-left: 25px;
  border-bottom: 1px dotted #a6a6a6;
  margin-bottom: 10px;
}

.howtoBox .howtoBox-in .title:before {
  display: block;
  content: '';
  background: url("../images/program/howto/icon_nihoneiga_20px.gif") no-repeat center center;
  width: 21px;
  height: 20px;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 45%;
  transform: translateY(-50%);
}

.howtoBox .howtoBox-in .title h2 {
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
}

.howtoBox .howtoBox-in .left {
  float: left;
  width: 40%;
}

@media screen and (max-width: 768px) {
  .howtoBox .howtoBox-in .left {
    width: 100%;
  }
}

.howtoBox .howtoBox-in .left a {
  color: black;
}

.howtoBox .howtoBox-in .left span {
  text-decoration: underline;
  color: #a70000;
}

.howtoBox .howtoBox-in .right {
  float: right;
  width: 57%;
}

@media screen and (max-width: 768px) {
  .howtoBox .howtoBox-in .right {
    width: 100%;
  }
}

.img-caption {
  margin: 0;
}

.img-caption img {
  width: 100%;
}

.img-caption figcaption {
  font-size: 11px;
  font-size: 1.1rem;
}

.img-caption figcaption a {
  color: #a70000;
  text-decoration: underline;
  font-size: 11px;
  font-size: 1.1rem;
}

#howtoIcon {
  border: 1px #CCC solid;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #howtoIcon {
    min-width: 620px;
  }
}

#howtoIcon th {
  background: #139ace url("../images/program/howto/bg_line.gif");
  border-left: #FFF 1px solid;
  color: #fff;
  padding: 5px 8px;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
}

#howtoIcon td {
  border-left: #CCC 1px solid;
  border-bottom: #CCC 1px solid;
  text-align: center;
  padding: 0.5em 1em;
  line-height: 1.5;
  font-size: 11px;
  font-size: 1.1rem;
}

#howtoIcon tr.bgColor {
  background: #f5f5f5;
}

#howtoIcon .imgWrap {
  display: inline-block;
  margin: 2px;
}

#page-howto .txt-basic.txt-sm {
  line-height: 1.5;
  font-size: 13px;
  font-size: 1.3rem;
}

.ttl_caution {
  margin: 0 0 20px 0;
  display: inline-block;
  width: 100%;
}

.ttl_caution img {
  width: 100%;
}

.m20b {
  margin-bottom: 20px;
}

.m10b {
  margin-bottom: 10px;
}

.main-user {
  padding: 20px 30px;
  border: 1px solid #baa242;
  background: #fff9df;
  border-radius: 5px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .main-user {
    padding: 10px;
  }
}

.main-user .fs15 {
  font-size: 15px;
  font-size: 1.5rem;
}

.main-user p {
  font-size: 13px;
  font-size: 1.3rem;
}

.main-user .txt-user {
  background: #FFF;
  margin: 20px 0 0 0;
  padding: 15px 20px;
  font-size: 11px;
  font-size: 1.1rem;
  font-weight: 400;
}

.userList {
  display: inline-block;
  width: 100%;
}

.userList li {
  list-style-type: disc;
  list-style-position: outside;
  margin: 0 0 0 20px;
  font-size: 13px;
  font-size: 1.3rem;
}

.userCautions_B {
  background: #FFF;
  font-size: 100%;
  font-weight: bold;
  margin: 1em;
  padding: 1em;
}

.userCautions_B .linkRed {
  color: #f5616a;
  text-decoration: underline;
}

.userCautions_B .linkRed:visited {
  color: #f5616a;
}

.userCautions_B .linkRed:hover {
  color: #0087ba;
}

.list-userBoxwrap {
  display: inline-block;
  width: 100%;
}

.list-userBoxwrap li {
  border: #baa242 1px solid;
  margin: 20px 0 0 0;
  font-size: 13px;
  font-size: 1.3rem;
}

.list-userBoxwrap li .tit {
  background: #fff9df;
  border-bottom: #baa242 1px solid;
  margin: 0;
  padding: 15px;
}

@media (max-width: 768px) {
  .list-userBoxwrap li .tit {
    padding: 10px;
  }
}

.list-userBoxwrap li .tit .handleDay {
  float: right;
  font-size: 12px;
  font-size: 1.2rem;
  padding-left: 10px;
  text-align: right;
  width: 25%;
}

@media (max-width: 768px) {
  .list-userBoxwrap li .tit .handleDay {
    text-align: left;
    width: 100%;
    padding-left: 0;
    padding-bottom: 5px;
  }
}

.list-userBoxwrap li .tit .handleName {
  float: right;
  padding-left: 20px;
  width: 35%;
}

@media (max-width: 768px) {
  .list-userBoxwrap li .tit .handleName {
    width: 100%;
    padding-left: 0;
  }
}

.list-userBoxwrap li .tit .handleTitle {
  float: left;
  width: 40%;
}

@media (max-width: 768px) {
  .list-userBoxwrap li .tit .handleTitle {
    width: 100%;
  }
}

.list-userBoxwrap li .tit .handleTitle .small {
  font-size: 11px;
  font-size: 1.1rem;
}

.list-userBoxwrap li .tit .handleTitle .big {
  font-weight: bold;
  font-size: 15px;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .list-userBoxwrap li .tit .handleTitle .big {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.list-userBoxwrap li .text {
  background: #fff;
  clear: both;
  line-height: 1.5;
  padding: 15px 20px;
}

@media (max-width: 768px) {
  .list-userBoxwrap li .text {
    padding: 10px;
  }
}

.list-userBoxwrap li .text .text-blue {
  color: #0000ff;
}

.userNo {
  padding: 20px;
  text-align: center;
}

.userNo a {
  text-decoration: underline;
  color: #333;
}

.userNo a:hover {
  color: #a70000;
}

@media (max-width: 768px) {
  .userNo {
    padding: 10px;
  }
}

.red-bold {
  color: #C00;
}

.linkBlue {
  color: #0087ba;
  text-decoration: underline;
}

.linkBlue:hover {
  color: #C00;
}

.page-404 .pb25 {
  padding-bottom: 25px;
}

.page-404 .cen {
  text-align: center !important;
}

.page-404 .title_notFound {
  background: #1d1d1d;
  color: #fff;
  padding: 8px;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

.page-404 .main-text {
  background: #fff;
  border: 1px solid #CCCCCC;
  padding: 10px 10px 40px 10px;
  margin: 0;
}

.page-404 .main-text p {
  text-align: justify;
  line-height: 1.5;
  font-size: 14px;
  font-size: 1.4rem;
  color: #000;
}

.page-404 .main-text p a {
  text-decoration: underline;
  color: #000;
}

.page-404 .main-text p a:hover, .page-404 .main-text p a:active {
  color: #bc0d17;
}

.page-sitemap {
  font-size: 14px;
  font-size: 1.4rem;
}

.page-sitemap #sitemapWrap a {
  display: block;
  margin: 0.4em 0;
  padding-left: 13px;
  background: url(../images/common/arrow_red.gif) 0 center no-repeat;
  color: #bc0d17;
  text-decoration: none;
}

.page-sitemap #sitemapWrap a:link {
  color: #bc0d17;
}

.page-sitemap #sitemapWrap a:visited {
  color: #f5616a;
}

.page-sitemap #sitemapWrap a:hover {
  color: #0087ba;
}

.page-sitemap #sitemapWrap a:active {
  color: #0087ba;
}

.page-sitemap #sitemapWrap .top p {
  border-bottom: none;
}

.page-sitemap #sitemapWrap .sitemapMargin1 {
  border-top: #CCC 1px solid;
}

.page-sitemap #sitemapWrap .sitemapMargin1 p {
  border-bottom: #CCC 1px solid;
  margin: 0 0 0 6%;
}

.page-sitemap #sitemapWrap .sitemapMargin2 {
  border-top: #CCC 1px solid;
}

.page-sitemap #sitemapWrap .sitemapMargin2 p {
  border-bottom: #CCC 1px solid;
  margin: 0 0 0 40%;
}

.page-sitemap #sitemapWrap p a.greySmall {
  background: url(../images/common/arrow_lnav_off.gif) 0 center no-repeat;
  color: #666;
  display: block;
  font-size: 85%;
  margin: 0.5em 0 0 10%;
  padding-left: 13px;
}

.page-sitemap #sitemapWrap p.sitemapNoborder {
  border-bottom: none;
}

.page-sitemap .unit_thumbnail {
  padding: 0 !important;
  margin: 0 !important;
  display: inline-block;
  content: '';
  z-index: 9998;
  position: absolute;
  top: 0;
  left: 0;
}

.pageTop a {
  display: inline-block;
}

.policy #policyWrap {
  line-height: 1.6;
  margin: 20px 15px;
  padding: 0 30px;
}

@media screen and (max-width: 768px) {
  .policy #policyWrap {
    padding: 0;
    margin: 0 0 20px 0;
  }
}

.policy #policyWrap p {
  margin-bottom: 20px;
  margin-top: 15px;
  font-size: 14px;
  font-size: 1.4rem;
}

.policy #policyWrap .title {
  clear: both;
  font-size: 14px;
  font-size: 1.4rem;
  padding: 1em;
  background: url(../images/policy/bg_gradation_gray.jpg) repeat center top;
  border: none;
  border: 1px solid #ccc;
  font-weight: bold;
}

.policy #policyWrap ul li {
  margin-bottom: 10px;
  margin-top: 3px;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: bold;
  list-style-type: none;
}

.policy #policyWrap ul li.top {
  margin-top: 10px;
}

.policy #policyWrap ul ul li {
  font-weight: normal;
}

.policy #policyWrap .policy_link {
  color: #333;
  text-decoration: underline;
}

.policy #policyWrap .policy_link:hover {
  color: #bc0d17;
}

.table-news-item {
  width: 100%;
  font-size: 13px;
  font-size: 1.3rem;
}

.table-news-item tr th, .table-news-item tr td {
  padding: 1em;
  vertical-align: top;
  border: 1px solid #ccc;
  background: #f0f0f0 url(../images/jimaku/bg_gradation_gray.jpg) 0 0 repeat-x;
}

.table-news-item tr th {
  text-align: center;
  font-weight: bold;
  border-right: none;
}

@-moz-document url-prefix() {
  .table-news-item tr th {
    width: 180px;
  }
}

@media screen and (max-width: 768px) {
  @-moz-document url-prefix() {
    .table-news-item tr th {
      width: auto;
    }
  }
}

.table-news-item tr td {
  border-left: 1px dotted #ccc;
  color: #a70000;
}

.table-news-item tr td a:hover {
  color: #0087ba;
}

.table-news-item tr.active th {
  background: url(../images/jimaku/news_bgItem.jpg) 0 0 repeat-x;
}

.txt-news {
  margin: 15px 0;
  font-size: 13px;
  font-size: 1.3rem;
  border-bottom: 1px dotted #ccc;
  padding-bottom: 15px;
  line-height: 1.5em;
}

.txt-news p {
  margin-bottom: 10px;
}

.link-arrow {
  padding-left: 25px;
  position: relative;
  text-decoration: underline;
}

.link-arrow:before {
  display: block;
  content: '';
  background: url("../images/shicho/houjin/icon-arrow.png") no-repeat center center;
  background-size: 100% 100%;
  width: 10px;
  height: 10px;
  overflow: hidden;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.link-arrow:hover {
  color: #0087ba;
}

.mb30 {
  margin-bottom: 30px;
}

ol {
  list-style: none;
}

.page-inquiry {
  font-size: 15px;
  font-size: 1.5rem;
}

.page-inquiry .accordion02 dl dt a, .page-inquiry .accordion02 dl dd a {
  line-height: 1.5;
  color: #000;
  text-decoration: underline;
  font-size: 13px;
  font-size: 1.3rem;
}

.page-inquiry .accordion02 dl dt a:hover, .page-inquiry .accordion02 dl dd a:hover {
  color: #a70000;
}

.page-inquiry.index {
  font-size: 14px;
  font-size: 1.4rem;
}

.page-inquiry.index #mainInner {
  background: #fff;
  border-width: 0 1px 1px 1px;
  border-color: #ccc;
  border-style: solid;
  margin-bottom: 30px;
  padding: 19px;
}

.page-inquiry.index .inquiryTit {
  background: url(/images/common/bg_gradation_gray.jpg) 0 0 repeat-x;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 1em 1em 1em 0.8em;
}

.page-inquiry.index .inquiryTit h2 {
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: bold;
}

.page-inquiry.index .inquiryTit span {
  background: url(/images/common/arrow_red.gif) 0 4px no-repeat;
  padding-left: 15px;
}

.page-inquiry.index .inquiryList {
  border: 1px solid #ccc;
  margin-bottom: 1em;
  padding: 1.2em 1.2em 0 1.5em;
}

.page-inquiry.index .inquiryList li {
  background: url(/images/common/arrow_red.gif) 0 4px no-repeat;
  padding: 0 0 1.2em 15px;
}

.page-inquiry.index .inquiryList li a {
  text-decoration: underline;
  color: #bc0d17;
}

.page-inquiry.index .inquiryList li a:hover {
  color: #0087ba;
}

.corp-com #lNav .lNavInnerLi li a {
  text-decoration: underline;
}

.page-corp {
  font-size: 14px;
  font-size: 1.4rem;
}

.page-corp a {
  text-decoration: underline;
}

.page-corp a.linkRed {
  color: #bc0d17;
}

.page-corp a:hover {
  color: #0087ba;
}

.page-corp #corpInfo {
  width: 100%;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin-bottom: 3%;
}

.page-corp #corpInfo tr th {
  background: #ece5d3;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  line-height: 1.5em;
  text-align: center;
  padding: 0.5%;
  width: 20%;
}

@media screen and (max-width: 480px) {
  .page-corp #corpInfo tr th {
    display: block;
    width: 100%;
    text-align: left;
    padding: 1em;
  }
}

.page-corp #corpInfo tr td {
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  line-height: 1.5em;
  padding: 1em;
  vertical-align: top;
}

@media screen and (max-width: 480px) {
  .page-corp #corpInfo tr td {
    display: block;
    width: 100%;
  }
}

.page-corp .corpWrap {
  border: #CCC 1px solid;
  margin: 0 0 20px 0;
  padding: 1px;
}

.page-corp .corpWrap .corpBox {
  background: url(../images/corp/corp_bg_box.gif) 0 0 repeat-x;
  padding: 10px;
}

.page-corp .corpWrap .corpBox .title {
  border-bottom: #999 1px dotted;
  font-weight: bold;
  padding: 0 5px 5px 5px;
}

.page-corp .corpWrap .corpBox .text {
  padding: 10px 5px 5px 5px;
}

.page-shicho_inquiry {
  font-size: 14px;
  font-size: 1.4rem;
}

.page-shicho_inquiry #inquiryWrap .title {
  clear: both;
  padding: 1em;
  background: url(/images/common/bg_gradation_gray.jpg) repeat-x;
  border: none;
  border: 1px solid #ccc;
  font-weight: bold;
}

.page-shicho_inquiry #inquiryWrap .contact {
  font-weight: bold;
  margin: 0.5em 1em 1em 1em;
  padding: 1em 1em 0.5em 1em;
  border-bottom: 1px dotted #ccc;
}

.page-shicho_inquiry #inquiryWrap table {
  width: 92%;
  margin: 0 auto 1em auto;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  box-sizing: border-box;
}

.page-shicho_inquiry #inquiryWrap table th {
  padding: 0.5em;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  white-space: nowrap;
  background: #F1F1F1;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .page-shicho_inquiry #inquiryWrap table th {
    display: block;
    width: 100%;
    text-align: left;
    padding: 1em;
  }
}

.page-shicho_inquiry #inquiryWrap table td {
  padding: 1em;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

@media screen and (max-width: 480px) {
  .page-shicho_inquiry #inquiryWrap table td {
    display: block;
    width: 100%;
  }
}

.page-shicho_inquiry #inquiryWrap table td a {
  word-break: break-all;
}

.page-shicho_inquiry #inquiryWrap .inquiryBox {
  background: url(../../images/common/bg_box_redline.gif) repeat-x;
  margin: 1em auto 1.5em auto;
  border: 1px solid #ccc;
  padding-top: 1px;
}

.page-shicho_inquiry #inquiryWrap .inquiryBox .txt {
  width: 85%;
  margin: 0 auto 1em auto;
  text-align: center;
  padding: 0.5em;
}

.page-shicho_inquiry #inquiryWrap .notes {
  width: 92%;
  display: inherit;
  margin: 0.5em auto 0 auto;
}

.page-shicho_inquiry #inquiryWrap .notes p a {
  text-decoration: underline;
  color: #000;
}

.page-shicho_inquiry #inquiryWrap .notes p a:hover {
  color: #bc0d17;
}

.page-shicho_inquiry #inquiryWrap .inquiryBox_notes {
  margin-bottom: 1.5em;
}

.page-shicho_inquiry #inquiryWrap .inquiryBox_notes .inquiryBox2 {
  background: url(../../images/common/bg_box_redline.gif) repeat-x;
  margin: 1em auto 0.5em auto;
  border: 1px solid #ccc;
  padding-top: 1px;
  overflow: hidden;
}

@media screen and (min-width: 1024px) {
  .page-shicho_inquiry #bannerArea ul {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .page-shicho_inquiry #bannerArea ul li {
    width: 48%;
    float: left;
  }
  .page-shicho_inquiry #bannerArea ul li .imgWrap img {
    width: 100%;
  }
}
