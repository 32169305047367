.corp-com {
  #lNav {
    .lNavInnerLi {
      li {
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
.page-corp {
  @include font-size(14);
  a {
    text-decoration: underline;
    &.linkRed {
      color: #bc0d17;
    }
    &:hover {
      color: #0087ba;
    }
  }
  #corpInfo {
    width: 100%;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    margin-bottom: 3%;
    tr {
      th {
        background: #ece5d3;
        border-left: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        font-weight:bold;
        line-height: 1.5em;
        text-align: center;
        padding: 0.5%;
        width: 20%;
        @media screen and (max-width: 480px){
          display: block;
          width: 100%;
          text-align: left;
          padding: 1em;
        }
      }
      td {
        border-left: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        line-height: 1.5em;
        padding: 1em;
        vertical-align: top;
        @media screen and (max-width: 480px){
          display: block;
          width: 100%;
        }
      }
    }
  }
  .corpWrap {
    border: #CCC 1px solid;
    margin: 0 0 20px 0;
    padding: 1px;
    .corpBox {
      background: url(../images/corp/corp_bg_box.gif) 0 0 repeat-x;
      padding: 10px;
      .title {
        border-bottom: #999 1px dotted;
        font-weight: bold;
        padding: 0 5px 5px 5px;
      }
      .text {
        padding: 10px 5px 5px 5px;
      }
    }
  }
}